@import "react-notifications/lib/notifications.css";
@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
@import "react-confirm-alert/src/react-confirm-alert.css";
@import url('../fonts/Commissioner/fontCss.css');
:root {
  --left: 0;
  --mindalay--light-blue-color: #297fb90c;
  --mindalay--brand-color: #32064a;
  --mindalay--secondary-color: #e56b1f;
  --mindalay--blue-color: #2980B9;
  --mindalay--gray-color: #0f1013;
  --mindalay--white-color: #ffffff;
  --mindalay--trasnparent-white-color: rgba(255, 255, 255, 0.34);
  --mindalay--light-gray: #f9f9f9;
  --mindalay--black-color: #000000;
  --mindalay--dark-color: #222F3E;
  --mindalay--green-color: #28a745;
  --mindalay--orange-color: #FFA500;
  --mindalay--red-color: #d8032e;
  --mindalay--grey-color: #808080;
}
:root {
  --mindalay--brand-color-lightened-10: #371a56;
  --mindalay--brand-color-lightened-20: #4b3d5b;
  --mindalay--brand-color-lightened-30: #49386e;
  --mindalay--brand-color-lightened-50: #704a75;
  --mindalay--brand-color-lightened-80: #a084bc;
  --mindalay--gray-color-lightened-10: #1a1b1d;
  --mindalay--gray-color-lightened-20: #2b2c2f;
  --mindalay--gray-color-lightened-25: #343538;
  --mindalay--gray-color-lightened-30: #3e4044;
  --mindalay--gray-color-lightened-40: #a5a6aa;
  --mindalay--gray-color-lightened-70: #d0d1d3;
  --mindalay--gray-color-lightened-75: #dcdce1;
  --mindalay--gray-color-lightened-80: #d6d7da;
  --mindalay--gray-color-lightened-90: #f4f4f4;
  --mindalay--blue-color-lightened-20: #4e9cc1;
  --mindalay--blue-color-lightened-40: #BEDCF0;
  --mindalay--blue-color-lightened-45: #CEE2F0;
  --mindalay--blue-color-lightened-50: #DBF1FF;
  --mindalay--blue-color-lightened-55: #F4F9FD;
  --mindalay--blue-color-lightened-minus-10: #267a9a;
  --mindalay--secondary-color-lightened-50: #f7935a;
  --mindalay--secondary-color-lightened-minus-5: #e06c22;
  --mindalay--dark-color-lightened-80: #d4d8df;
  --mindalay--dark-color-darkened-5: #1f2a36;
  --mindalay--green-color-lightened-50: #E5F9E9;
  --mindalay--green-color-lightened-minus-5: #267b3a;
  --mindalay--red-color-lightened-50: #ed435a;
  --mindalay--red-color-lightened-minus-5: #cd0227;
  --mindalay--orange-color-lightened-minus-5: #ff9900;
  --mindalay--black-color-lightened-70: #b8b8b8;
}
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--mindalay--gray-color-lightened-80);
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--mindalay--gray-color-lightened-40);
}
.mr--45 {
  margin-right: 45px !important;
}
.fw-500 {
  font-weight: 500;
}
.help-container li,
.footer-page li {
  list-style: inherit !important;
  margin: inherit !important;
  padding: inherit !important;
}
.help-container ul,
.footer-page ul,
.help-container ol,
.footer-page ol {
  margin: inherit !important;
  padding: inherit !important;
}
.font-extrabold {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.font-black {
  font-family: 'Commissioner', sans-serif;
  font-weight: 900;
  font-style: normal;
}
.font-bold {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.font-medium {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-default {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.font-light {
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.font-tini {
  font-family: 'Commissioner', sans-serif;
  font-weight: 100;
  font-style: normal;
}
p {
  color: var(--mindalay--dark-color);
  opacity: 1 !important;
}
.word-wrap-break-word {
  word-wrap: break-word;
}
.word-break-break-word {
  word-break: break-word;
}
.mindalay--btn-dark circle,
.mindalay--btn-dark path {
  fill: transparent !important;
}
.box-shadow-4 {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.active-message-container {
  background-color: var(--mindalay--brand-color);
  transform: scale(1.02);
  color: var(--mindalay--white-color);
  opacity: 0.9;
}
.outline-none {
  outline: none !important;
}
.text-red {
  color: var(--mindalay--red-color);
}
.text-warning {
  color: var(--mindalay--orange-color);
}
.text-inherit {
  text-align: inherit !important;
}
.z-index-auto {
  z-index: auto !important;
}
.box-shadow-33 {
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
}
.fs-30 {
  font-size: 30px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 0.2 !important;
}
.opacity-2 {
  opacity: 0.4 !important;
}
.opacity-3 {
  opacity: 0.6 !important;
}
.opacity-4 {
  opacity: 0.8 !important;
}
.opacity-5 {
  opacity: 1 !important;
}
.pr--4 {
  padding-right: 45px !important;
}
.pr--5 {
  padding-right: 55px !important;
}
.pr--6 {
  padding-right: 65px !important;
}
.white-space-break-spaces {
  white-space: break-spaces;
}
.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.min-width-auto {
  min-width: auto;
}
a.disabled {
  pointer-events: none;
  cursor: default;
  border-color: var(--mindalay--gray-color-lightened-80) !important;
}
a.disabled svg * {
  fill: var(--mindalay--gray-color-lightened-80) !important;
}
.top-5 {
  top: 5px !important;
}
.top-100 {
  top: 100px;
}
.top-80 {
  top: 80px !important;
}
.background-transparent {
  background-color: transparent !important;
}
.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.max-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.max-height-50 {
  max-height: 50px !important;
}
.h-auto {
  height: auto !important;
}
.cursor-default {
  cursor: default !important;
}
.pointer-events-none {
  pointer-events: none;
}
.disabled-input {
  pointer-events: none;
  background-color: var(--mindalay--black-color);
  opacity: 0.4 !important;
  color: var(--mindalay--white-color) !important;
}
input,
button {
  outline: none;
}
.border-none {
  border: none !important;
}
.max-line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
button:hover a {
  color: var(--mindalay--white-color);
}
.disabled {
  color: var(--mindalay--gray-color);
  border-color: var(--mindalay--gray-color);
  opacity: 0.8;
  pointer-events: none;
}
.disabled:hover {
  color: var(--mindalay--white-color);
  border-color: var(--mindalay--gray-color);
  background-color: var(--mindalay--gray-color);
}
button[type="button"]:disabled,
button[type="submit"]:disabled,
button[type="reset"]:disabled {
  background-color: var(var(--mindalay--gray-color-lightened-80));
}
button[type="button"]:disabled svg,
button[type="submit"]:disabled svg,
button[type="reset"]:disabled svg {
  fill: var(--mindalay--gray-color-lightened-40);
}
button[type="button"]:disabled:hover,
button[type="submit"]:disabled:hover,
button[type="reset"]:disabled:hover {
  cursor: default;
}
.course-info-wrapper p,
.course-info-wrapper span,
.course-info-wrapper a,
.course-info-wrapper small,
.course-info-wrapper h1,
.course-info-wrapper h2,
.course-info-wrapper h3,
.course-info-wrapper h4,
.course-info-wrapper h5,
.course-info-wrapper h6,
.course-info-wrapper strong,
.course-info-wrapper b {
  overflow: hidden;
}
.error-bordered {
  border: 1px solid var(--mindalay--red-color) !important;
  box-shadow: 0 0 2px var(--mindalay--red-color);
  border-radius: 5px;
}
.custom-control-input-disabled {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1.25rem;
  opacity: 1;
  top: 2px;
  border-color: transparent;
}
.disabled-checkbox::before {
  content: none;
}
.disabled-checkbox::after {
  content: none;
}
.custom-control-input.is-invalid ~ .custom-control-label::before {
  color: var(--mindalay--red-color) !important;
  border-color: var(--mindalay--red-color) !important;
}
.form-control.is-invalid {
  background-position: right calc(0.375em + 0.85rem) center;
}
.error-border {
  border-bottom: 2px solid var(--mindalay--red-color) !important;
}
.success {
  background-color: var(--mindalay--green-color);
}
.fail-block {
  margin-bottom: 10px;
}
.fail {
  color: var(--mindalay--red-color) !important;
}
.checked {
  color: var(--mindalay--green-color);
}
.black-color {
  color: var(--mindalay--black-color) !important;
}
.center {
  text-align: center;
}
.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-button .action-button-spinner {
  color: var(--mindalay--light-gray) !important;
}
.action-button:hover .action-button-spinner {
  color: var(--mindalay--brand-color) !important;
}
.page-loader {
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-spinner-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  position: fixed;
  z-index: 17;
  top: 0;
}
.notification-spinner-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--mindalay--white-color);
  position: absolute;
  z-index: 12;
  top: 0;
}
.third-level-categories-spinner-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
  top: 0;
}
.mobile-categories-block {
  overflow: auto;
}
footer {
  border-top: 1px solid #e0e0e0;
  z-index: 9;
}
header {
  position: sticky;
}
.wrapper {
  min-height: 80vh;
  width: 100%;
}
.input-block,
.form-group {
  position: relative;
}
.input-block i,
.form-group i {
  color: var(--mindalay--red-color);
  position: absolute;
  right: 5px;
  top: 33px;
  font-size: 20px;
}
.upload {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}
.file-icon-wrapper {
  text-align: center;
}
.file-icon-wrapper svg {
  width: 135px;
  height: 135px;
  fill: var(--mindalay--gray-color);
}
.pointer {
  cursor: pointer;
}
.mindalay--consultation-card {
  background-color: var(--mindalay--white-color);
  border: 1px solid #e0e0e0;
}
.mindalay--consultation-card:hover {
  transform: scale(1.01);
}
.custom-ui {
  padding: 20px 40px;
  background-color: var(--mindalay--white-color);
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.custom-ui p {
  margin-bottom: 12px;
  word-wrap: break-word;
}
.custom-ui .yes,
.custom-ui .no {
  padding: 10px 25px;
}
.selected {
  background-color: var(--mindalay--brand-color) !important;
  color: var(--mindalay--white-color);
}
.selected:hover {
  color: var(--mindalay--white-color) !important;
}
.react-multiple-carousel__arrow--left::before {
  content: "\e824";
}
.react-multiple-carousel__arrow--right::before {
  content: "\e825";
}
.react-multiple-carousel__arrow--left {
  left: 5px !important;
}
.react-multiple-carousel__arrow--right {
  right: 5px !important;
}
.course-carousel .react-multiple-carousel__arrow {
  top: 22%;
}
.capitalize {
  text-transform: capitalize;
}
.react-multiple-carousel__arrow {
  width: 35px !important;
  height: 35px !important;
  min-width: 30px !important;
  min-height: 30px !important;
  padding: 0 !important;
  background-color: var(--mindalay--black-color) !important;
  opacity: 0.6 !important;
  z-index: 9 !important;
}
.react-multiple-carousel__arrow::before {
  color: var(--mindalay--white-color) !important;
  font-size: 15px !important;
  font-weight: bolder !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: var(--mindalay--black-color) !important;
  opacity: 0.5 !important;
}
.course-wrapper {
  margin: 2px;
}
.course-wrapper .course-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-wrapper .course-image-wrapper .course-image {
  height: 150px;
  max-height: 155px;
  width: 100% !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  position: relative;
}
.setting-btn-group {
  z-index: 10;
}
.setting-btn-group i {
  margin: 0 10px !important;
}
.course-section-wrapper {
  transition: 0.5s;
}
.right {
  float: right;
  display: flex;
}
.left {
  float: left;
  display: flex;
}
.pl-120 {
  padding-left: 120px !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--mindalay--white-color);
  border-color: var(--mindalay--brand-color) !important;
  background-color: var(--mindalay--brand-color) !important;
}
.settings-icon {
  color: var(--mindalay--brand-color);
  cursor: pointer;
  font-size: 27px;
}
.section-content:hover {
  z-index: 10;
}
.arrow-font-size {
  font-size: 25px;
}
.main-color {
  color: var(--mindalay--brand-color);
}
.matching-answer {
  float: right;
  width: fit-content;
}
.full-width {
  width: 100%;
}
.fit-content {
  width: fit-content;
}
.btn-primary {
  color: var(--mindalay--white-color) !important;
  border-color: var(--mindalay--brand-color) !important;
  background-color: var(--mindalay--brand-color) !important;
}
.btn-primary:hover,
.btn-primary:focus {
  color: var(--mindalay--white-color) !important;
  border-color: var(--mindalay--brand-color) !important;
  background-color: var(--mindalay--brand-color) !important;
  box-shadow: none !important;
}
.small-error-msg {
  margin-top: 5px;
  color: var(--mindalay--red-color);
  font-size: 12px;
}
.label-less {
  margin-top: 30px;
}
a.mindalay--btn-default,
.mindalay--btn-default {
  border-radius: 3px;
  padding: 8px 30px;
  font-size: 16px;
  transition: 0.2s;
  cursor: pointer;
}
.mindalay--btn-default .spinner {
  display: inline-block;
}
.mindalay--btn-default .spinner:hover svg g {
  fill: var(--mindalay--brand-color) !important;
}
.mindalay--btn-default:hover .spinner svg g {
  fill: var(--mindalay--brand-color) !important;
}
a.mindalay--btn-small {
  padding: 7px 15px !important;
}
.page-title {
  color: var(--mindalay--dark-color);
  font-size: 32px;
  font-weight: 600;
}
.mindalay-select__menu {
  font-size: 16px;
}
.mindalay-select {
  text-transform: capitalize !important;
}
.mindalay-select__value-container .mindalay-select__single-value {
  font-size: 16px;
}
.setting-btn-group i {
  color: var(--mindalay--gray-color);
  border: 1px solid var(--mindalay--gray-color);
  padding: 8px;
  margin: 0 !important;
  border-radius: 3px;
  font-size: 15px !important;
  transition: 0.2s;
}
.setting-btn-group i:hover {
  background-color: var(--mindalay--gray-color);
  color: var(--mindalay--white-color);
}
.right-side-actions {
  float: right;
  top: -42px;
}
.right-long-mr {
  margin-right: 100px;
}
.table-min-width {
  min-width: 280px;
}
.icon-width {
  width: 20px;
}
.topic-form-upload .upload-file .form-control {
  display: none;
}
.sign-form-wrapper .sign-input,
.sign-form-wrapper .is-invalid {
  display: initial;
  border-radius: 0;
  box-shadow: none;
}
.sign-form-wrapper .sign-input:focus,
.sign-form-wrapper .is-invalid:focus {
  box-shadow: none;
}
.nav-item {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.nav-item a {
  width: 100%;
}
.text-capitalize {
  text-transform: capitalize;
}
.offcanvas-header {
  display: block;
}
.mobile-offcanvas {
  max-width: 500px;
  visibility: hidden;
  transform: translateX(-130%);
  border-radius: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
  transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.mobile-offcanvas.show {
  visibility: visible;
  transform: translateX(0);
}
.modal-title {
  font-size: 25px;
  color: #707070;
  margin: 0;
  text-transform: inherit;
  word-wrap: break-word;
  overflow: hidden;
}
button.close {
  font-size: 2.3rem !important;
}
.add-media-file {
  width: 85px;
  height: 85px;
  background-size: cover;
  background-position: center;
}
.selected-border {
  border: 2px solid var(--mindalay--brand-color);
  border-radius: 5px;
}
.resource-modal .modal-body,
.library-modal .modal-body {
  padding: 0;
}
.resource-modal .modal-body .nav-tabs,
.library-modal .modal-body .nav-tabs {
  border-bottom: none;
}
.resource-modal .modal-body ul,
.library-modal .modal-body ul {
  flex: 50%;
}
.library-modal .nav {
  flex: 30%;
  max-width: 250px;
}
.library-modal .nav li {
  padding: 5px 15px;
}
.library-modal .nav p {
  color: var(--mindalay--gray-color);
  border-radius: 0;
  padding: 0;
  border: none !important;
  cursor: pointer;
}
.library-modal .nav .active {
  background-color: var(--mindalay--light-gray) !important;
  color: var(--mindalay--brand-color) !important;
}
.library-modal .nav .active::before {
  content: '\203A';
  margin-right: 5px;
  color: var(--mindalay--brand-color);
  font-weight: 800;
}
.library-modal .tab-content {
  flex: 70%;
}
.library-modal .tab-content .file-image {
  height: 110px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.course-left-menu-wrapper .left-list-information-list .active-menu-item {
  color: var(--mindalay--secondary-color);
  font-weight: 600;
}
.section-media-wrapper img,
.section-media-wrapper video {
  width: 100% !important;
  margin-right: 10px;
  border-radius: 3px;
}
.sub-title {
  color: #707070;
  font-size: 25px;
  opacity: 0.6;
}
.course-media-wrapper .img,
.course-media-wrapper video {
  width: 100%;
  max-height: 300px;
  min-height: 257px;
  margin: 5px 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  background-color: var(--mindalay--light-gray);
}
.course-media-wrapper video {
  margin-bottom: 0;
  min-height: auto;
  max-height: 182px !important;
}
.accordion-wrapper .mindalay-card .card-header {
  z-index: none;
}
.accordion-wrapper .mindalay-card .card-body .section-content-wrapper .section-content svg {
  width: 25px;
  margin-right: 10px;
  min-width: 25px;
}
.course-quiz-svg {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.course-quiz-svg svg {
  width: 50px;
  min-width: 50px;
  margin-right: 10px;
}
.accordion-wrapper .mindalay-card .card-body .section-content-wrapper .section-content {
  color: var(--mindalay--brand-color);
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: var(--mindalay--white-color) !important;
  padding: 10px;
  margin: 0 0 5px 0;
  position: relative;
  transition: 0.3s;
}
.accordion-wrapper .mindalay-card .content-block {
  margin: 0 0.75rem;
  border: 1px solid var(--mindalay--blue-color-lightened-50);
  max-height: 100%;
}
.transparent-background {
  background-color: transparent;
}
.webinar-notification {
  text-align: center;
  padding: 10px 20px;
  align-items: center;
  position: fixed;
  top: 110px;
  transform: translate(-50%, -300px);
  left: 50%;
  border-radius: 5px;
  z-index: 100;
  background-color: var(--mindalay--white-color);
  border: 2px solid var(--mindalay--green-color);
  transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s;
  animation: pulse 1s infinite;
}
.webinar-notification p {
  margin-right: 15px;
  color: var(--mindalay--brand-color) !important;
  opacity: 1;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.6);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.webinar-notification.show {
  transform: translate(-50%, 0) !important;
}
.profile-user-fullname {
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor {
  color: var(--mindalay--white-color);
}
.filtered-courses strong {
  font-size: 15px !important;
  font-weight: 300 !important;
}
.custom-inp-style .custom-control-label::before,
.custom-inp-style .custom-control-label::after {
  top: 1.2rem;
  left: 2.4rem;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--mindalay--brand-color-lightened-30);
  border-color: var(--mindalay--brand-color-lightened-30);
}
.custom-card-header {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.custom-card-header .question-type-icon {
  margin-left: 30px;
}
.mindalay--question-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.mindalay--question-wrapper::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #0f1013;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}
.close-icon i {
  cursor: pointer;
  border: 1px solid var(--mindalay--red-color);
  padding: 5px 10px;
  font-size: 15px;
  color: var(--mindalay--red-color);
  border-radius: 3px;
  margin-left: 5px;
  transition: 0.3s;
}
.close-icon i:hover {
  background-color: var(--mindalay--red-color);
  color: var(--mindalay--white-color);
}
.active-pagination-item {
  background-color: var(--mindalay--brand-color) !important;
  border-color: var(--mindalay--brand-color) !important;
  color: var(--mindalay--white-color) !important;
}
.passed-pagination-item {
  background-color: var(--mindalay--gray-color-lightened-20);
  border-color: var(--mindalay--gray-color-lightened-25);
  color: var(--mindalay--white-color);
}
.countdown-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}
.countdown-wrapper .countdown-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  max-width: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
  padding: 5px;
  border-radius: 3px;
}
.countdown-wrapper .countdown-item .time-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--mindalay--brand-color);
  font-size: 13px !important;
}
.countdown-wrapper .countdown-item .timer-number {
  font-size: 22px !important;
  color: var(--mindalay--secondary-color);
}
.course-image-wrapper--instructor .course-image {
  width: 100%;
  height: 220px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--mindalay--light-gray);
}
.course-document-wrapper--instructor {
  display: flex;
  align-items: center;
  background-color: var(--mindalay--light-gray);
  padding: 5px;
}
.course-document-wrapper--instructor .course-document-icon {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px;
}
.course-document-wrapper--instructor .course-document-icon svg {
  width: 35px;
  height: auto;
}
.course-document-wrapper--instructor .document-button-group {
  display: flex;
  align-items: center;
}
.course-document-wrapper--instructor .document-button-group .course-view-button,
.course-document-wrapper--instructor .document-button-group .course-edit-button,
.course-document-wrapper--instructor .document-button-group .course-delet-button,
.course-document-wrapper--instructor .document-button-group .course-gamify-button {
  margin: 5px;
  height: fit-content;
}
.file-library-item {
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--mindalay--light-gray);
}
.tabs-wrapper .file-item svg {
  padding: 7px 10px;
  width: 50px;
  background-color: var(--mindalay--light-gray);
}
.tabs-wrapper .file-item svg path {
  fill: var(--mindalay--brand-color);
}
.uploaded-file-library {
  width: 100%;
  min-width: 70px;
  height: 100%;
  min-height: 200px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--mindalay--light-gray);
}
.uploaded-type-file-library {
  height: 100%;
  width: 100%;
  min-width: 70px;
  background-color: var(--mindalay--light-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uploaded-type-file-library .type-file {
  width: 50px;
  min-width: 50px;
}
.uploaded-type-file-library p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 1px solid #dee2e6;
}
table.dataTable {
  border-top: none;
  border-bottom: none;
}
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting {
  background-image: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 1px solid var(--mindalay--brand-color-lightened-50) !important;
  background-color: var(--mindalay--white-color);
  border-radius: 3px;
  color: var(--mindalay--gray-color) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: var(--mindalay--brand-color-lightened-50) !important;
  color: var(--mindalay--gray-color) !important;
  border: 1px solid var(--mindalay--brand-color-lightened-50) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: var(--mindalay--white-color) !important;
  background: var(--mindalay--brand-color) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--mindalay--white-color) !important;
  border: 1px solid var(--mindalay--brand-color);
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.42);
  z-index: 1060;
}
.empty-quiz-msg {
  font-size: 22px;
  color: var(--mindalay--gray-color);
}
.course-description,
.consultation-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.uppercase {
  text-transform: uppercase;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item h2 {
  font-size: 18px;
  color: var(--mindalay--black-color);
  opacity: 0.8;
  font-weight: 600;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item:first-child .navbar-profile-image {
  min-width: 60px;
}
.navbar-profile-container .nav--item .profile-nav-link {
  width: 100%;
}
.navbar .category-dropdown .dropdown-menu a {
  padding: 10px 30px 10px 15px;
}
.navbar .navbar-profile-image {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.navbar-min-height {
  min-height: 70px;
}
.join-meeting-title {
  font-size: 20px;
}
.categories-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.categories-wrapper .selected-category {
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
  padding: 10px 30px 10px 20px;
  color: var(--mindalay--blue-color);
  border-radius: 3px;
  border: 1px solid var(--mindalay--light-blue-color);
  background-color: var(--mindalay--light-blue-color);
}
.categories-wrapper .selected-category svg {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 11px;
  cursor: pointer;
}
.categories-wrapper .selected-category svg path {
  fill: var(--mindalay--blue-color);
}
.become-an-instructor-step-1 .custom-control-label::before,
.become-an-instructor-step-1 .custom-control-label::after {
  top: 1.25rem;
  left: -0.5rem;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a {
  width: 100%;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a svg {
  overflow: initial;
  width: 30px;
  height: 30px;
}
.profile-tabs nav a {
  font-size: 20px !important;
}
.profile-picture-wrapper {
  width: 100%;
}
.profile-picture-wrapper .user-public-profile-avatar {
  width: 100%;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--mindalay--light-gray);
}
.bread-crump .bread-crump-item {
  opacity: 0.8;
}
.bread-crump .bread-crump-item a {
  color: var(--mindalay--gray-color);
  font-weight: 600;
  cursor: pointer;
}
.bread-crump .bread-crump-item i {
  color: var(--mindalay--gray-color);
}
.bread-crump .bread-crump-item:last-of-type i {
  display: none;
}
.link-button--instructor {
  padding: 10px 20px !important;
}
.filter-collapse .filter-full-width {
  height: 100% !important;
}
.filter-collapse .collapsed {
  max-height: 230px;
  overflow: hidden;
  position: relative;
}
.filter-collapse .collapsed::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50px;
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.74833683) 50%, rgba(255, 255, 255, 0.01164216) 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
}
.collapse-btn {
  font-weight: 600;
  font-size: 13px;
}
.collapse-btn i {
  margin-left: 5px !important;
}
.reviewer-image {
  min-width: 90px;
  height: 90px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.replier-image {
  min-width: 70px;
  height: 70px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.form-control-padding {
  padding: 0.375rem 0.75rem !important;
}
.upload-file-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.upload-file-content svg {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  border-radius: 3px;
  box-sizing: content-box;
  cursor: pointer;
  background-color: var(--mindalay--white-color);
  border: 0.5px solid var(--mindalay--red-color);
  z-index: 8;
  transition: 0.3s;
}
.upload-file-content svg * {
  fill: var(--mindalay--red-color);
}
.upload-file-content svg:hover {
  background-color: var(--mindalay--red-color);
}
.upload-file-content svg:hover * {
  fill: var(--mindalay--white-color);
}
.sc-hHftDr .sc-crrsfI div {
  color: var(--mindalay--gray-color);
  font-size: 1rem;
}
.sc-hHftDr .sc-hKgILt div {
  color: var(--mindalay--gray-color);
  font-size: 1rem;
}
.sc-lmoMRL .sc-cxFLnm select {
  outline: none;
}
.sc-lmoMRL .sc-cxFLnm svg {
  top: 10px !important;
}
.sc-lmoMRL span,
.sc-lmoMRL div {
  color: var(--mindalay--gray-color);
  font-size: 0.9rem;
}
.sc-lmoMRL .sc-giIncl button {
  width: 30px;
  height: 30px;
  margin: 5px;
  padding: 0 !important;
}
.sc-lmoMRL .sc-giIncl button svg path {
  color: var(--mindalay--gray-color);
}
.data-table-extensions {
  padding: 20px 0 0 10px !important;
}
.data-table-extensions .data-table-extensions-filter {
  display: flex;
  align-items: center;
}
.data-table-extensions .data-table-extensions-filter input {
  border: none;
  border-bottom: 1px solid var(--mindalay--gray-color-lightened-30) !important;
  color: var(--mindalay--gray-color);
  padding: 5px 10px !important;
  width: 100%;
  margin: 10px 0;
}
.data-table-extensions .data-table-extensions-filter label {
  float: initial;
  background-image: url(../assets/images/explore+find+magnifier+search+icon-1320185008030646474.png) !important;
  background-size: contain;
}
.bread-crump .bread-crump-item p {
  color: #707070;
  font-weight: 600;
  cursor: pointer;
}
.file-category .bread-crump-item {
  display: flex;
  align-items: center;
}
.modal-spinner {
  position: absolute !important;
  margin: 0 auto;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partial-view-spinner-wrapper {
  width: calc(100% - 260px);
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.97);
  position: fixed;
  z-index: 11;
  top: 60px;
}
.partial-view-spinner-wrapper .page-loader {
  position: absolute;
  z-index: 10000;
  top: 45%;
}
.header-white-content ul li a {
  color: var(--mindalay--white-color);
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor a {
  width: 100%;
  text-align: center;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor a:hover {
  text-decoration: underline;
}
.header-zindex {
  z-index: 13 !important;
}
.category-conteny-zindex {
  z-index: 12 !important;
}
.instructor-header-zindex {
  z-index: 17 !important;
}
.admin-dashboard-header {
  right: 0;
}
.dropdown-item:active {
  background-color: var(--mindalay--white-color);
  color: var(--mindalay--dark-color);
}
.navbar-popup-wrapper {
  max-height: 370px;
  overflow-y: auto;
}
.header-dropdown .dropdown .dropdown-toggle {
  padding: 0;
  box-shadow: none;
}
.header-dropdown .dropdown .dropdown-toggle::after {
  display: none;
}
.header-dropdown .dropdown-menu {
  top: 15px !important;
  padding: 0 !important;
}
.header-dropdown .dropdown-menu > .profile-nav-link {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
}
.header-dropdown .dropdown-menu > .profile-nav-link h2 {
  font-size: 20px;
}
.category-filter-dropdown .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0;
  box-shadow: none;
  width: 100% !important;
  border-radius: 0 !important;
  border: none !important;
  text-align: start !important;
}
.category-filter-dropdown .dropdown .dropdown-toggle::after {
  margin-left: 0;
}
.category-filter-dropdown .dropdown .hide-arrow .dropdown-toggle::after {
  content: '' !important;
  display: none !important;
}
.category-filter-dropdown .dropdown .ul-wrapper {
  position: relative;
}
.category-filter-dropdown .dropdown .ul-wrapper .dropdown-menu {
  display: none;
}
.category-filter-dropdown .dropdown .dropdown-menu {
  top: 49px !important;
  padding: 0 !important;
  transform: none !important;
}
.category-filter-dropdown .dropdown .dropdown-menu .btn-light {
  background-color: var(--mindalay--white-color);
}
.category-filter-dropdown .dropdown .dropdown-menu .btn-light:hover {
  background-color: var(--mindalay--blue-color-lightened-50);
}
.category-filter-dropdown .dropdown .has-submenu .dropdown-menu {
  left: 100% !important;
  top: -1px !important;
  height: 100% !important;
}
.category-filter-dropdown .dropdown .has-submenu:hover > .dropdown-menu {
  display: block !important;
}
.active-category {
  background-color: var(--mindalay--blue-color-lightened-50);
}
.notification-body {
  padding: 10px;
  border-bottom: 1px solid var(--mindalay--gray-color-lightened-80);
}
.notification-body .notification-title {
  display: flex;
  align-items: center;
}
.gamify-category-bg-dark {
  background-color: var(--mindalay--blue-color-lightened-50);
}
.gamify-category-bg-light {
  background-color: var(--mindalay--blue-color-lightened-55);
}
.unread-notification {
  background-color: var(--mindalay--blue-color-lightened-50);
}
.unread-notification .notification-title {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.unread-notification .consultation-date {
  color: var(--mindalay--blue-color);
  font-weight: 600;
}
.not-subject-empty {
  padding: 20px;
  text-align: center;
}
.upload-file-bordered-wrapper {
  border: 1px solid #bdbdbd;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 3px;
}
.upload-file-bordered-wrapper > .upload-input {
  padding: 0 !important;
  padding-left: 0 !important;
  border: none !important;
}
.upload-file-bordered-wrapper button {
  white-space: nowrap;
  padding: 10px 15px !important;
  margin-right: 8px;
  background-color: var(--mindalay--brand-color);
  color: var(--mindalay--white-color);
  border-radius: 3px;
  font-size: 14px !important;
  border: none;
  cursor: pointer;
  z-index: 1;
  position: inherit !important;
  transform: none !important;
}
.upload-file-bordered-wrapper button svg {
  width: 20px;
  margin: 0 5px;
  margin-bottom: 2px;
}
.upload-file-bordered-wrapper button svg * {
  fill: var(--mindalay--white-color);
}
.content-title .dropdown-menu a {
  line-height: 1.5;
  color: var(--mindalay--dark-color);
}
.setting-dropdown {
  z-index: 2 !important;
}
.course-pass-container aside.coures-pass-left-content .mindalay--collapse-body {
  margin: 0 !important;
  padding: 0;
}
.course-pass-container .course-pass-section .mindalay--collapse-body {
  border: none !important;
  background-color: transparent !important;
  margin: 0 0.75rem;
  padding: 0.5rem 0;
  border: 1px solid #bedcf0;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-body {
  padding: 1px 0;
  border-radius: 0;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-body.disabled-course {
  opacity: 0.5;
  pointer-events: none;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-body.disabled-course .card-header {
  cursor: default;
  pointer-events: none;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header {
  transition: 0.3s;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header h5 {
  margin-left: 55px;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header span {
  position: absolute;
  left: 0;
  padding: 15px 20px;
}
.card-header,
.card-body {
  padding: 10px !important;
}
.transition {
  transition: 0.3s;
}
.header {
  z-index: 13 !important;
}
.data-table-search-right p {
  overflow: hidden;
}
.data-table-search-right .data-table-extensions {
  padding-top: 0 !important;
}
.data-table-search-right .data-table-extensions .data-table-extensions-filter {
  float: right !important;
}
.data-table-search-right button {
  padding: inherit !important;
  font-size: inherit !important;
  margin: 3px;
}
.gray-color {
  color: var(--mindalay--gray-color);
}
.filter-dropdown .dropdown .dropdown-toggle {
  border: none !important;
}
.filter-dropdown .dropdown .dropdown-toggle a::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.filter-dropdown .dropdown .dropdown-menu a {
  padding: 10px 20px;
}
.gQHdOi div:first-child {
  white-space: inherit !important;
  overflow: visible !important;
  text-overflow: inherit !important;
}
video {
  outline: none !important;
}
.section-media-wrapper .topic-image {
  background-color: var(--mindalay--light-gray);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 150px;
  width: 100%;
}
.section-media-wrapper video {
  min-height: 150px;
}
.less-title {
  width: calc(100% - 30px);
}
.mindalay--btn-link {
  white-space: inherit !important;
}
.background {
  position: relative;
}
.course-dashboard-spinner-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--mindalay--white-color);
  position: absolute;
  z-index: 11;
}
.course-dashboard-spinner-wrapper .page-loader {
  top: 20% !important;
}
.course-wrapper:hover .wish-list-image {
  transform: scale(1.1);
}
.course-wrapper .wish-list-image-wrapper {
  position: relative;
  overflow: hidden;
}
.course-wrapper .wish-list-image-wrapper span {
  z-index: 2;
  position: relative;
}
.course-wrapper .wish-list-image-wrapper .wish-list-image {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--mindalay--light-gray);
}
.course-ruls-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #0000004d;
  z-index: 10;
  justify-content: flex-end;
  padding: 5px;
}
.wish-list-icon {
  padding: 0 5px;
  margin: 0 5px;
}
.wish-list-icon svg {
  width: 20px;
  fill: var(--mindalay--white-color);
}
.mindalay--information-close-block {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.mindalay--information-close-block svg {
  min-width: 12px !important;
  max-width: 15px !important;
  cursor: pointer;
}
.mindalay--information-close-block svg:hover {
  transform: scale(1.2);
  transition: 0.3s;
}
.mindalay--information-open-block {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.mindalay--information-open-block svg {
  width: 22px;
  cursor: pointer;
  fill: var(--mindalay--blue-color);
}
.mindalay--information-open-block svg:hover {
  transform: scale(1.2);
  transition: 0.3s;
}
.mindalay--information svg {
  min-width: 20px;
  height: 20px;
}
.css-1pahdxg-control,
.form-control:focus {
  border-color: var(--mindalay--blue-color-lightened-40) !important;
  box-shadow: 0 0 0 2px var(--mindalay--blue-color-lightened-40) !important;
}
.css-g1d714-ValueContainer {
  padding: 11px 15px !important;
}
.css-wmatm6-dummyInput-DummyInput {
  font-size: initial;
}
.select-fail {
  border: 1px solid var(--mindalay--red-color) !important;
  box-shadow: 0 0 5px var(--mindalay--red-color) !important;
  border-radius: 5px !important;
}
.pagination-group .mindalay--btn-small svg {
  min-width: 10px;
  fill: var(--mindalay--brand-color);
}
.pagination-group .left svg {
  transform: rotateY(180deg);
}
.choose-video {
  width: 100%;
}
.side-left-submenu {
  z-index: 14;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.side-left-submenu .side-left-submenu-container .side-left-submenu-list .side-left-submenu-item a {
  max-width: 200px;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-name--instructor {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 5px 0;
  text-overflow: ellipsis;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-price-wrapper--instructor {
  flex-wrap: wrap;
}
.remove-category {
  box-sizing: content-box;
  padding: 5px !important;
  line-height: 0;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer !important;
  z-index: 9;
  color: var(--mindalay--red-color);
  border: 1px solid var(--mindalay--red-color);
  background-color: var(--mindalay--red-color);
  width: fit-content !important;
  opacity: 0.8;
  transition: 0.3s;
}
.remove-category:hover {
  opacity: 1;
}
.remove-category svg {
  width: 15px;
}
.remove-category svg * {
  fill: var(--mindalay--white-color);
}
.consultation-chat-input-wrapper {
  position: relative;
  padding: 15px;
}
.consultation-chat-input-wrapper input {
  padding-right: 35px !important;
  border-color: var(--mindalay--brand-color);
  padding: 13px 20px;
  min-height: 50px;
}
.consultation-chat-input-wrapper .consultation-chat-send-button {
  box-sizing: content-box;
  width: fit-content;
  position: absolute;
  background-color: var(--mindalay--white-color);
  padding: 10px;
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}
.consultation-chat-input-wrapper .consultation-chat-send-button svg {
  width: 25px;
}
.consultation-chat-input-wrapper .consultation-chat-send-button svg * {
  fill: var(--mindalay--brand-color);
}
.course-feedback-reply-spinner-block {
  box-sizing: content-box;
  width: fit-content;
  position: absolute;
  padding: 10px;
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}
.mindalay--consultation-tab .consultation-chat-message-container p {
  overflow: hidden;
}
.mindalay--consultation-tab .consultation-chat-message .consultation-chat-sender-wrapper {
  margin: 0;
}
.mindalay--consultation-tab .consultant-chat-sender .consultation-chat-message .consultation-chat-message-text {
  color: var(--mindalay--gray-color);
}
.mindalay--consultation-tab .consultant-chat-sender .consultation-chat-message span {
  color: var(--mindalay--gray-color);
  text-align: left !important;
}
.question-icon-wrapper {
  cursor: pointer;
}
.question-icon-wrapper i {
  font-size: 35px;
  color: var(--mindalay--secondary-color);
}
.alert-wrapper {
  position: absolute;
  color: var(--mindalay--gray-color);
  z-index: 12;
  min-width: 270px;
  max-width: 300px;
  max-height: 500px;
}
.alert-wrapper .alert-block {
  background-color: var(--mindalay--white-color);
  padding: 7px 15px;
  border-radius: 5px;
  position: relative;
  font-weight: normal !important;
  font-size: 13px !important;
  border: 1px solid var(--mindalay--gray-color-lightened-40);
}
.alert-wrapper .alert-block hr {
  background-color: var(--mindalay--gray-color) !important;
  margin: 8px 0;
}
.alert-wrapper .alert-block p {
  margin: 0;
  font-size: 13px !important;
  max-height: 450px;
  overflow-y: auto;
  color: var(--mindalay--gray-color);
  letter-spacing: normal;
  text-transform: none;
}
.alert-wrapper .alert-block p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: var(--mindalay--gray-color);
}
.alert-wrapper .alert-block p::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: var(--mindalay--gray-color);
}
.alert-wrapper .alert-block p::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.alert-wrapper .alert-block button {
  flex: inherit;
  line-height: 0 !important;
  padding: 3px 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  background-color: var(--mindalay--gray-color-lightened-30);
}
.alert-wrapper .alert-block button i {
  font-size: 14px !important;
  margin: 0;
}
.alert-wrapper .alert-block button:hover {
  background-color: var(--mindalay--gray-color-lightened-10);
}
.alert-wrapper .alert-block button:hover i {
  color: var(--mindalay--white-color);
}
html {
  scroll-behavior: smooth;
}
.help-bottom::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--mindalay--gray-color);
  position: absolute;
  bottom: -10px;
  display: block;
}
.help-bottom-right::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--mindalay--gray-color);
  position: absolute;
  bottom: -10px;
  right: 13px;
  display: block;
}
.help-top::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--mindalay--gray-color);
  display: block;
  position: absolute;
  top: -10px;
}
.help-right::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--mindalay--gray-color);
  display: block;
  position: absolute;
  right: -10px;
}
.help-left::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #367ba7e3;
  border-bottom: 10px solid transparent;
  display: block;
  position: absolute;
  left: -10px;
}
.help-bottom-center::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #0f1013;
  position: absolute;
  bottom: -10px;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.help-top-center::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #0f1013;
  display: block;
  position: absolute;
  top: -10px;
  left: var(--left);
  right: 0;
  margin: 0 auto;
}
.mindalat--interview-container {
  flex: 1 !important;
}
.invitation-link {
  color: var(--mindalay--black-color);
}
.invitation-link::first-letter {
  text-transform: lowercase;
}
.mindalay--card {
  border: none;
  transition: 0.3s;
  border: 1px solid var(--mindalay--gray-color-lightened-80);
}
.mindalay--card .card-text {
  padding: 10px 60px 10px 15px;
  background-color: var(--mindalay--secondary-color-lightened-50);
  border-radius: 3px;
  color: var(--mindalay--gray-color-lightened-40);
  position: relative;
  font-size: 16px !important;
  background-color: var(--mindalay--dark-color-lightened-80);
}
.mindalay--card .card-text p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
  color: var(--mindalay--dark-color);
  user-select: none;
}
.mindalay--card .card-text .mindalay--paragrap-iner-item {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--mindalay--white-color);
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--mindalay--brand-color-lightened-10);
  padding: 5px 10px;
  font-size: 10px;
}
.mindalay--card .card-text .mindalay--paragrap-iner-item::after {
  content: attr(rel);
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 5px 10px;
  transform: translate(-50%, calc(-100% - 8px));
  background-color: var(--mindalay--brand-color);
  border-radius: 5px;
  width: max-content;
}
.mindalay--card .card-text .mindalay--paragrap-iner-item::before {
  display: none;
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-150% - 1px)) rotate(45deg);
  background-color: var(--mindalay--brand-color);
}
.mindalay--card .card-text .show-copy-message:not(:active) {
  background-color: var(--mindalay--secondary-color);
}
.mindalay--card .card-text .show-copy-message::after,
.mindalay--card .card-text .show-copy-message::before {
  display: block !important;
}
.mindalay--card .mindalay--card-span-group {
  display: flex;
}
.mindalay--card .mindalay--card-span-group .webinar-start-time,
.mindalay--card .mindalay--card-span-group .webinar-end-time {
  width: 50%;
}
.mindalay--card .mindalay--card-span-group .webinar-start-time legend,
.mindalay--card .mindalay--card-span-group .webinar-end-time legend {
  color: var(--mindalay--gray-color-lightened-30);
}
.mindalay--card .mindalay--card-span-group .webinar-start-time {
  margin-right: 3px;
}
.mindalay--card .mindalay--card-span-group .webinar-start-time span {
  background-color: var(--mindalay--green-color-lightened-50);
  color: var(--mindalay--green-color);
}
.mindalay--card .mindalay--card-span-group .webinar-end-time {
  margin-left: 3px;
}
.mindalay--card .mindalay--card-span-group .webinar-end-time span {
  background-color: var(--mindalay--red-color-lightened-50);
  color: var(--mindalay--red-color);
}
.mindalay--card .mindalay--card-span-group span {
  font-weight: 600;
  padding: 5px 15px;
  position: relative;
  border-radius: 3px;
  display: inline-block;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  text-align: center;
}
.mindalay--card .card-title {
  color: var(--mindalay--gray-color);
  font-size: 18px;
}
.mindalay--some-process-status {
  font-size: 16px !important;
  position: relative;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mindalay--some-process-status.text-danger::before {
  background-color: var(--mindalay--red-color);
}
.mindalay--some-process-status.text-success::before {
  background-color: var(--mindalay--green-color);
}
.mindalay--some-process-status.text-warning::before {
  background-color: var(--mindalay--orange-color);
}
.mindalay--some-process-status.text-info::before {
  background-color: var(--mindalay--blue-color);
}
.navbar-profile-dropdown ::-webkit-scrollbar {
  width: 8px;
}
.basic-single ::-webkit-scrollbar {
  width: 8px !important;
}
.header-popup {
  overflow-y: auto;
}
.header-popup a:hover {
  background-color: transparent !important;
}
.notification-container {
  width: 500px;
  margin-bottom: 15px;
  top: 50px;
  padding: 0 5px;
}
.notification-container h4 {
  color: var(--mindalay--white-color);
}
.notification-container .notification {
  padding: 0px 5px 0px 45px;
  border-radius: 5px;
  box-shadow: none;
  opacity: 1 !important;
}
.notification-container .notification::before {
  left: 10px;
  font-size: 20px !important;
}
.notification-container .notification-success {
  background-color: var(--mindalay--dark-color);
  border: 1px solid var(--mindalay--green-color);
}
.notification-container .notification-success::before {
  font-size: 25px;
  color: var(--mindalay--green-color);
}
.notification-container .notification-warning {
  background-color: var(--mindalay--dark-color);
  border: 1px solid var(--mindalay--orange-color);
}
.notification-container .notification-warning::before {
  font-size: 25px;
  color: var(--mindalay--orange-color);
}
.notification-container .notification-error {
  background-color: var(--mindalay--dark-color);
  border: 1px solid var(--mindalay--red-color);
}
.notification-container .notification-error::before {
  font-size: 25px;
  color: var(--mindalay--red-color);
}
.notification-container .notification-info {
  background-color: var(--mindalay--dark-color);
  border: 1px solid var(--mindalay--blue-color);
}
.notification-container .notification-info::before {
  font-size: 25px;
  color: var(--mindalay--blue-color);
}
.notification-container .title {
  margin: 0;
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.authorization-form .authorization-input {
  margin: 30px 0 5px 0 !important;
}
.modal-icon-width {
  min-width: 40px;
  text-align: center;
}
.modal-icon-width svg * {
  fill: var(--mindalay--brand-color);
}
.verified-courses .sc-dIUggk .sc-jrAGrp,
.unverified-courses .sc-dIUggk .sc-jrAGrp,
.in-process-tickets .sc-dIUggk .sc-jrAGrp,
.resolved-tickets .sc-dIUggk .sc-jrAGrp {
  padding: 0;
}
.verified-courses .sc-dIUggk .sc-jrAGrp .sc-hKgILt div,
.unverified-courses .sc-dIUggk .sc-jrAGrp .sc-hKgILt div,
.feedback-courses .sc-dIUggk .sc-jrAGrp .sc-hKgILt div,
.in-process-tickets .sc-dIUggk .sc-jrAGrp .sc-hKgILt div,
.resolved-tickets .sc-dIUggk .sc-jrAGrp .sc-hKgILt div {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.payment-name span {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: var(--mindalay--secondary-color) !important;
}
.currency-select-option {
  min-width: 150px;
  flex: 1;
}
.currency-select {
  width: 120px;
}
.title {
  opacity: 1 !important;
}
.mindalay--consultation-container .consultation-description {
  -webkit-line-clamp: 4 !important;
}
.consultation-status-item {
  padding: 5px 15px;
  margin-right: 3px;
  border-radius: 3px;
  width: fit-content;
  font-weight: 600;
  transition: 0.3s;
}
.consultation-status-item a,
.consultation-status-item span {
  color: var(--mindalay--white-color);
  font-size: 14px;
}
.consultation-status-item a:hover,
.consultation-status-item span:hover {
  color: var(--mindalay--white-color);
}
.consultation-status-item.proposal {
  border: 1px solid var(--mindalay--secondary-color);
  transition: 0.2s;
}
.consultation-status-item.proposal a,
.consultation-status-item.proposal span {
  transition: 0.2s;
  color: var(--mindalay--secondary-color);
}
.consultation-status-item.proposal:hover {
  background-color: var(--mindalay--secondary-color-lightened-minus-5);
}
.consultation-status-item.proposal:hover a,
.consultation-status-item.proposal:hover span {
  transition: 0.2s;
  color: var(--mindalay--white-color);
}
.consultation-status-item.in-process {
  border: 1px solid var(--mindalay--green-color);
}
.consultation-status-item.in-process a,
.consultation-status-item.in-process span {
  transition: 0.2s;
  color: var(--mindalay--green-color);
}
.consultation-status-item.in-process:hover {
  background-color: var(--mindalay--green-color-lightened-minus-5);
}
.consultation-status-item.in-process:hover a,
.consultation-status-item.in-process:hover span {
  color: var(--mindalay--white-color);
  transition: 0.2s;
}
.consultation-status-item.interview {
  border: 1px solid var(--mindalay--orange-color);
}
.consultation-status-item.interview a,
.consultation-status-item.interview span {
  transition: 0.2s;
  color: var(--mindalay--orange-color);
}
.consultation-status-item.interview:hover {
  background-color: var(--mindalay--orange-color-lightened-minus-5);
}
.consultation-status-item.interview:hover a,
.consultation-status-item.interview:hover span {
  transition: 0.2s;
  color: var(--mindalay--white-color);
}
.consultation-status-item.completed {
  border: 1px solid var(--mindalay--red-color);
}
.consultation-status-item.completed a,
.consultation-status-item.completed span {
  transition: 0.2s;
  color: var(--mindalay--red-color);
}
.consultation-status-item.completed:hover {
  background-color: var(--mindalay--red-color-lightened-minus-5);
}
.consultation-status-item.completed:hover a,
.consultation-status-item.completed:hover span {
  transition: 0.2s;
  color: var(--mindalay--white-color);
}
.consultation-date {
  margin-top: 3px !important;
}
.mindalay--search-input-wrapper .mindalay--search-input {
  cursor: default !important;
  right: 1px !important;
  top: 50% !important;
  transform: translateY(-50%);
  background-color: var(--mindalay--white-color) !important;
  padding: 12px !important;
}
.mindalay--search-input-wrapper .mindalay--search-input svg {
  width: 20px;
}
.mindalay--search-input-wrapper .mindalay--search-input svg * {
  fill: var(--mindalay--gray-color) !important;
}
.sc-hKgILt > div {
  width: 100%;
}
.nav-tabs .nav-link.active {
  background-color: var(--mindalay--white-color) !important;
}
.payment-container {
  border: 1px solid transparent;
}
.payment-container .payment-logo-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  margin: 5px;
  margin-left: 0;
}
.payment-container .payment-logo-block img {
  width: auto;
  height: 25px;
  filter: grayscale(100%);
}
.payment-container .active-payment-system {
  color: var(--mindalay--brand-color) !important;
}
.payment-container .active-payment-system img {
  filter: none;
}
.payment-container .active-payment-system svg * {
  fill: var(--mindalay--brand-color) !important;
}
.payments-logos-container .payment-logo-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  margin: 5px;
  margin-left: 0;
  border: 1px solid transparent;
}
.payments-logos-container .payment-logo-block img {
  width: auto;
  height: 25px;
  filter: grayscale(100%);
}
.payments-logos-container .active-payment-system {
  border: 2px solid var(--mindalay--secondary-color) !important;
  border-radius: 5px;
  color: var(--mindalay--brand-color) !important;
}
.payments-logos-container .active-payment-system img {
  filter: none;
}
.payments-logos-container .active-payment-system svg * {
  fill: var(--mindalay--brand-color) !important;
}
.mindalay--contract-date {
  position: relative;
}
.mindalay--contract-date .info-local-alert {
  position: absolute;
  background-color: var(--mindalay--white-color);
  padding: 10px 15px;
  z-index: 10;
  right: 0;
  top: -110px;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  margin: 0;
  text-align: center;
}
.square-holder {
  padding: 30px;
  border: 1px solid var(--mindalay--gray-color-lightened-75);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 120px;
  cursor: pointer;
}
.square-holder img {
  max-width: 150px;
  max-height: 80px;
  filter: grayscale(80%);
  transition: 0.1s;
}
.square-holder:hover img {
  filter: none;
}
.consultation-date {
  color: var(--mindalay--gray-color);
  font-size: 13px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.consultation-date span {
  display: block;
}
.navbar .search-wrapper i:first-child {
  left: 15px;
  top: 20px;
  z-index: 1;
}
.no-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data-container .no-data-wrapper {
  display: block;
  text-align: center;
}
.no-data-container .no-data-wrapper img {
  max-width: 350px;
}
.no-data-container .no-data-wrapper p {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 15px;
}
.folder-button {
  width: 200px;
  margin: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.general-messages .messages-users-container {
  height: 100%;
}
.general-messages .messages-users-container .message-users-container-height {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}
.general-messages .messages-users-container .messages-user-block {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}
.general-messages .messages-users-container .messages-user-block .message-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  border: 0.5px solid var(--mindalay--white-color);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.general-messages .messages-users-container .messages-user-block h3 {
  font-size: 16px;
  margin-left: 10px;
}
.general-messages .tab-pane {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.message-unread-count {
  position: absolute;
  right: 40px;
  background-color: var(--mindalay--secondary-color);
  /* padding: 5px; */
  border-radius: 50%;
  line-height: 1;
  color: var(--mindalay--white-color);
  font-size: 12px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-send-button {
  background: transparent;
  border: none;
}
.autocomplete-block {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10;
  top: 55px;
  width: 100%;
  background-color: var(--mindalay--white-color);
  border-radius: 3px;
  max-height: 500px;
  overflow-y: auto;
}
.autocomplete-block .autocomplete-item {
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.autocomplete-block .autocomplete-item a {
  color: var(--mindalay--gray-color-lightened-20);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 100%;
}
.autocomplete-block .autocomplete-item:hover {
  background-color: var(--mindalay--brand-color-lightened-80);
}
.autocomplete-block .autocomplete-item:hover a {
  color: var(--mindalay--brand-color-lightened-10);
}
.autocomplete-block .autocomplete-item i {
  color: var(--mindalay--gray-color-lightened-10);
  margin-right: 10px;
  font-size: 15px;
}
.navbar .header-popup {
  min-width: 300px !important;
  overflow-y: auto;
  max-height: calc(100vh - 190px);
}
.paymentForm {
  display: flex;
}
.registration-success {
  font-size: 50px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--mindalay--green-color);
  width: 100%;
}
.course-status--instructor {
  color: var(--mindalay--white-color);
  border-radius: 3px;
}
.course-submit-verified--instructor {
  background-color: var(--mindalay--orange-color);
}
.course-denied--instructor {
  background-color: var(--mindalay--red-color);
}
.course-verified--instructor {
  background-color: var(--mindalay--brand-color);
}
.course-inprocess--instructor {
  background-color: var(--mindalay--blue-color);
}
.has-value::after {
  content: attr(rel);
  position: absolute;
  right: 15px;
  top: 50%;
  background-color: var(--mindalay--brand-color);
  transform: translateY(-50%);
  font-size: 14px;
  padding: 3px 7px;
  border-radius: 3px;
  text-align: center;
  color: var(--mindalay--white-color);
}
.open-burger-menu {
  padding-right: 0 !important;
}
.instructor-consultant-navbar .open-burger-menu {
  display: none;
}
.instructor-consultant-navbar .nav-link svg * {
  fill: var(--mindalay--brand-color) !important;
}
@media (min-width: 991.98px) {
  .list-fixed {
    position: fixed;
    top: 75px;
  }
}
.side-menu-container .layer,
.help-side-menu-container .layer {
  background: var(--mindalay--black-color);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 16;
  opacity: 0.8;
}
.show-general-left-menu {
  display: block !important;
}
.show-help-left-menu {
  display: block !important;
}
.info-alert-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-alert-container .info-alert-block {
  padding: 40px;
  z-index: 10;
  background: var(--mindalay--white-color);
  border-radius: 5px;
  max-width: 450px;
}
.info-alert-container .layer {
  position: absolute;
  background: var(--mindalay--black-color);
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
.info-alert-container .close-svg {
  position: absolute;
  right: -20px;
  top: -25px;
  cursor: pointer;
}
.info-alert-container .close-svg svg {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}
.zoom-image-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1060;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}
.zoom-image-container .zoom-image-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px) !important;
}
.zoom-image-container img {
  max-height: calc(100% - 50px);
  max-width: calc(100% - 50px);
}
.zoom-image-container video {
  max-height: calc(100% - 50px);
  max-width: calc(100% - 50px);
}
.zoom-image-container .close-svg {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.zoom-image-container .close-svg svg {
  width: 30px;
  fill: var(--mindalay--white-color);
  transition: 0.2s;
}
.zoom-image-container .close-svg svg:hover {
  transform: scale(1.07);
  transition: 0.2s;
}
.zoom-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.zoom-image-container .zoom-image-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--mindalay--black-color);
  opacity: 0.8;
  z-index: 14;
}
.zoom-image-container:hover .bottom-block {
  bottom: 10px !important;
}
.pagination-dropdown {
  width: 90px !important;
}
.course-status {
  z-index: 1;
}
.burger-menu-container ::-webkit-scrollbar {
  width: 0 !important;
}
.remove-or-move-course {
  display: flex;
  align-items: center;
}
.user-burger-scroll-block {
  height: calc(100vh - 30px);
  overflow-y: auto;
}
.user-burger-scroll-block .profile-block {
  position: fixed;
  background: var(--mindalay--white-color) !important;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.payment-modal-status-icon-block {
  display: flex;
  justify-content: center;
}
.transaction-successful {
  font-size: 60px;
  color: var(--mindalay--green-color);
}
.transaction-unsuccessful {
  font-size: 60px;
  color: var(--mindalay--red-color);
}
.navbar .search-wrapper .close-search-input {
  right: 6px;
  padding: 8px 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: var(--mindalay--brand-color);
  cursor: pointer;
}
.active-search {
  background-color: var(--mindalay--brand-color-lightened-80);
}
.active-search a,
.active-search span {
  color: var(--mindalay--brand-color-lightened-20) !important;
}
.admin--header {
  height: 70px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.admin-dashboard-body--large {
  margin-left: 70px !important;
}
.admin-quiz-view table th {
  width: 30%;
}
.admin-quiz-view table .question-block {
  transition: 0.2s;
}
.admin-quiz-view table .question-block td p {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.admin-quiz-view table .question-block:hover {
  background-color: #EEEEEE;
}
.quiz-question-modal-body,
.course-discount-modal-body {
  max-height: 500px;
  overflow: auto;
}
.topic-spinner-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 12;
  top: 0;
  position: absolute;
}
.admin-message-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.admin-message-container .message-send-button {
  background: transparent;
  border: none;
}
.close-admin-left-menu {
  position: absolute;
  left: 80px;
}
.close-admin-left-menu svg {
  height: 20px;
}
.close-admin-left-menu svg * {
  fill: var(--mindalay--gray-color) !important;
  margin-left: 50px;
}
.notification-circle {
  width: 15px;
  height: 15px;
  background-color: var(--mindalay--blue-color-lightened-20);
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
}
.notification-circle:hover {
  background-color: var(--mindalay--blue-color-lightened-minus-10);
  transition: 0.2s;
}
.file-video {
  width: 100px;
}
.pass-course-burger span,
.pass-course-burger ::after,
.pass-course-burger ::before {
  background-color: var(--mindalay--brand-color) !important;
}
.my-anchor-css-class {
  color: var(--mindalay--secondary-color);
  font-size: 14px;
}
.my-anchor-css-class:hover {
  color: var(--mindalay--secondary-color);
}
.table-action-btn-mini-svg {
  padding: 0;
  border: none !important;
  line-height: 0;
  transition: 0.2s;
}
.table-action-btn-mini-svg:hover {
  background-color: transparent !important;
  transition: 0.2s;
}
.table-action-btn-mini-svg:hover svg {
  height: auto;
  width: 17px !important;
}
.table-action-btn-mini-svg svg {
  height: auto;
  width: 15px !important;
}
.table-action-btn-mini-svg svg * {
  fill: var(--mindalay--secondary-color) !important;
}
.modal-window {
  margin-top: 100px;
}
.course-view-description ul li {
  list-style: disc !important;
  padding: auto !important;
}
.course-view-description ol li {
  list-style: auto !important;
}
.css-1hwfws3 {
  padding: 10px !important;
}
.red-color {
  font-weight: 500;
}
.mindalay-business-info-container img {
  max-width: 550px;
}
section.pricing .card {
  height: 100%;
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
section.pricing hr {
  margin: 1.5rem 0;
}
section.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}
section.pricing .card-price {
  margin: 0;
  color: var(--mindalay--secondary-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
section.pricing .card-price .period {
  font-size: 0.8rem;
}
section.pricing li {
  margin-bottom: 1rem;
}
section.pricing .text-muted {
  opacity: 0.7;
}
.info-cover-image-wrapper .info-cover-text-wrapper .info-cover-title {
  color: var(--mindalay--red-color);
  position: relative;
}
.info-cover-image-wrapper .info-cover-text-wrapper .info-cover-subtitle {
  font-style: italic;
}
.info-section-container {
  margin: 3.5rem 0;
}
.info-section-container .info-section-text-container .sub-list {
  margin-top: 10px;
  padding-left: 35px;
  list-style: disc !important;
}
.info-section-container .info-section-text-container .sub-list li {
  list-style: disc !important;
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-style: italic !important;
  font-size: 16px;
}
.info-section-container .info-section-text-container > li {
  padding: 10px;
  font-size: 20px;
  padding-left: 60px;
  color: var(--mindalay--black-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  position: relative;
}
.info-section-container .info-section-text-container > li::before {
  content: "—";
  color: var(--mindalay--gray-color);
  float: left;
  margin-left: -2em;
}
.info-section-container .info-section-text-container > li p {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.info-section-container .info-section-image {
  max-width: 400px;
}
.third-level-categories-block {
  width: 100%;
  height: 100%;
  position: relative;
}
.react-share__ShareButton {
  margin: 5px;
}
.react-share__ShareButton:focus {
  outline: none;
}
.plus-svg-in-input {
  height: 47px !important;
}
.keyword-description {
  max-width: 300px;
  overflow: hidden;
}
.rdt_Table {
  max-width: 100vw !important;
}
.second-level-categories-admin {
  margin: 10px 0 10px;
  width: 90%;
}
.organization-dropdown-sub-pages {
  border-left: 1px solid var(--mindalay--white-color);
}
.unread-messages-count-org {
  background-color: var(--mindalay--secondary-color);
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 15px;
}
.not-found {
  width: 100%;
  height: 100%;
  background-color: var(--mindalay--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found svg {
  width: 500px;
  height: 500px;
}
.not-found .cls-1 {
  fill: #a8a8a8;
}
.not-found .cls-2 {
  fill: #dcdcdc;
}
.not-found .cls-3 {
  fill: #ececec;
}
.not-found .cls-4 {
  fill: #cbcbff;
}
.not-found .cls-5 {
  fill: #263238;
}
.not-found .cls-6 {
  fill: #ffba41;
}
.not-found .cls-7 {
  opacity: 0.1;
}
.not-found .cls-8 {
  fill: #1a2e35;
}
.not-found .cls-9 {
  fill: #ff9581;
}
.not-found .cls-10 {
  fill: #32064a;
}
.not-found .cls-11 {
  fill: #786ab5;
}
.not-found .cls-12 {
  fill: #fff;
}
.not-found .cls-13 {
  fill: #ff99ba;
}
.not-found .cls-14 {
  opacity: 0.3;
}
.join-webinar-svg {
  max-width: 700px;
  margin: 0 auto;
}
.join-webinar-svg .cls-1 {
  opacity: 0.38;
}
.join-webinar-svg .cls-2 {
  fill: #e2e7ff;
}
.join-webinar-svg .cls-3 {
  fill: #32064a;
}
.join-webinar-svg .cls-4 {
  opacity: 0.5;
}
.join-webinar-svg .cls-5 {
  fill: #68a1bf;
}
.join-webinar-svg .cls-6 {
  fill: #ffb27d;
}
.join-webinar-svg .cls-7 {
  fill: #92bcb5;
}
.join-webinar-svg .cls-8 {
  fill: #786ab5;
}
.join-webinar-svg .cls-9 {
  fill: #764678;
}
.join-webinar-svg .cls-10 {
  fill: #331832;
}
.join-webinar-svg .cls-11 {
  fill: #ffba41;
}
.join-webinar-svg .cls-12 {
  fill: #1a2e35;
}
.join-webinar-svg .cls-13 {
  fill: #080435;
}
.join-webinar-svg .cls-14 {
  fill: #fff;
}
.join-webinar-svg .cls-15 {
  opacity: 0.3;
}
.join-webinar-svg .cls-16 {
  fill: #b8d5dc;
}
.join-webinar-svg .cls-17 {
  fill: #444092;
}
.join-webinar-svg .cls-18 {
  fill: #366f90;
}
.join-webinar-svg .cls-19 {
  fill: #e4ecff;
}
.join-webinar-svg .cls-20 {
  fill: #e9845c;
}
.join-webinar-svg .cls-21 {
  fill: #f69b6e;
}
.join-webinar-svg .cls-22 {
  fill: #ececec;
}
.join-webinar-svg .cls-23 {
  fill: #5c305d;
}
.scroll-top-block {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mindalay--brand-color);
  border-radius: 5px;
  z-index: 15;
  box-shadow: 0 0 2px 1px var(--mindalay--white-color);
}
.scroll-top-block i {
  color: var(--mindalay--white-color);
}
.day-of-the-week-block-item {
  height: auto !important;
}
.day-of-the-week-block {
  border: 0.1px solid var(--mindalay--black-color-lightened-70);
  min-height: 300px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.day-of-the-week-block .week-days {
  height: 210px;
  overflow: auto;
}
.day-of-the-week-block .title-block {
  border-bottom: 0.5px solid var(--mindalay--black-color-lightened-70);
  display: flex;
  justify-content: space-between;
  background-color: var(--mindalay--gray-color-lightened-90);
  padding: 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.day-of-the-week-block .title-block label {
  font-weight: 600;
}
.day-of-the-week-block .title-block .plus-minus-block {
  display: flex;
  align-items: center;
}
.day-of-the-week-block .title-block .plus-minus-block i {
  margin: 2px;
  color: var(--mindalay--green-color);
  font-size: 30px;
  cursor: pointer;
}
.org-student-info-block {
  text-align: center;
}
.org-student-info-block img {
  max-height: 100px;
}
.help-topic-img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-size: contain;
}
.category-field,
.category-field-item {
  transition: 0.2s;
}
.category-field:hover,
.category-field-item:hover {
  color: var(--mindalay--secondary-color) !important;
}
.payment-status {
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
  width: fit-content;
}
.faq-topic-body li {
  list-style: initial;
  margin-left: 40px;
}
.faq-topic-container .faq-wrapper,
.help-topic-container .faq-wrapper,
.faq-topic-container .help-wrapper,
.help-topic-container .help-wrapper {
  transition: 0.3s;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: none;
}
.faq-topic-container .faq-wrapper .card-body,
.help-topic-container .faq-wrapper .card-body,
.faq-topic-container .help-wrapper .card-body,
.help-topic-container .help-wrapper .card-body {
  text-align: center;
  cursor: pointer;
}
.faq-topic-container .faq-wrapper .card-body p,
.help-topic-container .faq-wrapper .card-body p,
.faq-topic-container .help-wrapper .card-body p,
.help-topic-container .help-wrapper .card-body p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: var(--mindalay--gray-color);
}
.faq-topic-container .faq-wrapper .card-body .faq-description,
.help-topic-container .faq-wrapper .card-body .faq-description,
.faq-topic-container .help-wrapper .card-body .faq-description,
.help-topic-container .help-wrapper .card-body .faq-description {
  -webkit-line-clamp: 2;
  height: 45px;
  text-transform: inherit !important;
  display: block;
}
.faq-topic-container .faq-wrapper .card-body .faq-description::first-letter,
.help-topic-container .faq-wrapper .card-body .faq-description::first-letter,
.faq-topic-container .help-wrapper .card-body .faq-description::first-letter,
.help-topic-container .help-wrapper .card-body .faq-description::first-letter {
  text-transform: uppercase !important;
}
.faq-topic-container .faq-wrapper .card-body .topic-description,
.help-topic-container .faq-wrapper .card-body .topic-description,
.faq-topic-container .help-wrapper .card-body .topic-description,
.help-topic-container .help-wrapper .card-body .topic-description {
  -webkit-line-clamp: 4;
  height: 85px;
}
.faq-topic-container .faq-wrapper:hover,
.help-topic-container .faq-wrapper:hover,
.faq-topic-container .help-wrapper:hover,
.help-topic-container .help-wrapper:hover {
  transform: scale(1.02);
}
.faq-topic-container .faq-wrapper p,
.help-topic-container .faq-wrapper p {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.faq-topic-container .help-wrapper svg,
.help-topic-container .help-wrapper svg {
  max-width: 60px;
  display: block;
  margin: 0 auto 15px auto;
}
.faq-topic-container .help-wrapper strong,
.help-topic-container .help-wrapper strong {
  color: var(--mindalay--gray-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
}
.faq-topic-container .help-wrapper p,
.help-topic-container .help-wrapper p {
  font-size: 14px;
}
.help-top-sections {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
}
.help-top-sections .help-top-section {
  transition: 0.3s;
  border: 0.5px solid var(--mindalay--gray-color-lightened-70);
  margin: 3px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
}
.help-top-sections .help-top-section a {
  font-size: 20px;
  color: var(--mindalay--black-color);
  font-weight: 500;
}
.help-top-sections .help-top-section:hover {
  transform: scale(1.01);
}
.help-top-sections .help-top-section.help-top-active-section {
  background-color: var(--mindalay--brand-color);
}
.help-top-sections .help-top-section.help-top-active-section a {
  color: var(--mindalay--white-color);
}
.edit-group,
.delete-group,
.game-group {
  width: 30px;
  height: 30px;
}
.edit-group .edit-group-item,
.delete-group .edit-group-item,
.game-group .edit-group-item,
.edit-group .delete-group-item,
.delete-group .delete-group-item,
.game-group .delete-group-item,
.edit-group .game-group-item,
.delete-group .game-group-item,
.game-group .game-group-item {
  width: 30px;
  height: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: var(--mindalay--white-color);
  display: block;
  line-height: 0;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--mindalay--secondary-color);
}
.edit-group svg,
.delete-group svg,
.game-group svg {
  width: 15px;
  height: 15px;
}
.edit-group svg *,
.delete-group svg *,
.game-group svg * {
  fill: var(--mindalay--secondary-color);
}
.delete-group .delete-group-item {
  border: 1px solid var(--mindalay--red-color);
}
.delete-group svg {
  width: 15px;
  height: 15px;
}
.delete-group svg * {
  fill: var(--mindalay--red-color);
}
.game-group .game-group-item {
  border: 1px solid var(--mindalay--green-color);
}
.game-group svg {
  width: 15px;
  height: 15px;
}
.game-group svg * {
  fill: var(--mindalay--green-color);
}
.group-student-checkbox {
  filter: var(--mindalay--brand-color);
}
.crop-container {
  width: 800px;
  height: 800px;
  top: 0;
  right: 0;
  position: absolute;
}
.payment-card {
  border: 2px solid transparent !important;
}
.payment-card .card-body {
  display: flex;
  flex-direction: column;
}
.payment-card-item {
  border: 3px solid transparent !important;
}
.active-card {
  border: 2px solid var(--mindalay--secondary-color) !important;
  transform: translateY(-10px);
  transition: 0.5s !important;
}
.active-card-item {
  border: 3px solid var(--mindalay--secondary-color) !important;
}
#column-isActiveField,
#column-isPaidField,
#column-recurringPaymentField {
  display: flex;
  justify-content: center;
}
.faq-body img {
  max-width: 100% !important;
}
.page-item.active .page-link {
  color: var(--mindalay--white-color);
  background-color: var(--mindalay--brand-color);
  border-color: var(--mindalay--brand-color);
}
.pagination {
  z-index: 1;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: left;
}
.pagination .page-link {
  color: var(--mindalay--brand-color);
  outline: none;
}
.mindayal-admin-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.mindayal-admin-table .mindalay-admin-table-td-item {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mindayal-admin-table .border-none {
  border: none !important;
}
.mindayal-admin-table td,
.mindayal-admin-table th {
  border: 1px solid var(--mindalay--gray-color-lightened-80);
  text-align: left;
  padding: 8px;
}
.mindayal-admin-table td {
  min-height: 40px;
}
.mindayal-admin-table td.border_none {
  border: none !important;
}
.mindayal-admin-table thead:hover {
  cursor: default;
}
.mindayal-admin-table thead:hover tr {
  background-color: var(--mindalay--white-color);
}
.mindayal-admin-table tbody:hover {
  cursor: pointer;
}
.mindayal-admin-table tr {
  transition: 0.2s;
}
.mindayal-admin-table tr:hover {
  background-color: var(--mindalay--gray-color-lightened-90);
  transition: 0.2s;
}
.alertBlockWithCloseButton {
  width: 100%;
  position: relative;
  z-index: -1;
  top: 0;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.alertBlockWithCloseButton p,
.alertBlockWithCloseButton i {
  color: var(--mindalay--white-color) !important;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.alertBlockWithCloseButtonSuccess {
  background-color: var(--mindalay--green-color);
}
.alertBlockWithCloseButtonWarning {
  background-color: var(--mindalay--orange-color);
}
.alertBlockWithCloseButtonWrror {
  background-color: var(--mindalay--red-color);
}
.info-main-image {
  height: 500px;
  max-height: 500px;
  width: 100%;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .info-main-image {
    display: none;
  }
}
.payment-package-active {
  border-color: var(--mindalay--secondary-color) !important;
}
.course-description-error {
  border: 2px solid var(--mindalay--red-color);
}
.contact-us {
  color: var(--mindalay--white-color);
  text-decoration: revert;
}
.contact-us:hover {
  color: var(--mindalay--white-color);
  text-decoration: revert;
}
.mindalay-alert-container {
  position: fixed;
  right: 10px;
  top: 70px;
  z-index: 1100;
}
.mindalay-alert-container .mindalay-alert-block {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  padding: 15px 5px;
  border-radius: 10px;
  z-index: 49;
  background-color: var(--mindalay--dark-color-darkened-5);
  max-width: 500px;
  width: 100%;
  min-width: 300px;
  margin-bottom: 10px;
}
.mindalay-alert-container .mindalay-alert-block .alert-block-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.mindalay-alert-container .mindalay-alert-block .alert-block-wrapper .alertText {
  color: var(--mindalay--white-color);
  margin: 0 15px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mindalay-alert-container .mindalay-alert-block .alert-block-wrapper img {
  width: 25px;
  opacity: 0.9;
}
.mindalay-alert-container .mindalay-alert-block .alert-block-wrapper .close-icon {
  margin-left: auto;
  width: 20px !important;
  cursor: pointer;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.keyword-ckeckbox-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-list {
  cursor: default !important;
}
.line-loader {
  height: 20px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: var(--mindalay--blue-color-lightened-45);
  width: 100%;
  overflow: hidden;
}
.line-loader .line-loader-item {
  width: 0%;
  height: 100%;
  background-color: var(--mindalay--blue-color);
  border-radius: 5px;
}
.line-loader-org-student-course {
  height: 10px;
}
.course-message-block {
  max-width: 800px;
  overflow: hidden;
}
.course-title-in-header {
  max-width: 300px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 36px;
  align-items: flex-start !important;
}
.iRhjJW {
  font-size: 14px !important;
}
.payment-system-icon-block svg {
  width: 50px;
  height: 30px;
}
.chat-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pdf-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--mindalay--gray-color);
  z-index: 10000;
}
.pdf-container .pdf-container-item {
  width: 100%;
  height: 100%;
}
.pdf-container .pdf-container-item iframe {
  width: 100%;
  height: 100%;
}
.send-message-input-block input {
  padding-right: 55px !important;
}
.send-message-input-block input::placeholder {
  text-transform: capitalize;
}
.quiz-results-block {
  max-height: 500px;
  overflow-y: auto;
}
.file-name-block {
  width: calc(100% - 25px);
}
.file-name-block h3 {
  max-width: calc(100% - 100px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card-body-content {
  text-overflow: hidden;
  overflow: hidden;
}
.card-body-content img {
  max-width: 100px;
  max-height: 100px;
  height: auto !important;
  cursor: pointer;
  display: block;
  margin: 5px 0;
  border-radius: 3px;
  padding: 2px;
  border: 1px solid var(--mindalay--gray-color-lightened-70);
  box-sizing: content-box;
}
.card-body-content span {
  font-size: 16px;
  font-weight: 300 !important;
  opacity: 0.7;
}
.card-body-content h5 {
  font-size: 18px !important;
  padding: 0 !important;
}
.result-message {
  margin-top: 10px;
  text-align: center;
}
.webinar-video-block {
  display: flex;
  flex-flow: column;
}
.nav-item-help-organization a {
  color: var(--mindalay--brand-color) !important;
  transition: 0.2s;
}
.nav-item-help-organization a:hover {
  color: var(--mindalay--secondary-color) !important;
  transition: 0.2s;
}
.question-text-block-position-bottom {
  top: 10% !important;
  transform: translate(-100px, calc(0% + 20px)) !important;
  left: 50% !important;
}
.small-question-text-left {
  transform: translate(-121%, calc(-100% + 10px)) !important;
}
.custom-control {
  z-index: initial;
}
#document {
  width: 100%;
  overflow-x: auto;
}
#document .document-body {
  padding: 20px;
  max-width: 900px;
  min-width: 800px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
#document .document-body .mt-6 {
  margin-top: 60px;
}
#document .document-body .mt-7 {
  margin-top: 70px;
}
#document .document-body .mt-8 {
  margin-top: 80px;
}
#document .document-body .mt-9 {
  margin-top: 90px;
}
#document .document-body .custom-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #828282;
  font-size: 15px;
  outline: none;
  background: transparent;
  margin: 0 5px;
}
#document .document-body .template-title {
  text-align: center;
  font-size: 30px;
}
#document .document-body .template-sub-title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}
#document .document-body .template-input {
  width: 270px !important;
  min-width: 200px;
}
#document .document-body .date-input {
  width: 170px !important;
}
#document .document-body textarea {
  width: 100%;
  border-color: transparent;
  padding: 7px;
  font-size: 17px;
  overflow: hidden;
}
#document .document-body .max-assignment-width {
  max-width: 190px;
}
#document .document-body .document-select-option {
  width: 160px !important;
  font-size: 16px !important;
}
#document .document-body .table-block {
  max-width: 100%;
  overflow-y: auto;
}
#document .document-body .table-block .template-table-container .template-table {
  width: 1000px;
}
#document .document-body .table-block .template-table-container .template-table thead tr {
  background: #373d40;
  color: var(--mindalay--white-color);
}
#document .document-body .table-block .template-table-container .template-table thead tr td {
  overflow: hidden !important;
  overflow-wrap: break-word;
  padding: 5px;
}
#document .document-body .table-block .template-table-container .template-table tbody tr td {
  overflow: hidden !important;
  overflow-wrap: break-word;
}
#document .document-body .table-row-add {
  padding: 2px 10px;
  border: 1px solid #5d5d5d;
  background: #373d40;
  border-radius: 3px;
  color: white;
  margin: 5px;
}
#document .empty-row {
  width: 150px !important;
  border-bottom: 1px solid gray !important;
  display: inline-block;
}
.img-cursor-default img {
  cursor: default !important;
}
.question-dropdown-block,
.course-description-block,
.topic-description-block {
  color: var(--mindalay--gray-color);
}
.question-dropdown-block .topic-description-block-item,
.course-description-block .topic-description-block-item,
.topic-description-block .topic-description-block-item {
  color: var(--mindalay--white-color) !important;
}
.question-dropdown-block ol,
.course-description-block ol,
.topic-description-block ol {
  list-style-type: decimal;
}
.question-dropdown-block ol li,
.course-description-block ol li,
.topic-description-block ol li {
  list-style: decimal;
}
.question-dropdown-block ul,
.course-description-block ul,
.topic-description-block ul {
  padding-inline-start: 40px;
  list-style-type: disc;
}
.question-dropdown-block ul > li,
.course-description-block ul > li,
.topic-description-block ul > li {
  list-style: disc;
}
.question-dropdown-block img,
.course-description-block img,
.topic-description-block img {
  height: auto !important;
  cursor: pointer;
  display: block;
  margin: 5px 0;
  border-radius: 3px;
  padding: 2px;
  border: 1px solid var(--mindalay--gray-color-lightened-70);
  box-sizing: content-box;
}
.question-dropdown-block h1,
.course-description-block h1,
.topic-description-block h1,
.question-dropdown-block h2,
.course-description-block h2,
.topic-description-block h2,
.question-dropdown-block h3,
.course-description-block h3,
.topic-description-block h3,
.question-dropdown-block h4,
.course-description-block h4,
.topic-description-block h4,
.question-dropdown-block h5,
.course-description-block h5,
.topic-description-block h5,
.question-dropdown-block h6,
.course-description-block h6,
.topic-description-block h6,
.question-dropdown-block p,
.course-description-block p,
.topic-description-block p {
  margin-bottom: 0.25em;
  overflow-wrap: anywhere;
}
.question-dropdown-block h1,
.course-description-block h1,
.topic-description-block h1 {
  font-size: 2em;
}
.question-dropdown-block h2,
.course-description-block h2,
.topic-description-block h2 {
  font-size: 1.5em;
}
.question-dropdown-block h3,
.course-description-block h3,
.topic-description-block h3 {
  font-size: 1.17em;
}
.question-dropdown-block h4,
.course-description-block h4,
.topic-description-block h4 {
  font-size: 1em;
}
.question-dropdown-block h5,
.course-description-block h5,
.topic-description-block h5 {
  font-size: 0.83em;
}
.question-dropdown-block h6,
.course-description-block h6,
.topic-description-block h6 {
  font-size: 0.75;
}
.question-dropdown-block p,
.course-description-block p,
.topic-description-block p {
  font-size: 16px;
}
.course-pass-process-video {
  position: relative;
}
.course-pass-process-video img {
  max-width: 600px;
}
.full-screen-blick {
  position: absolute;
  right: 10px;
  top: 10px;
}
.full-screen-blick svg {
  width: 30px;
  cursor: pointer;
}
.full-screen-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1060;
}
.full-screen-container .zoom-image-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-screen-container .close-svg {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
}
.full-screen-container .close-svg svg {
  width: 30px;
  fill: var(--mindalay--white-color);
  transition: 0.2s;
}
.full-screen-container .close-svg svg:hover {
  transform: scale(1.07);
  transition: 0.2s;
}
.full-screen-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: -1;
}
.full-screen-container .actions-block-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--mindalay--white-color);
}
.full-screen-container .actions-block-item p {
  color: var(--mindalay--white-color);
  cursor: pointer;
}
.full-screen-container .actions-block-item p:hover {
  transform: scale(1.07);
  transition: 0.2s;
}
@media screen and (orientation: landscape) {
  .full-screen-container .bottom-block {
    bottom: -45px !important;
  }
}
.full-screen-container .top-title-block {
  margin-top: 60px;
}
.full-screen-container .top-title-block h4 {
  font-size: 25px;
  white-space: normal;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: center;
}
.full-screen-container .top-block {
  margin: 20px 0;
}
.full-screen-container .bottom-block {
  bottom: 10px;
  color: var(--mindalay--white-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  width: 100%;
  transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.3s;
}
.full-screen-container .full-screen-container-item {
  width: calc(100% - 30px) !important;
  margin: 0 auto;
}
.full-screen-container .full-screen-container-item .arrow-block {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.full-screen-container .full-screen-container-item .arrow-block svg * {
  fill: var(--mindalay--white-color) !important;
}
.full-screen-container .full-screen-container-item .arrow-block svg {
  width: 30px;
  height: 30px;
  fill: var(--mindalay--white-color);
  transition: 0.2s;
}
.full-screen-container .full-screen-container-item .arrow-block svg:hover {
  transform: scale(1.07);
  transition: 0.2s;
}
.full-screen-container .full-screen-container-item .left-arrow-block {
  left: 10px;
}
.full-screen-container .full-screen-container-item .right-arrow-block {
  right: 10px;
}
.full-screen-container .full-screen-container-item .image-or-video-block {
  max-width: 100%;
  max-height: 600px;
  margin: 0 auto;
  display: block;
  overflow-y: auto;
  max-height: scale(0vh) !important;
  max-height: calc(100vh - 100px);
  scrollbar-width: auto;
  scrollbar-color: #c1c1c1 yellow;
}
.full-screen-container .full-screen-container-item .image-or-video-block::-webkit-scrollbar {
  width: 8px;
}
.full-screen-container .full-screen-container-item .image-or-video-block::-webkit-scrollbar-track {
  background: var(--mindalay--light-gray);
  border-radius: 10px;
}
.full-screen-container .full-screen-container-item .image-or-video-block::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}
.full-screen-container .full-screen-container-item .image-or-video-block p {
  color: var(--mindalay--white-color);
}
.full-screen-container .full-screen-container-item .image-or-video-block video {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 100px);
}
.full-screen-container .full-screen-container-item .image-or-video-block .topic-image-full-screen {
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 800px;
  max-height: 500px;
}
.active-border {
  border: 2px solid var(--mindalay--brand-color);
}
.question-answer-dropdown {
  max-width: 370px;
  height: auto;
  max-height: 500px;
  overflow-y: auto;
  border: none;
  padding: 0;
}
.question-answer-dropdown .dropdown-item {
  padding: 10px 15px;
}
.question-answer-dropdown p {
  margin: 0;
}
.question-answer-dropdown .answer:last-child hr {
  display: none;
}
.question-answer-dropdown .answer hr {
  opacity: 0.1;
}
.question-answer-dropdown * :not(img) {
  min-width: 320px;
  margin: 0 auto;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}
.language-dropdown-light button {
  font-size: initial !important;
  padding: 0 20px !important;
}
.language-dropdown-light div {
  inset: 16px auto auto 0px !important;
}
.language-dropdown-dark button {
  background-color: transparent !important;
  color: var(--mindalay--brand-color) !important;
  border: none;
  font-size: initial !important;
  padding: 0 10px !important;
}
.language-dropdown-dark button:hover,
.language-dropdown-dark button:focus {
  background-color: transparent !important;
  color: var(--mindalay--brand-color) !important;
}
.topic-form-container {
  border: 1px solid;
  border-color: var(--mindalay--gray-color-lightened-80);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 40px;
}
.topic-form-container .close-block {
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
}
.topic-form-container .close-block svg {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}
.scale-up-hor-right {
  animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes scale-up-hor-right {
  0% {
    transform: scaleX(0.4);
    transform-origin: 100% 100%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
  }
}
.border-2-transparent {
  border: 2px solid transparent !important;
}
.error-bordered-2 {
  border: 2px solid var(--mindalay--red-color) !important;
}
.active-upload-button {
  border: 2px solid var(--mindalay--brand-color) !important;
}
.icon-input {
  position: relative;
}
.icon-input input {
  padding-left: 45px !important;
}
.icon-input span {
  position: absolute;
  bottom: 7px;
  left: 5px;
  padding: 5px 10px;
  background-color: var(--mindalay--brand-color);
  color: var(--mindalay--white-color);
  border-radius: 3px;
}
.category-logo {
  width: 25px;
}
.header-left-menu-item {
  transition: 0.3s;
}
.header-left-menu-item:hover {
  margin-left: 10px;
  color: var(--mindalay--secondary-color);
  transition: 0.3s;
}
.header-left-menu-item-accordion {
  border: none;
  background-color: var(--mindalay--white-color);
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  text-align: inherit;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  flex-flow: column;
}
.active-header-left-menu-item {
  margin-left: 10px;
  color: var(--mindalay--secondary-color);
}
.gamify-category-item {
  transition: 0.3s;
}
.gamify-category-item:hover {
  background-color: var(--mindalay--blue-color-lightened-45);
}
.underline {
  text-decoration: underline;
}
.Toastify__toast {
  word-break: break-word;
}
.Toastify__close-button {
  padding: 13px !important;
}
.mindalay--modal-window {
  position: relative;
}
.mindalay--modal-window .mindalay--modal-window-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1010;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  left: 0;
}
.mindalay--modal-window .mindalay--modal-window-wrapper .mindalay--modal {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--mindalay--white-color);
  border-radius: 5px;
  max-width: 600px;
  width: calc(100% - 30px);
  padding: 25px;
}
.mindalay--modal-window .mindalay--modal-window-wrapper .mindalay--modal img {
  width: 25px;
  margin-right: 15px;
  margin-top: 3px;
}
.mindalay--modal-window .mindalay--modal-window-wrapper .mindalay--modal strong {
  font-size: 22px;
}
.left-submenu-item {
  transition: 0.3s;
}
.left-submenu-item:hover {
  margin-left: 0.5rem;
}
.small-carousel .slider-control-bottomcenter {
  display: none;
}
.small-carousel .arrow {
  border: none;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.30196078);
  color: var(--mindalay--white-color);
  padding: 8px;
}
.small-carousel .arrow:hover {
  transform: scale(1.05);
}
.small-carousel .left-arrow {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.small-carousel .right-arrow {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.favorite-spinner div {
  display: flex;
}
.gamify-resource-count-success {
  border-color: var(--mindalay--green-color) !important;
}
.gamify-resource-count-success span {
  color: var(--mindalay--green-color);
}
.gamify-resource-count-danger {
  border-color: var(--mindalay--grey-color) !important;
}
.gamify-resource-count-danger span {
  color: var(--mindalay--grey-color);
}
.gamify-resource-count-block-sm {
  width: 40px;
  height: 40px;
}
.gamify-resource-count-block-sm .gamify-resource-count-sub-block {
  border-width: 2px !important;
}
.gamify-resource-count-block-sm span {
  font-size: 18px !important;
}
.gamify-resource-count-block-md {
  min-width: 40px;
  height: 40px;
}
.gamify-resource-count-block {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid;
  background-color: var(--mindalay--white-color);
}
.gamify-resource-count-block .gamify-resource-count-sub-block span {
  font-weight: bold;
}
.setting-tabs a {
  color: inherit;
}
.input-range {
  accent-color: var(--mindalay--brand-color);
}
.gamification-card-image {
  transition: transform 0.2s ease-in-out;
}
.gamification-card-text-block {
  padding: 15px 20px;
  position: absolute;
  height: 90%;
  width: 94%;
  transition: 0.3s;
  z-index: 1;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border-radius: 3px;
  height: auto;
}
.gamification-card-text-block p {
  transform: scale(0);
  transition: ease-in-out 0.3s;
  height: 0;
}
.gamification-card-text-block h3 {
  text-transform: uppercase !important;
  letter-spacing: 1.5px;
  transition: 0.3s;
}
.gamification-card-text-block::after {
  content: "";
  background-color: var(--mindalay--black-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.gamification-card {
  overflow: hidden;
  position: relative;
}
.gamification-card:hover .gamification-card-image {
  transform: scale(1.03);
}
.gamification-card:hover .gamification-card-text-block {
  height: 90%;
}
.gamification-card:hover h3 {
  margin-top: 1rem !important;
}
.gamification-card:hover p {
  transform: scale(1);
  height: auto;
  margin-top: 2rem;
}
.mindalay--color-picker {
  width: 100%;
}
.mindalay--color-picker .varied-colors {
  border: 1px solid var(--mindalay--black-color);
  border-radius: 10px;
  padding: 10px;
}
.mindalay--color-picker .base-colors,
.mindalay--color-picker .text-colors,
.mindalay--color-picker .varied-colors {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 10px 0;
}
.mindalay--color-picker .base-colors .color,
.mindalay--color-picker .text-colors .color,
.mindalay--color-picker .varied-colors .color,
.mindalay--color-picker .base-colors .color-var,
.mindalay--color-picker .text-colors .color-var,
.mindalay--color-picker .varied-colors .color-var {
  float: left;
  border-radius: 50%;
  cursor: pointer;
}
.mindalay--color-picker .base-colors .color,
.mindalay--color-picker .text-colors .color,
.mindalay--color-picker .varied-colors .color {
  transition: all 0.2s;
  width: 45px;
  height: 45px;
  margin: 10px;
  border: 0.5px solid var(--mindalay--grey-color);
}
.mindalay--color-picker .base-colors .color.active,
.mindalay--color-picker .text-colors .color.active,
.mindalay--color-picker .varied-colors .color.active {
  transform: scale(1.2, 1.2);
}
.mindalay--color-picker .base-colors .color-var,
.mindalay--color-picker .text-colors .color-var,
.mindalay--color-picker .varied-colors .color-var {
  transform: scale(0, 0);
  width: 25px;
  height: 25px;
  margin: 10px;
}
.mindalay--color-picker .base-colors .color-var.visible,
.mindalay--color-picker .text-colors .color-var.visible,
.mindalay--color-picker .varied-colors .color-var.visible {
  transform: scale(1, 1);
}
.mindalay--color-picker .base-colors .color-var.active,
.mindalay--color-picker .text-colors .color-var.active,
.mindalay--color-picker .varied-colors .color-var.active {
  transform: scale(1.2, 1.2);
}
.mindalay--color-picker .color-picker {
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  margin: 10px 15px;
  position: relative;
  border: 2px solid var(--mindalay--grey-color);
}
.mindalay--color-picker .color-picker::after {
  content: url(../assets/icons/color-picker.svg);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  padding: 7px;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.mindalay--color-picker .color-picker input {
  height: 40px;
  padding: 0;
  border: none;
  cursor: pointer;
  visibility: hidden;
}
.gamification-nodata {
  min-height: 200px;
  border: 2px dashed var(--mindalay--black-color-lightened-70);
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 20px;
}
.gamification-nodata p {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
}
.gamification-nodata span {
  color: var(--mindalay--black-color-lightened-70) !important;
  font-size: 40px;
  text-align: center;
  display: block;
}
@media (min-width: 768px) {
  .gamification-nodata:hover {
    transform: scale(1.02);
  }
}
.pass-course-gamify-button:hover svg {
  transform: scale(1.15);
  transition: 0.3s;
}
.pass-course-gamify-section-button svg * {
  fill: var(--mindalay--brand-color) !important;
}
.pass-course-tab a {
  width: fit-content;
}
.congratulations_modal .modal-content {
  min-height: 200px;
}
.congratulations_modal .modal-content .congratulations_modal_star {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  padding: 15px;
  top: -170px;
  right: 50%;
  background-color: var(--mindalay--white-color);
  transform: translate(50%, 50%);
}
.congratulations_modal .modal-content .congratulations_modal_star .main-image {
  width: 100px;
  height: 100px;
}
@media (max-width: 767.98px) {
  .congratulations_modal .modal-content .congratulations_modal_star {
    top: -150px;
  }
  .congratulations_modal .modal-content .congratulations_modal_star .main-image {
    width: 80px;
    height: 80px;
  }
}
.congratulations_modal .quest-image {
  width: 40px;
  height: 40px;
}
.congratulations_modal .item-image {
  width: 30px;
  height: 30px;
}
.congratulations_modal .quest-image,
.congratulations_modal .item-image {
  border-radius: 50%;
  border: 1px solid var(--mindalay--black-color-lightened-70);
}
.org-student-header-gamify-icon {
  width: 30px !important;
}
.org-student-header-gamify-icon* {
  fill: var(--mindalay--green-color) !important;
}
.student-badge {
  gap: 30px;
}
.student-badge .badge-container {
  gap: 30px;
  flex-direction: row-reverse;
}
.student-badge .badge-block {
  cursor: pointer;
  position: relative;
  width: 100px;
}
.student-badge .badge-block::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 30px;
  left: -30px;
  top: 50px;
  background-color: var(--mindalay--brand-color);
}
.student-badge .badge-block .badge-image-block {
  width: 100px;
  height: 100px;
  border: 1px solid gray;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.student-badge .badge-block.active {
  border: 2px solid var(--mindalay--green-color);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.student-badge .badge-block.active .badge-image-block {
  width: 80px;
  height: 80px;
}
.student-badge .badge-block-without-arrow::after {
  background-color: transparent !important;
}
.student-badge .quest-container {
  gap: 10px;
}
.student-badge .quest-block {
  cursor: pointer;
  width: 80px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}
.student-badge .quest-block::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 2px;
  background-color: var(--mindalay--brand-color);
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
}
.student-badge .quest-block .quest-image-block {
  width: 60px;
  height: 60px;
  border: 1px solid gray;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.student-badge .choose-badge-block {
  padding: 10px;
  border: 2px solid var(--mindalay--gray-color-lightened-50);
  border-radius: 5px;
}
.student-badge .choose-badge-block.active {
  border-color: var(--mindalay--green-color);
}
.student-badge .not-awarded {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.student-badge .not-awarded::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: var(--mindalay--black-color);
}
.student-badge .not-awarded i {
  font-size: 25px;
  position: relative;
  z-index: 1;
  color: var(--mindalay--white-color);
}
.webinar-notification-layer {
  position: fixed;
  background-color: var(--mindalay--black-color);
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.circle-impulse {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--mindalay--green-color);
  opacity: 0;
  animation: appearDisappear 1.5s ease infinite;
  margin-left: 10px;
}
@keyframes appearDisappear {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.cke_notifications_area {
  display: none !important;
}
.blur-box {
  background-color: rgba(0, 0, 0, 0.05) !important;
  backdrop-filter: blur(6px);
}
.marquee {
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  animation: marquee 30s linear infinite;
  color: var(--mindalay--brand-color);
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.custom-phone-number-input-block input {
  width: 100% !important;
  height: 46px !important;
  border-radius: 3px !important;
  border: 1.5px solid var(--mindalay--gray-color-lightened-75) !important;
  padding: 10px 10px 10px 50px !important;
}
/* Hover Effects on Card */
@media (min-width: 992px) {
  .pricing .card:hover .btn {
    opacity: 1;
  }
}
@media (max-width: 991.98px) {
  .data-table-extensions {
    position: initial;
    width: 100% !important;
  }
  .additional-top-padding {
    padding-top: 40px !important;
  }
  .show-search-input {
    display: block !important;
    top: 70px;
  }
  .search-wrapper {
    position: absolute !important;
    left: 15px;
    display: none;
    width: calc(100% - 30px) !important;
  }
  .search-wrapper .search-input {
    padding: 10px 40px !important;
    min-height: 45px;
  }
}
@media (max-width: 767.98px) {
  .partial-view-spinner-wrapper {
    width: 100%;
  }
  .custom-card-header .card-body-content {
    margin-left: 30px;
  }
  section.pricing .card-price {
    font-size: 1.5rem;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper {
    margin-top: 20px;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper .info-cover-title {
    font-size: 20px;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper .info-cover-subtitle {
    font-size: 16px;
  }
  .info-alert-container .info-alert-block {
    max-width: calc(100% - 30px);
  }
  .instructor-consultant-navbar .open-burger-menu {
    display: block;
  }
  .instructor-consultant-navbar .open-burger-menu span {
    background-color: var(--mindalay--black-color);
  }
  .instructor-consultant-navbar .open-burger-menu span::before,
  .instructor-consultant-navbar .open-burger-menu span::after {
    background-color: var(--mindalay--black-color);
  }
  .no-data-wrapper img {
    min-width: 300px;
  }
  .tabs-wrapper .nav-item {
    font-size: 20px;
  }
  .tab-content .nav {
    display: block;
  }
  .instructor-consultant-header-logo {
    display: block !important;
    position: absolute;
    left: 10px;
  }
  .admin-dashboard-body--large {
    margin-left: 0 !important;
  }
  .custom-ui {
    max-width: calc(100vw - 30px);
  }
}
@media (min-width: 992px) {
  .megasubmenu {
    left: 100%;
    top: -0.1% !important;
    min-height: 100%;
  }
  .additional-top-padding {
    padding-top: 60px !important;
  }
}
@media (max-width: 575.98px) {
  .mobile-offcanvas {
    width: calc(100% - 80px);
  }
  .webinar-notification {
    width: calc(100% - 20px);
  }
  .webinar-notification p {
    margin-bottom: 10px;
  }
  .mindalay--card-span-group span:first-child,
  .mindalay--card-span-group span:last-child {
    display: block;
  }
  h5 {
    font-size: 15px;
  }
  .notification-container {
    width: calc(100% - 30px);
  }
  .show-filter-popup-button {
    display: none;
  }
}
@media (min-width: 768px) {
  .admin-dashboard-header .alertBlockWithCloseButton {
    max-width: calc(100% - 260px);
    margin-left: auto;
  }
  section.pricing .card-price {
    font-size: 2rem;
  }
  .resource-modal .nav-tabs,
  .library-modal .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }
  .modal .card-body {
    max-height: 400px;
    overflow-y: auto;
  }
}
@media (min-width: 576px) {
  .webinar-notification {
    display: flex;
  }
  .container {
    max-width: 100%;
  }
  .mobile-offcanvas {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
  .autocomplete-block {
    min-width: 500px;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    max-width: 500px;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper .info-cover-title {
    font-size: 40px;
  }
  .info-cover-image-wrapper .info-cover-text-wrapper .info-cover-subtitle {
    font-size: 16px;
  }
  .consultation-chat-message {
    max-width: 80%;
  }
  .no-data-wrapper img {
    min-width: 300px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1330px;
  }
}
@media print {
  header,
  footer,
  aside,
  button,
  .content-title,
  .content-title-block,
  .scroll-top-block,
  .mindalay-alert-block,
  .print-button-block {
    display: none !important;
  }
  .top-section {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
  }
  .document-body {
    border: none !important;
  }
  .document-body table {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }
}
.custom-circle-picker span div span div {
  border: 1px solid var(--mindalay--black-color-lightened-70);
}
.pe-none {
  pointer-events: none !important;
  position: relative;
}
.th_icons svg {
  width: 20px;
  height: 20px;
}
.th_icons.blue svg {
  fill: var(--mindalay--blue-color);
}
.th_icons.green svg {
  fill: var(--mindalay--green-color);
}
.custom-button-conatiner {
  position: absolute;
  top: 10px;
  right: 10px;
}
.Toastify__close-button {
  width: initial !important;
}
