@import url('../fonts/Commissioner/fontCss.css');
:root {
  --mindalay--brand-color: #32064A;
  --mindalay--secondary-color: #E56B1F;
  --mindalay--gray-color: #0f1013;
  --mindalay--blue-color: #2980B9;
  --mindalay--white-color: #ffffff;
  --mindalay--header-color: #ffffff;
  --mindalay--body-color: #ffffff;
  --mindalay--trasnparent-white-color: rgba(255, 255, 255, 0.34);
  --mindalay--black-color: #000000;
  --mindalay--green-color: #28a745;
  --mindalay--orange-color: #FFA500;
  --mindalay--red-color: #DC143C;
  --mindalay--footer-color: #f9f9f9;
}
:root {
  --mindalay--brand-color-darken-35: #220432;
  --mindalay--gray-color-lightened-30: #525869;
  --mindalay--gray-color-lightened-35: #787a80;
  --mindalay--gray-color-lightened-40: #b4b5b9;
  --mindalay--gray-color-lightened-50: #a6a8ad;
  --mindalay--gray-color-lightened-60: #c9cad0;
  --mindalay--gray-color-lightened-75: #d9dae1;
  --mindalay--gray-color-lightened-70: #e0e1e3;
  --mindalay--gray-color-lightened-80: #e0e1e3;
  --mindalay--gray-color-lightened-85: #f0f1f4;
  --mindalay--gray-color-lightened-90: #f5f5f7;
  --mindalay--blue-color-lightened-30: #5baed9;
  --mindalay--blue-color-lightened-45: #D2E7F5;
  --mindalay--blue-color-lightened-50: #E7F2FA;
  --mindalay--blue-color-lightened-55: #F4F9FD;
  --mindalay--blue-color-lightened-minus-5: #2578a8;
  --mindalay--secondary-color-lightened-30: #d99058;
  --mindalay--secondary-color-lightened-35: #f09c58;
  --mindalay--secondary-color-lightened-40: #f9bb80;
  --mindalay--secondary-color-lightened-50: #ffbf9f;
  --mindalay--secondary-color-lightened-53: #ffbb80;
  --mindalay--secondary-color-lightened-minus-5: #e26418;
  --mindalay--red-color-lightened-30: #e9697e;
  --mindalay--red-color-lightened-50: #FFEBEF;
  --mindalay--red-color-lightened-minus-5: #d41234;
  --mindalay--green-color-lightened-55: #EDFBF0;
  --mindalay--green-color-lightened-30: #6bc98e;
  --mindalay--orange-color-lightened-30: #ffb07e;
  --mindalay--orange-color-lightened-45: #ffce8e;
  --mindalay--light-gray-lightened-3: #FBFBFB;
  --mindalay--light-gray-lightened-minus-10: #e6e6e6;
}
.font-extrabold {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.font-black {
  font-family: 'Commissioner', sans-serif;
  font-weight: 900;
  font-style: normal;
}
.font-bold {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.font-medium {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-default {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.font-light {
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.font-tini {
  font-family: 'Commissioner', sans-serif;
  font-weight: 100;
  font-style: normal;
}
body {
  font-family: 'Commissioner', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
title,
ul,
li,
small,
strong {
  margin: 0;
  padding: 0;
}
.text-initial {
  text-transform: initial !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
strong {
  word-wrap: break-word;
}
.form-check-label {
  word-break: break-word;
}
a:hover {
  text-decoration: none;
}
.pt-75 {
  padding-top: 75px !important;
}
p::first-letter,
select::first-letter,
legend::first-letter,
h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
h6::first-letter,
small::first-letter,
strong::first-letter,
button::first-letter,
td::first-letter,
th::first-letter,
a::first-letter,
label::first-letter,
li::first-letter,
b::first-letter {
  text-transform: capitalize;
}
li {
  list-style: none;
}
body {
  background-color: var(--mindalay--body-color);
}
fieldset {
  border: 1px solid var(--mindalay--gray-color-lightened-80) !important;
  padding: revert;
  margin: revert;
  min-width: revert;
  border-radius: 3px;
  margin-bottom: 10px;
}
fieldset legend {
  display: block;
  width: auto;
  padding: 0 10px;
  color: var(--mindalay--blue-color);
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 16px;
  line-height: inherit;
  white-space: normal;
}
p {
  opacity: 0.9;
  color: var(--mindalay--gray-color);
}
label {
  color: var(--mindalay--gray-color);
  margin-bottom: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--mindalay--brand-color) !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--mindalay--blue-color-lightened-55);
}
hr {
  background-color: var(--mindalay--gray-color);
  opacity: 0.3;
}
.brand-background {
  background-color: var(--mindalay--brand-color) !important;
}
button[type="button"]:focus,
button[type="submit"]:focus,
button[type="reset"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none;
}
button[type="button"],
button[type="submit"],
button[type="reset"],
input[type="submit"],
input[type="reset"],
.mindalay--btn-link {
  transition: 0.3s;
  cursor: pointer;
  padding: 10px 30px;
  font-size: 18px;
  text-align: center;
  border-radius: 3px;
}
button[type="button"]::first-letter,
button[type="submit"]::first-letter,
button[type="reset"]::first-letter,
input[type="submit"]::first-letter,
input[type="reset"]::first-letter,
.mindalay--btn-link::first-letter {
  text-transform: capitalize;
}
button[type="button"] i,
button[type="submit"] i,
button[type="reset"] i,
input[type="submit"] i,
input[type="reset"] i,
.mindalay--btn-link i {
  margin-right: 10px;
}
.svg-button {
  height: auto;
  cursor: pointer;
  transition: 0.3s;
  padding: 15px 20px;
}
.svg-button svg {
  width: 15px;
  height: 15px;
  line-height: 0;
  display: flex;
  align-items: center;
}
.svg-button svg * {
  fill: var(--mindalay--white-color);
}
.add-language-btn {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.fix-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.flex-1 {
  flex: 1;
}
.border-radius-0 {
  border-radius: 0 !important;
}
.heigth-fit-content {
  height: fit-content;
}
.width-fit-content {
  width: fit-content !important;
}
.cursor-pointer {
  cursor: pointer;
}
.transition {
  transition: 0.3s;
}
.active-text {
  color: var(--mindalay--brand-color) !important;
}
.progress {
  height: 0.5rem;
}
.progress .progress-bar {
  background-color: var(--mindalay--brand-color);
}
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.background-image-cover {
  background-size: cover;
}
.background-image-contain {
  background-size: contain;
}
.mindalay--information {
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
}
.mindalay--information svg {
  width: 22px;
  margin-right: 10px;
}
.mindalay--information svg * {
  fill: var(--mindalay--blue-color);
}
.dont-disturb-point {
  position: absolute;
  top: 50%;
}
.dont-disturb-point::after {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  left: 5px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.dont-disturb-point.is-active::after {
  background-color: var(--mindalay--green-color);
}
.dont-disturb-point.is-passive::after {
  background-color: var(--mindalay--red-color);
}
.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
}
.header .header-top-menu {
  position: relative;
}
.header .header-top-menu .navbar {
  min-height: auto;
  padding: 0px;
  background-color: var(--mindalay--header-color);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  opacity: 0.95;
}
.header .header-top-menu .navbar a {
  color: var(--mindalay--gray-color);
  font-size: 13px;
  transition: 0.3s;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding: 8px;
  border-bottom: 2px solid var(--mindalay--white-color);
  font-weight: 600;
}
.header .header-top-menu .navbar a:hover {
  color: var(--mindalay--secondary-color);
  border-bottom: 2px solid var(--mindalay--secondary-color);
}
.header .header-top-menu .active-menu {
  color: var(--mindalay--secondary-color) !important;
  border-bottom: 2px solid var(--mindalay--secondary-color) !important;
}
.site-navbar-heigth {
  min-height: 65px;
}
.has-number {
  position: relative;
}
.has-number::after {
  content: attr(rel);
  line-height: 0;
  position: absolute;
  border-radius: 30px;
  background-color: var(--mindalay--secondary-color);
  font-weight: bold;
  font-family: sans-serif;
  color: var(--mindalay--white-color);
  min-width: 23px;
  text-align: center;
  top: -5px;
  right: -2px;
  font-size: 12px;
  padding: 10px 5px;
}
.has-number-in-menu::after {
  top: initial;
}
.navbar {
  padding: 10px 20px;
  min-height: 60px;
}
.navbar .header-popup {
  top: 50px;
}
.navbar .navbar-brand {
  width: auto;
  padding: 0 !important;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--mindalay--white-color);
}
.navbar .navbar-brand h1 {
  font-size: 20px;
}
.navbar .navbar-brand img {
  margin-right: 10px;
  height: 30px;
}
.navbar .navbar-brand:hover {
  color: var(--mindalay--white-color);
}
.navbar .search-wrapper {
  position: relative;
  z-index: 2;
}
.navbar .search-wrapper > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--mindalay--brand-color);
  opacity: 0.7;
}
.navbar .search-wrapper > i:first-child {
  left: 15px;
}
.navbar .search-wrapper > i:last-child {
  right: 6px;
  padding: 8px 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: var(--mindalay--brand-color);
  cursor: pointer;
}
.navbar .search-wrapper .search-input {
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  padding: 10px 40px;
  color: var(--mindalay--brand-color);
  font-size: 14px;
  background-color: var(--mindalay--white-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  transition: 0.3s;
}
.navbar .search-wrapper .search-input::placeholder {
  color: var(--mindalay--brand-color);
  opacity: 0.7;
}
.navbar .search-wrapper .search-input:focus {
  opacity: 1;
}
.navbar .search-wrapper .autocomplete-block span {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.navbar .dropdown-menu {
  margin: 0;
}
.navbar .dropdown-menu .dropdown-toggle::after {
  font-size: 13px;
  transform: translateY(-50%) rotate(180deg);
  position: absolute;
  content: "❮";
  right: 10px;
  top: 50%;
  border: none;
}
.navbar .dropdown-menu a {
  padding: 10px 15px;
  color: var(--mindalay--gray-color);
  text-decoration: none;
  font-size: 16px;
}
.navbar hr {
  margin: 5px 0;
}
.navbar .navbar-nav {
  display: flex;
  align-items: center;
  flex-direction: inherit;
}
.navbar .nav-item .nav-link {
  color: var(--mindalay--white-color);
  padding: 0 10px;
}
.navbar .nav-item .nav-link svg {
  width: 23px;
  height: auto;
}
.navbar .nav-item .nav-link svg path {
  fill: var(--mindalay--white-color);
}
.navbar .nav-item .nav-link:hover {
  color: var(--mindalay--secondary-color);
}
.navbar .nav-item .nav-link:hover path,
.navbar .nav-item .nav-link:hover circle,
.navbar .nav-item .nav-link:hover line {
  fill: var(--mindalay--secondary-color) !important;
}
.navbar .nav-item .nav-link,
.navbar .nav-item .nav-link--course-navbar {
  transition: 0.3s;
  position: relative;
  margin: 0 0.15rem;
  width: fit-content;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.navbar .navbar-profile-image {
  cursor: pointer;
  background-color: var(--mindalay--secondary-color);
  border: 2px solid var(--mindalay--white-color);
  box-sizing: content-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  flex: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.navbar-profile-image {
  cursor: pointer;
  background-color: var(--mindalay--secondary-color);
  border: 2px solid var(--mindalay--white-color);
  box-sizing: content-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  flex: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.navbar-profile-container .navbar-profile-dropdown {
  position: relative;
  transition: 0.3s;
  border-radius: 3px;
  width: fit-content;
  margin: 0 0.5rem;
}
.navbar-profile-container .navbar-profile-dropdown .user-profile-dropdown-container {
  position: absolute;
  right: calc(50% - 15px);
  z-index: 10;
}
.navbar-profile-container .navbar-profile-dropdown .user-profile-dropdown-container .dropdown-menu {
  position: sticky;
}
.navbar-profile-container .navbar-profile-dropdown .user-profile-dropdown-container .user-profile-dropdown {
  padding: 0;
  min-width: 300px;
}
.navbar-profile-container .navbar-profile-dropdown .profile-nav-link .navbar-profile-image {
  width: 50px;
  height: 50px;
}
.navbar-profile-container .navbar-profile-dropdown .profile-nav-link small {
  font-size: 14px;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item {
  display: flex;
  align-items: center;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item .user-profil-info-wrapper small {
  font-size: 14px;
  color: var(--mindalay--gray-color);
}
.navbar-profile-container .navbar-profile-dropdown .nav--item .profile-nav-link {
  color: var(--mindalay--gray-color);
  padding: 10px;
  margin: 0 0.5rem !important;
  width: fit-content;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item h1 {
  font-size: 20px;
  color: var(--mindalay--black-color);
  opacity: 0.8;
  font-weight: 600;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item a {
  text-decoration: none;
  padding: 0;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item:first-child {
  padding: 15px;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item:first-child .navbar-profile-image {
  width: 60px;
  height: 60px;
}
.navbar-profile-container .navbar-profile-dropdown .nav--item:not(:first-child):hover {
  background-color: var(--mindalay--light-gray);
}
.navbar-profile-container .navbar-profile-dropdown .nav--item:not(:first-child):hover a {
  color: var(--mindalay--brand-color);
}
.navbar-profile-container .navbar-profile-dropdown .nav--item.hover--item:hover {
  background-color: var(--mindalay--light-gray);
}
.navbar-profile-container .navbar-profile-dropdown .nav--item.hover--item:hover a {
  color: var(--mindalay--brand-color);
}
.navbar-shadow {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  background-color: var(--mindalay--header-color);
}
.authorization-form {
  padding: 30px;
}
.authorization-form .authorization-form-title {
  font-size: 40px !important;
  color: var(--mindalay--brand-color);
}
.authorization-form .authorization-input {
  border: none;
  border-bottom: 1px solid var(--mindalay--gray-color);
  color: var(--mindalay--gray-color);
  padding: 5px 0;
  width: 100%;
  margin: 25px 0;
}
.authorization-form a:hover {
  color: var(--mindalay--secondary-color);
}
.authorization-form .social-icons-wrepper img {
  width: 30px;
  margin-right: 10px;
}
.cover-image {
  position: relative;
}
.cover-image .slider-control-centerright,
.cover-image .slider-control-centerleft {
  display: none;
}
.cover-image .slider-control-bottomcenter {
  bottom: auto !important;
}
.cover-image .slider-control-bottomcenter ul {
  bottom: -20px !important;
}
.cover-image .slider-control-bottomcenter ul .paging-item button {
  padding: 5px;
}
.cover-image .cover-text-wrapper {
  padding: 15px;
}
.cover-image .cover-text-wrapper .cover-title {
  font-weight: 600;
}
.cover-image .cover-text-wrapper .cover-title span {
  text-transform: uppercase;
}
.cover-image .cover-text-wrapper p {
  color: var(--mindalay--gray-color);
}
.cover-image img {
  max-width: 50%;
}
.burger-close-button-scala {
  transform: translateX(calc(-100% - 15px)) scale(1) !important;
}
.burger-menu-container {
  position: absolute;
}
.burger-menu-container .burger-menu-body {
  max-width: 400px;
  position: fixed;
  z-index: 100;
  background-color: var(--mindalay--white-color);
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(140%);
  transition: 0.3s;
}
.burger-menu-container .burger-menu-body ul {
  padding: 5px 0;
}
.burger-menu-container .burger-menu-body .profile-block .user-profil-info-wrapper .profile-user-fullname {
  font-size: 20px;
  color: var(--mindalay--gray-color);
}
.burger-menu-container .burger-menu-body .profile-block .user-profil-info-wrapper small {
  font-size: 14px;
  color: var(--mindalay--gray-color);
}
.burger-menu-container .burger-menu-body .burger-profile-container {
  position: relative;
}
.burger-menu-container .burger-menu-body .burger-profile-container .has-value::after {
  content: attr(rel);
  position: absolute;
  right: 15px;
  top: 50%;
  background-color: var(--mindalay--brand-color);
  transform: translateY(-50%);
  padding: 3px 7px;
  border-radius: 3px;
  text-align: center;
  color: var(--mindalay--white-color);
}
.burger-menu-container .burger-menu-body .burger-profile-container .important-action {
  background-color: var(--mindalay--brand-color);
  margin: 5px;
  transition: 0.3s;
}
.burger-menu-container .burger-menu-body .burger-profile-container .important-action .profile-nav-link {
  width: 100% !important;
  text-transform: uppercase;
  text-align: center;
  color: var(--mindalay--white-color) !important;
  font-size: 14px;
  letter-spacing: 2px;
}
.burger-menu-container .burger-menu-body .burger-profile-container .important-action:hover {
  background-color: var(--mindalay--brand-color-darken-35) !important;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item {
  display: flex;
  align-items: center;
  position: relative;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item .profile-nav-link {
  color: var(--mindalay--gray-color);
  transition: 0.3s;
  padding: 10px;
  margin: 0 0.5rem !important;
  width: 100%;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item h1 {
  font-size: 20px;
  color: var(--mindalay--black-color);
  opacity: 0.8;
  font-weight: 600;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item:first-child {
  padding: 15px;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item:first-child .navbar-profile-image {
  width: 60px;
  height: 60px;
  flex: none;
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item:not(:first-child):hover {
  background-color: var(--mindalay--light-gray);
}
.burger-menu-container .burger-menu-body .burger-profile-container .nav--item:not(:first-child):hover a {
  color: var(--mindalay--brand-color);
}
.burger-menu-container .burger-menu-body a {
  text-decoration: none;
}
.toggle-burger::before {
  content: '';
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: 0.8;
  position: fixed;
  background-color: var(--mindalay--black-color);
  left: 0;
  top: 0;
}
.toggle-burger-menu {
  display: block !important;
  transform: translateX(0) !important;
}
.burger-btn {
  padding: 12px 0;
}
.burger-btn span {
  position: relative;
  display: block;
  width: 30px;
  height: 2px;
  background: var(--mindalay--white-color);
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
}
.burger-btn span:before,
.burger-btn span:after {
  position: absolute;
  background: var(--mindalay--white-color);
  content: '';
  width: 30px;
  height: 2px;
  transition: all 0.2s ease-in-out;
}
.burger-btn span:before {
  top: -8px;
}
.burger-btn span:after {
  top: 8px;
}
.side-menu-close-btn {
  position: absolute;
  padding: 22px 13px;
  background-color: var(--mindalay--white-color);
  transition: 0.3s;
  cursor: pointer;
  border-radius: 50%;
}
.left-close-btn {
  top: 5px;
  right: 0%;
}
.left-close-btn span {
  background: transparent;
  width: 19px;
  position: relative;
  display: block;
}
.left-close-btn span:before,
.left-close-btn span:after {
  position: absolute;
  background: var(--mindalay--gray-color);
  content: '';
  width: 30px;
  height: 2px;
  transition: all 0.2s ease-in-out;
}
.left-close-btn span:before {
  transform: rotate(45deg) translate(5px, 6px);
  width: 20px;
  top: -8px;
}
.left-close-btn span:after {
  transform: rotate(-45deg) translate(5px, -6px);
  width: 20px;
  top: 8px;
}
.right-close-btn {
  top: 20px;
  left: 0%;
  transform: translateX(calc(-100% - 15px)) scale(0);
}
.right-close-btn span {
  background: transparent;
  width: 19px;
  position: relative;
  display: block;
}
.right-close-btn span:before,
.right-close-btn span:after {
  position: absolute;
  background: var(--mindalay--gray-color);
  content: '';
  width: 30px;
  height: 2px;
  transition: all 0.2s ease-in-out;
}
.right-close-btn span:before {
  transform: rotate(45deg) translate(5px, 6px);
  width: 20px;
  top: -8px;
}
.right-close-btn span:after {
  transform: rotate(-45deg) translate(5px, -6px);
  width: 20px;
  top: 8px;
}
.course-burger.course-burger span {
  background: var(--mindalay--white-color) !important;
}
.course-burger.course-burger span::after,
.course-burger.course-burger span::before {
  background: var(--mindalay--white-color) !important;
}
.top-section {
  padding-top: 60px !important;
}
.section-title {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
  color: var(--mindalay--black-color);
  margin: 0;
  padding: 10px 0;
  padding-left: 10px;
  position: relative;
}
.section-title::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 20px;
  opacity: 0.8;
  border-radius: 5px;
  background-color: var(--mindalay--brand-color);
  top: 50%;
  transform: translateY(-55%);
  left: 0;
}
.section-title span {
  color: var(--mindalay--secondary-color) !important;
  font-size: 18px !important;
}
.box-shadow-4 {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.box-shadow-33 {
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
}
.content-background {
  background-color: var(--mindalay--white-color);
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.form-group {
  position: relative;
}
.form-group .input-wrapper {
  position: relative;
}
.form-group .input-wrapper .input-lenght {
  position: absolute;
  border-radius: 3px;
  top: 6px;
  right: 6px;
  z-index: 1;
  font-size: 14px;
  color: var(--mindalay--white-color);
  background-color: var(--mindalay--brand-color);
}
.form-group .upload-btn {
  position: absolute;
  bottom: 50%;
  left: 6px;
  border: none;
  cursor: pointer;
  z-index: 1;
  transform: translate(0, 50%);
  padding: 8px 15px;
  background-color: var(--mindalay--secondary-color);
  color: var(--mindalay--white-color);
  border-radius: 3px;
  font-size: 14px;
}
.form-group .upload-file {
  position: relative;
}
.form-group .upload-file input {
  cursor: pointer;
}
.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="password"],
.form-group input[type="date"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group input[type="email"],
.form-group input[type="time"],
.form-group textarea {
  border: 1.5px solid var(--mindalay--gray-color-lightened-75);
  color: var(--mindalay--gray-color);
  border-radius: 3px;
  font-size: 16px;
  position: relative;
  width: 100%;
  padding: 10px 15px;
}
.form-group .form-control {
  color: var(--mindalay--gray-color);
}
.form-group .form-control:not([size]):not([multiple]) {
  height: auto !important;
}
.rating {
  width: 70px;
  height: 12px;
  margin-right: 3px;
  background: url(/assets/icons/star1.png) repeat-x;
}
.rating div {
  height: 12px;
  background: url(/assets/icons/star2.png) repeat-x;
  width: 85%;
}
.rating-big-stars {
  width: 135px;
  height: 25px;
  margin-right: 3px;
  background: url(/assets/icons/star-empty.png) repeat-x;
}
.rating-big-stars div {
  height: 25px;
  background: url(/assets/icons/star.png) repeat-x;
  width: 85%;
}
.rating-middle-size-stars {
  width: 85px;
  height: 25px;
  margin-right: 3px;
  background: url(/assets/icons/star-17x18-empty.png) repeat-x;
}
.rating-middle-size-stars div {
  height: 25px;
  background: url(/assets/icons/star-17x18.png) repeat-x;
  width: 85%;
}
.rotate-animation {
  animation: rotation 1s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.mindalay--order-list li {
  position: relative;
  padding-left: 20px;
}
.mindalay--order-list li::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--mindalay--brand-color);
}
.ul-wrapper a {
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.dropdown-sub-menu {
  background-color: var(--mindalay--blue-color-lightened-50);
}
.dropdown-sub-menu-item {
  background-color: var(--mindalay--blue-color-lightened-45);
  padding-left: 15px;
}
.dropdown .icon-right::after {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.login-btn {
  padding: 5px 15px;
  color: var(--mindalay--white-color);
  transition: 0.3s;
  border-radius: 3px;
}
.login-btn:hover {
  color: var(--mindalay--secondary-color);
}
.mindalay--btn {
  padding: 8px 30px;
  border-radius: 3px;
}
.mindalay--btn-small {
  font-size: 14px !important;
  padding: 5px 15px !important;
  display: inline-block;
}
.mindalay--btn-outline {
  color: var(--mindalay--white-color);
  border: 1px solid var(--mindalay--white-color);
  background-color: transparent;
}
.mindalay--btn-outline:hover {
  background-color: var(--mindalay--secondary-color);
  border-color: var(--mindalay--secondary-color);
  color: var(--mindalay--white-color);
}
.mindalay--btn-blue {
  color: var(--mindalay--blue-color);
  border: 1px solid var(--mindalay--blue-color);
  background-color: var(--mindalay--white-color);
}
.mindalay--btn-default {
  color: var(--mindalay--brand-color);
  border: 1px solid var(--mindalay--brand-color);
  background-color: var(--mindalay--white-color);
}
.mindalay--btn-secondary {
  color: var(--mindalay--white-color);
  border: 1px solid var(--mindalay--secondary-color);
  background-color: var(--mindalay--secondary-color);
}
.mindalay--btn-secondary:hover {
  background-color: var(--mindalay--secondary-color-lightened-minus-5);
  color: var(--mindalay--white-color);
}
.mindalay--btn-secondary-outline {
  color: var(--mindalay--secondary-color);
  border: 1px solid var(--mindalay--secondary-color);
  background-color: var(--mindalay--white-color);
}
.mindalay--btn-secondary-outline:hover {
  background-color: var(--mindalay--secondary-color);
  color: var(--mindalay--white-color);
}
.mindalay--btn-primary-outline {
  color: var(--mindalay--brand-color);
  border: 1px solid var(--mindalay--brand-color);
  background-color: var(--mindalay--white-color);
}
.mindalay--btn-primary-outline:hover {
  background-color: var(--mindalay--brand-color);
  color: var(--mindalay--white-color);
}
.mindalay--btn-dark {
  color: var(--mindalay--white-color);
  border: 1px solid var(--mindalay--brand-color);
  background-color: var(--mindalay--brand-color);
}
.mindalay--btn-dark:hover {
  background-color: var(--mindalay--brand-color-darken-35);
  border-color: var(--mindalay--brand-color-darken-35);
  color: var(--mindalay--white-color);
}
.mindalay--btn-dark * {
  fill: var(--mindalay--white-color);
}
.mindalay--btn-red {
  color: var(--mindalay--white-color);
  border: 1px solid var(--mindalay--red-color);
  background-color: var(--mindalay--red-color);
}
.mindalay--btn-red:hover {
  background-color: var(--mindalay--red-color-lightened-minus-5);
  border-color: var(--mindalay--red-color-lightened-minus-5);
  color: var(--mindalay--white-color);
}
.mindalay--btn-red * {
  fill: var(--mindalay--white-color);
}
.mindalay--btn-red-outline {
  color: var(--mindalay--red-color);
  border: 1px solid var(--mindalay--red-color);
  background-color: var(--mindalay--white-color);
}
.mindalay--btn-red-outline:hover {
  background-color: var(--mindalay--red-color);
  color: var(--mindalay--white-color);
}
.mindalay--btn-dark-blue {
  color: var(--mindalay--white-color);
  border: 1px solid var(--mindalay--blue-color);
  background-color: var(--mindalay--blue-color);
}
.mindalay--btn-dark-blue:hover {
  background-color: var(--mindalay--blue-color-lightened-minus-5);
  border-color: var(--mindalay--blue-color-lightened-minus-5);
  color: var(--mindalay--white-color);
}
.mindalay--btn-dark-blue * {
  fill: var(--mindalay--white-color);
}
.mindalay--btn-link {
  display: block;
  width: fit-content;
  white-space: nowrap;
}
.mindalay--btn-link:hover {
  text-decoration: none;
}
.mindalay--link {
  color: var(--mindalay--brand-color);
}
.slide-up {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}
.slide-up button {
  padding: 15px 20px !important;
}
.slide-up button svg {
  width: 20px;
}
.disable-user-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.blue-background {
  background-color: var(--mindalay--blue-color);
}
.white-background {
  background-color: var(--mindalay--white-color);
}
.purple-background {
  background-color: var(--mindalay--brand-color);
}
.blue-background-hover:hover {
  background-color: var(--mindalay--blue-color-lightened-minus-5);
}
.purple-background-hover:hover {
  background-color: var(--mindalay--brand-color-darken-35);
}
.brand-color {
  color: var(--mindalay--brand-color) !important;
}
.secondary-color {
  color: var(--mindalay--secondary-color) !important;
}
.blue-color {
  color: var(--mindalay--blue-color) !important;
}
.green-color {
  color: var(--mindalay--green-color) !important;
}
.red-color {
  color: var(--mindalay--red-color) !important;
}
.gray-color {
  color: var(--mindalay--gray-color) !important;
}
.orange-color {
  color: var(--mindalay--orange-color);
}
.breadcrumb {
  background-color: var(--mindalay--blue-color-lightened-55);
  margin-bottom: 0.5em;
}
.breadcrumb a {
  color: var(--mindalay--blue-color) !important;
}
.light-gray-background {
  background-color: var(--mindalay--gray-color-lightened-90);
}
.light-blue-background {
  background-color: var(--mindalay--blue-color-lightened-55);
}
.light-red-background {
  background-color: var(--mindalay--red-color-lightened-50);
}
.light-green-background {
  background-color: var(--mindalay--green-color-lightened-55);
}
.light-orange-background {
  background-color: var(--mindalay--orange-color-lightened-45);
}
.gray-border {
  border: 1px solid var(--mindalay--gray-color-lightened-30);
}
.blue-border {
  border: 1px solid var(--mindalay--blue-color-lightened-30);
}
.red-border {
  border: 1px solid var(--mindalay--red-color-lightened-30);
}
.green-border {
  border: 1px solid var(--mindalay--green-color-lightened-30);
}
.orange-border {
  border: 1px solid var(--mindalay--orange-color-lightened-30);
}
.text-specific-row-count {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.collapse-btn {
  color: var(--mindalay--brand-color);
  cursor: pointer;
}
.collapse-btn i {
  margin-left: 10px;
}
.text-collapse p {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}
.text-collapse p::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.74833683) 50%, rgba(255, 255, 255, 0.01164216) 100%);
  bottom: -30px;
  left: 0;
}
.text-collapse .full-height {
  max-height: 100%;
}
.text-collapse .full-height::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0;
  bottom: 0;
  left: 0;
}
.small-text {
  color: var(--mindalay--gray-color);
  font-size: 13px !important;
}
.title {
  font-size: 20px;
  color: var(--mindalay--black-color);
  margin: 0;
  padding: 10px 0;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.switcher .toggle {
  border: none;
}
.switcher .toggle .toggle-group .toggle-handle {
  border: none !important;
  width: 15%;
  background-color: var(--mindalay--brand-color) !important;
}
.switcher .toggle .toggle-group label {
  font-weight: 600;
}
.switcher .toggle .toggle-group .mindalay--btn-default {
  background-color: var(--mindalay--gray-color-lightened-30);
  color: var(--mindalay--gray-color);
  border: 1px solid var(--mindalay--gray-color-lightened-35);
}
.switcher .toggle .toggle-group .mindalay--btn-default:hover {
  background-color: var(--mindalay--gray-color-lightened-50);
}
.switcher .toggle .toggle-group .btn-primary {
  border: 1px solid var(--mindalay--secondary-color-lightened-35);
  background-color: var(--mindalay--secondary-color-lightened-53);
  color: var(--mindalay--secondary-color);
}
.switcher .toggle .toggle-group .btn-primary:hover {
  background-color: var(--mindalay--secondary-color-lightened-50);
}
.quiz-pagination-btn-group button:first-child svg {
  transform: rotate(180deg);
}
.quiz-pagination-btn-group button svg {
  width: 12px;
}
.quiz-pagination-btn-group button svg polygon {
  fill: var(--mindalay--brand-color);
}
.quiz-pagination-btn-group button:hover polygon {
  fill: var(--mindalay--white-color);
}
.question-wrapper {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--mindalay--gray-color-lightened-70);
}
.question-wrapper .question-name {
  opacity: 1;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: var(--mindalay--secondary-color);
}
.question-wrapper .question-options span {
  font-size: 14px;
}
.question-wrapper .question-options span:not(:first-child) {
  margin-left: 10px;
}
.question-form .question-body-container {
  margin-bottom: 10px;
}
.question-form .question-body-container .question-feedback-container {
  padding: 15px;
  margin-top: 15px;
  position: relative;
  border: 1px solid var(--mindalay--blue-color-lightened-30);
}
.question-form .question-body-container .question-feedback-container::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 20px;
  background-color: var(--mindalay--blue-color-lightened-55);
  border-top: 1px solid var(--mindalay--secondary-color-lightened-30);
  border-left: 1px solid var(--mindalay--secondary-color-lightened-30);
  transform: rotate(45deg) translateY(-80%);
}
.question-form .question-body-container .question-label-wrapper {
  display: flex;
  padding: 10px;
  border-radius: 3px;
}
.question-form .question-body-container .question-label-wrapper .question-body {
  margin-left: 25px;
}
.question-form .question-body-container .question-label-wrapper .question-body p {
  margin-left: 15px;
}
.question-form .question-body-container .question-label-wrapper .question-body .question-number {
  position: absolute;
  left: 10px;
  font-size: 16px;
  opacity: 1;
}
.question-form .question-body-container .question-label-wrapper::after,
.question-form .question-body-container .question-label-wrapper::before {
  left: 20px !important;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-dropdown {
  width: 70px;
}
.pagination-group {
  flex-wrap: wrap;
}
.pagination-group span {
  padding: 5px;
}
.timer-wrapper .timer {
  justify-content: center;
  position: relative;
}
.timer-wrapper .timer .timer-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-radius: 3px;
  background-color: var(--mindalay--white-color);
}
.timer-wrapper .timer .timer-item span {
  text-align: center;
  margin: 2px 0;
  display: block;
}
.timer-wrapper .timer .timer-item .timer-item-value {
  font-weight: 600;
  color: var(--mindalay--secondary-color);
}
.timer-wrapper .timer .timer-item .timer-item-name {
  color: var(--mindalay--brand-color);
}
.file-table img {
  max-height: 70px;
  width: auto;
}
.file-table tbody td:last-child {
  width: 1%;
}
.mindalay--data-table .has-icon svg {
  width: 25px;
}
.mindalay--data-table td a,
.mindalay--data-table th a {
  color: var(--mindalay--secondary-color);
}
.mindalay--data-table td path,
.mindalay--data-table th path {
  fill: var(--mindalay--gray-color);
}
.mindalay--data-table td circle,
.mindalay--data-table th circle {
  fill: var(--mindalay--gray-color);
}
.table-action-btn-group .view-btn {
  border: 1px solid var(--mindalay--secondary-color);
}
.table-action-btn-group .view-btn path {
  fill: var(--mindalay--secondary-color);
}
.table-action-btn-group .view-btn:hover {
  background-color: var(--mindalay--white-color);
}
.table-action-btn-group .edit-btn {
  border: 1px solid var(--mindalay--blue-color);
}
.table-action-btn-group .edit-btn path {
  fill: var(--mindalay--blue-color);
}
.table-action-btn-group .edit-btn:hover {
  background-color: var(--mindalay--blue-color);
}
.table-action-btn-group .delete-btn {
  border: 1px solid var(--mindalay--red-color);
}
.table-action-btn-group .delete-btn path {
  fill: var(--mindalay--red-color);
}
.table-action-btn-group .delete-btn:hover {
  background-color: var(--mindalay--red-color);
}
.table-action-btn-group .add-btn {
  border: 1px solid var(--mindalay--green-color);
}
.table-action-btn-group .add-btn i {
  color: var(--mindalay--green-color);
}
.table-action-btn-group .add-btn path {
  fill: var(--mindalay--green-color);
}
.table-action-btn-group .add-btn:hover {
  background-color: var(--mindalay--green-color);
}
.table-action-btn-group .edit-btn:hover i,
.table-action-btn-group .delete-btn:hover i,
.table-action-btn-group .add-btn:hover i {
  color: var(--mindalay--white-color);
}
.table-action-btn-group .edit-btn:hover path,
.table-action-btn-group .delete-btn:hover path,
.table-action-btn-group .add-btn:hover path {
  fill: var(--mindalay--white-color);
}
.table-action-btn-group .table-action-btn {
  line-height: 0;
  cursor: pointer;
  padding: 8px;
  margin: 0 5px;
  border-radius: 3px;
  transition: 0.3s;
}
.table-action-btn-group .table-action-btn svg {
  width: 20px;
  height: auto;
}
.table-action-btn-group .table-action-btn.sm {
  margin: 0 5px 0 0;
}
.table-action-btn-group .table-action-btn.sm svg {
  width: 12px;
  height: auto;
}
.navbar-popup-title {
  padding: 10px 20px;
  color: var(--mindalay--gray-color);
  font-weight: 700;
  display: block;
  color: var(--mindalay--secondary-color);
}
.navbar-popup-wrapper {
  width: 320px;
  padding: 0;
}
.navbar-popup-wrapper hr {
  margin: 0 !important;
}
.navbar-popup-wrapper .dropdown-item {
  padding: 5px 10px;
  white-space: inherit;
  border-bottom: 1px solid var(--mindalay--gray-color-lightened-50);
}
.navbar-popup-wrapper .dropdown-item span {
  font-size: 13px;
  color: var(--mindalay--brand-color);
  opacity: 0.8;
}
.navbar-popup-wrapper .not-subject {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;
}
.navbar-popup-wrapper p {
  white-space: pre-wrap;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}
.notification-title {
  line-height: 0;
  font-size: 15px;
  line-height: 1.3;
  display: block;
  margin-bottom: 5px;
}
.notification-title img {
  width: 25px;
}
.modal-window {
  position: relative;
  z-index: 30;
}
.modal-window .content-body {
  padding: 0 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: var(--mindalay--white-color);
  border-radius: 3px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 30;
}
.modal-window .content-body > i {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 25px !important;
  color: var(--mindalay--gray-color);
  padding: 10px;
  z-index: 1;
}
.modal-window .content-body form {
  padding: 20px;
}
.modal-window .content-body .content-title img {
  width: 100px;
  position: absolute;
  left: 50%;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  top: 0;
  padding: 30px;
  background-color: white;
  border-radius: 50% 50% 0 0;
}
.modal-window::after {
  content: '';
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--mindalay--black-color);
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 20;
}
.modal-window ul {
  border: none !important;
}
.resource-modal .modal-window-menu {
  flex: 50%;
}
.resource-modal .modal-window-menu li a {
  border: none !important;
  padding: 15px 25px;
  color: var(--mindalay--gray-color);
}
.resource-modal .modal-window-menu li a i {
  color: var(--mindalay--brand-color);
  font-size: 30px;
  margin-right: 15px;
  width: 30px;
  height: 30px;
}
.resource-modal .modal-window-menu li a svg {
  fill: var(--mindalay--brand-color);
  margin-right: 15px;
  width: 30px;
  height: 40px;
}
.resource-modal .modal-window-menu li:hover {
  background-color: var(--mindalay--light-gray);
}
.resource-modal .modal-window-menu li .active {
  background-color: var(--mindalay--white-color) !important;
}
.resource-modal .tab-content .card {
  border-radius: 0;
}
.resource-modal .tab-content .card .card-header {
  border: none !important;
  border-radius: 0 !important;
  padding: 0;
}
.resource-modal .tab-content .card .card-header a {
  color: var(--mindalay--gray-color);
  border: none !important;
  display: block;
  padding: 15px;
  width: 100%;
}
.resource-modal .tab-content .card .card-header a i {
  color: var(--mindalay--brand-color);
  font-size: 30px;
  margin-right: 15px;
}
.resource-modal .tab-content .card .card-header:hover {
  background-color: var(--mindalay--light-gray);
}
.resource-modal .tab-content .card .card-header .active {
  background-color: var(--mindalay--white-color) !important;
}
.library-modal .nav {
  flex: 30%;
  max-width: 250px;
}
.library-modal .nav li {
  padding: 5px 15px;
}
.library-modal .nav a {
  color: var(--mindalay--gray-color);
  border-radius: 0;
  padding: 0;
  border: none !important;
}
.library-modal .nav .active {
  background-color: var(--mindalay--light-gray) !important;
  color: var(--mindalay--brand-color) !important;
}
.library-modal .nav .active::before {
  content: '\203A';
  margin-right: 5px;
  color: var(--mindalay--brand-color);
  font-weight: 800;
}
.library-modal .tab-content {
  flex: 70%;
}
.uploaded-file--file-library-image {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  min-width: 100%;
  min-height: 200px;
}
.accordion-wrapper .mindalay-card {
  border: none;
  margin-top: 3px;
}
.accordion-wrapper .mindalay-card .card-course-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-wrapper .mindalay-card .card-course-name svg {
  width: 20px;
  height: 20px;
  position: absolute;
  padding: 5px 10px;
  background-color: var(--mindalay--white-color);
  box-sizing: content-box;
  right: 10px;
}
.accordion-wrapper .mindalay-card .card-header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--mindalay--light-gray-lightened-3) !important;
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.accordion-wrapper .mindalay-card .card-header h5 {
  width: calc(100% - 30px);
  color: var(--mindalay--brand-color);
  padding: 5px 0;
  font-size: 16px;
}
.accordion-wrapper .mindalay-card .card-header h5 span {
  margin-right: 5px;
}
.accordion-wrapper .mindalay-card .card-header input[type="checkbox"] {
  margin-right: 30px;
}
.accordion-wrapper .mindalay-card .card-header .question-type-icon {
  position: relative;
  margin-right: 15px;
}
.accordion-wrapper .mindalay-card .collapse-body {
  margin: 0 0.75rem;
  padding: 0.5rem 0;
  border: 1px solid var(--mindalay--secondary-color-lightened-40);
}
.accordion-wrapper .mindalay-card .collapse-body .card-body {
  max-height: 100%;
  padding: 0.5rem 1rem;
}
.accordion-wrapper .mindalay-card .collapse-body .card-body > button {
  margin: 0.75rem 0;
}
.accordion-wrapper .mindalay-card .collapse-body .card-body .section-media-wrapper img {
  max-height: 100px;
  margin-right: 10px;
  border-radius: 3px;
}
.accordion-wrapper .mindalay-card .collapse-body .card-body .section-content-wrapper .section-content {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: var(--mindalay--white-color) !important;
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
}
.accordion-wrapper .mindalay-card .collapse-body .card-body .section-content-wrapper .section-content p {
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 70px);
}
.accordion-wrapper .mindalay-card .collapse-body .card-body .section-content-wrapper .section-content svg {
  width: 30px;
  margin-right: 10px;
}
.accordion-wrapper .mindalay-card .collapse-body .card-body .section-content-wrapper .section-content svg path {
  fill: var(--mindalay--gray-color);
}
.course-flexable {
  display: flex;
  flex-wrap: wrap;
}
.course-flexable .course-wrapper {
  margin: 5px;
}
.course-header ul li {
  color: var(--mindalay--white-color);
  margin: 0 5px;
}
.course-header ul li .course-ducarion {
  margin: 0 5px;
}
.course-header ul li .nav-mindalay--btn-link--course-navbar {
  background-color: var(--mindalay--white-color);
  color: var(--mindalay--brand-color);
  padding: 15px;
  border-radius: 3px;
  font-size: 15px;
  text-decoration: none;
}
.course-header ul li .nav-mindalay--btn-link--course-navbar:hover svg {
  transform: translateX(-5px);
}
.course-header ul li .nav-mindalay--btn-link--course-navbar svg {
  transition: 0.3s;
  height: 15px;
  margin-right: 5px;
}
.course-header ul li .nav-mindalay--btn-link--course-navbar svg path {
  fill: var(--mindalay--brand-color);
}
.course-header ul .course-status--course-header {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.course-pass-container .mindalay-card {
  margin: 0;
}
.course-pass-container .mindalay-card .card-header {
  background-color: var(--mindalay--white-color) !important;
}
.course-pass-container aside.coures-pass-left-content {
  background-color: var(--mindalay--white-color);
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid var(--mindalay--gray-color-lightened-40);
}
.course-pass-container aside.coures-pass-left-content .collapse-body {
  margin: 0 !important;
  padding: 0;
}
.course-pass-container aside.coures-pass-left-content .course-pass-process-left-header {
  padding: 10px 20px;
}
.course-pass-container aside.coures-pass-left-content .course-pass-process-left-header a {
  transition: 0.3s;
  margin: 0 0.5rem;
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  color: var(--mindalay--gray-color);
}
.course-pass-container aside.coures-pass-left-content .course-pass-process-left-header a:hover {
  color: var(--mindalay--secondary-color);
  text-decoration: none;
}
.course-pass-container aside.coures-pass-left-content .course-media-wrapper .course-video-wrapper {
  margin: 0 5px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.course-pass-container aside.coures-pass-left-content .course-media-wrapper .course-video-wrapper video {
  max-height: 160px;
  width: 100%;
  border: 0.5px solid var(--mindalay--gray-color-lightened-80);
  border-radius: 3px;
}
.course-pass-container .coures-pass-right-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.course-pass-container .coures-pass-right-content .course-pass-page-navidation-btn li {
  padding: 10px 20px;
  border: 1px solid var(--mindalay--light-gray-lightened-minus-10);
  cursor: pointer;
  transition: 0.3s;
}
.course-pass-container .coures-pass-right-content .course-pass-page-navidation-btn li a {
  color: var(--mindalay--gray-color);
  font-size: 16px;
}
.course-pass-container .coures-pass-right-content .course-pass-page-navidation-btn li:hover {
  border-color: var(--mindalay--secondary-color-lightened-40);
}
.course-pass-container .coures-pass-right-content .course-pass-page-navidation-btn li:hover a {
  text-decoration: none;
  color: var(--mindalay--secondary-color);
}
.course-pass-container .coures-pass-right-content .course-pass-process-video {
  padding: 20px;
  text-align: center;
}
.course-pass-container .coures-pass-right-content .course-pass-process-video video,
.course-pass-container .coures-pass-right-content .course-pass-process-video img {
  border-radius: 5px;
}
.course-pass-container .coures-pass-right-content .course-name {
  background-color: var(--mindalay--blue-color-lightened-55);
  padding: 5px 25px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.course-pass-container .coures-pass-right-content .course-pass-process-files {
  padding: 20px;
  padding-top: 0;
  width: 100%;
}
.course-pass-container .course-complited-status {
  padding: 0 20px 0 20px;
}
.course-pass-container .course-complited-status h1 {
  font-size: 23px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--mindalay--gray-color);
}
.course-pass-container .course-complited-status .course-pass-percent p {
  color: var(--mindalay--brand-color);
  font-weight: 600;
  font-size: 16px;
  opacity: 1;
  text-transform: uppercase;
}
.course-pass-container .course-complited-status .course-pass-percent span:last-child {
  margin-right: 10px;
}
.course-pass-container .course-pass-section .card-header-background-color {
  border-radius: 0;
  background-color: var(--mindalay--light-gray-lightened-3) !important;
}
.course-pass-container .course-pass-section .card-header-background-color h5 {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--mindalay--brand-color);
}
.course-pass-container .course-pass-section .card-header-background-color i {
  color: var(--mindalay--brand-color);
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-body {
  padding: 1px 0;
  border-radius: 0;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header {
  transition: 0.3s;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header h5 {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-left: 55px;
  color: var(--mindalay--gray-color) !important;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .card-header span {
  position: absolute;
  left: 0;
  padding: 14px 20px;
}
.course-pass-container .course-pass-section .mindalay--collapse-body .active-pass-course {
  border-left: 12px solid var(--mindalay--blue-color);
}
.course-pass-container .course-pass-section .mindalay--collapse-body .active-pass-course h5 {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.course-pass-container .content-title span {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.course-pass-container .content-title svg {
  padding: 8px;
}
.course-pass-container .burger-btn span,
.course-pass-container .burger-btn ::before,
.course-pass-container .burger-btn ::after {
  background-color: var(--mindalay--brand-color);
}
.course-pass-media-file-container .course-pass-media-file-wrapper {
  cursor: pointer;
  padding: 10px;
}
.course-pass-media-file-container .course-pass-media-file-wrapper p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  max-width: 180px;
}
.course-pass-media-file-container .course-pass-media-file-wrapper h4 {
  font-size: 18px;
}
.course-pass-media-file-container .course-pass-media-file-wrapper .quiz-information {
  padding: 0;
}
.course-pass-media-file-container .course-pass-media-file-wrapper .course-grade {
  width: 100%;
}
.course-pass-media-file-container .course-pass-media-file-wrapper .course-grade .value {
  display: block;
  font-size: 23px;
  color: var(--mindalay--brand-color);
  font-weight: 600;
}
.course-pass-media-file-container .course-pass-media-file-wrapper .course-grade .name {
  font-size: 16px;
}
.course-pass-media-file-container .course-pass-media-file-wrapper img {
  max-height: 50px;
}
.modal-position {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
}
.cross {
  cursor: pointer;
  padding: 10px;
}
.cross i {
  font-size: 25px;
  color: var(--mindalay--brand-color);
}
.course-status {
  color: var(--mindalay--white-color) !important;
  font-size: 13px !important;
  padding: 3px 15px;
}
.course-new {
  background-color: var(--mindalay--green-color);
}
.course-bestseller {
  background-color: var(--mindalay--blue-color);
}
.course-wrapper {
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  height: calc(100% - 2px);
}
.course-wrapper .course-image-wrapper {
  position: relative;
  overflow: hidden;
}
.course-wrapper .course-image-wrapper:hover .wish-list-heart {
  transform: translate(0, 0);
}
.course-wrapper .course-image-wrapper img {
  transition: 0.3s;
  width: 100%;
  margin: auto;
  display: block;
  z-index: -1;
  position: relative;
}
.course-wrapper .course-body-wrapper {
  padding: 10px;
  position: relative;
}
.course-wrapper .course-body-wrapper .wish-list-heart {
  position: absolute;
  z-index: 1;
  padding: 10px;
  top: 0;
  right: 0;
  background-color: var(--mindalay--white-color);
  color: var(--mindalay--light-gray);
  font-size: 17px;
  line-height: 0;
  transition: 0.3s;
}
.course-wrapper .course-body-wrapper .wish-list-heart svg {
  width: 25px;
}
.course-wrapper .course-body-wrapper .wish-list-heart svg path {
  fill: var(--mindalay--brand-color);
}
.course-wrapper .course-body-wrapper .course-description {
  margin: 3px 0;
  color: var(--mindalay--black-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.course-wrapper .course-body-wrapper .course-price-wrapper {
  align-items: center;
  margin-top: 5px;
}
.course-wrapper .course-body-wrapper .course-price-wrapper .course-new-price {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px !important;
}
.course-wrapper .course-body-wrapper .course-price-wrapper .course-old-price {
  opacity: 0.8;
  font-size: 14px;
  color: var(--mindalay--blue-color);
  text-decoration: line-through;
}
.course-wrapper .course-body-wrapper .course-review-number {
  font-weight: 700;
}
.course-wrapper .course-body-wrapper .course-review-count {
  color: var(--mindalay--gray-color);
  font-size: 14px;
}
.course-wrapper:hover .course-image-wrapper .course-image {
  transform: scale(1.1);
}
.course-wrapper:hover .course-image-wrapper img {
  transform: scale(1.1);
}
.course-slider-wrapper {
  position: relative;
}
.course-slider-wrapper .course-slider-wing {
  position: absolute;
  width: 30px;
  height: 50px;
  top: 30%;
  transform: translateY(-25px);
  background-color: var(--mindalay--black-color);
  opacity: 0.7;
  z-index: 2;
  cursor: pointer;
}
.course-slider-wrapper .course-slider-wing i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--mindalay--white-color);
  transform: translate(-50%, -50%);
}
.course-slider-wrapper .course-slider-left-wing {
  left: 1px;
}
.course-slider-wrapper .course-slider-right-wing {
  right: 1px;
}
.course-slider-wrapper .course-slider {
  overflow-x: hidden;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.filter-wrapper .filter-toggle {
  height: 0;
  overflow: hidden;
  padding: 0 !important;
}
.filter-wrapper .filter-toggle-icon {
  transform: rotate(180deg);
}
.filter-wrapper .filter-name {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 10px;
  color: var(--mindalay--gray-color-darkened-30);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.filter-wrapper .filter {
  padding: 10px;
}
.filter-wrapper .filter input[type="radio"] {
  margin-top: 0.5rem;
}
.filter-wrapper .filter .filter-item {
  padding-top: 10px;
}
.filter-wrapper .filter .filter-item label {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.burger-filter-container .burger-filter-header {
  background-color: var(--mindalay--secondary-color);
  padding: 15px;
}
.burger-filter-container .burger-filter-header p {
  color: var(--mindalay--white-color);
}
.burger-filter-container .burger-filter-body {
  padding: 10px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}
.course-wrapper-horizontal hr {
  margin: 0.5rem 0;
}
.course-wrapper-horizontal .course-wrapper {
  display: flex;
  box-sizing: content-box;
}
.course-wrapper-horizontal .course-wrapper .course-body-wrapper {
  flex: 1;
  padding: 0 10px;
  align-items: flex-start;
}
.course-wrapper-horizontal .course-wrapper .course-body-wrapper .course-name {
  max-width: calc(100% - 120px);
  color: var(--mindalay--gray-color);
}
.course-wrapper-horizontal .course-wrapper .course-image-wrapper {
  background-color: var(--mindalay--light-gray);
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--mindalay--gray-color-lightened-60);
}
.course-wrapper-horizontal .course-wrapper .course-image-wrapper .course-image {
  transition: 0.3s;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
.course-wrapper-horizontal .course-wrapper .course-status {
  margin: 5px 0;
  display: block;
  width: fit-content;
}
.course-wrapper-horizontal .course-wrapper .course-price-wrapper {
  text-align: right;
}
.course-wrapper-horizontal .course-wrapper .course-price-wrapper .course-new-price {
  font-size: 20px !important;
}
.course-wrapper-horizontal .course-wrapper .course-price-wrapper .course-old-price {
  font-size: 18px !important;
}
.course-wrapper-vertical.course-wrapper {
  border-radius: 3px;
}
.course-wrapper-vertical.course-wrapper .course-image-wrapper {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid var(--mindalay--gray-color-lightened-80);
}
.course-wrapper-vertical.course-wrapper .course-image-wrapper:hover .wish-list-heart {
  transform: translate(0, 0);
}
.course-wrapper-vertical.course-wrapper .course-image-wrapper .course-status {
  padding: 2px 10px;
  margin: 0 5px;
}
.course-left-menu-wrapper ul {
  margin: 10px 0;
}
.course-left-menu-wrapper ul li {
  display: block;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}
.course-left-menu-wrapper ul li:first-child {
  color: var(--mindalay--brand-color);
  font-weight: 600;
}
.course-left-menu-wrapper ul li a {
  padding: 5px 0;
  display: inline-block;
  color: var(--mindalay--gray-color);
  text-decoration: none;
}
.course-left-menu-wrapper ul li a i {
  margin-right: 5px;
  font-weight: 100;
  font-size: 18px;
}
.course-left-menu-wrapper ul li:hover a {
  color: var(--mindalay--secondary-color);
}
.course-left-menu-wrapper .left-list-information-list .passed-menu-item {
  width: fit-content;
}
.course-left-menu-wrapper .left-list-information-list .passed-menu-item::before {
  content: '';
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-55%);
  border-radius: 50%;
  position: absolute;
  background-color: var(--mindalay--secondary-color);
}
.course-left-menu-wrapper .left-list-information-list .default-menu-item {
  width: fit-content;
}
.course-left-menu-wrapper .left-list-information-list .default-menu-item::before {
  content: '';
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-55%);
  border-radius: 50%;
  position: absolute;
  border: 1px solid var(--mindalay--secondary-color);
  background-color: var(--mindalay--white-color);
}
.course-left-menu-wrapper .left-list-information-list li {
  position: relative;
}
.course-left-menu-wrapper .left-list-information-list li a {
  margin-left: 25px;
}
.course-left-menu-wrapper .left-list-action-list li {
  width: fit-content;
}
.course-left-menu-wrapper .left-list-action-list li:hover:not(:first-child) {
  transform: translateX(5px);
}
.course-left-menu-wrapper .left-list-action-list li:hover:not(:first-child) a {
  color: var(--mindalay--secondary-color);
}
.course-left-menu-wrapper .left-list-action-list li:hover:not(:first-child)::before {
  content: '\203A';
  margin-right: 5px;
  color: var(--mindalay--secondary-color);
  font-size: 20px;
  line-height: 0;
  font-weight: 800;
}
.course-left-menu-wrapper .left-list-action-list li a {
  font-size: 16px;
}
.course-left-menu-wrapper .left-list-action-list .active-menu-item {
  transform: translateX(5px);
}
.course-left-menu-wrapper .left-list-action-list .active-menu-item a {
  color: var(--mindalay--secondary-color);
}
.course-left-menu-wrapper .left-list-action-list .active-menu-item::before {
  content: '\203A';
  margin-right: 5px;
  color: var(--mindalay--secondary-color);
  font-size: 20px;
  line-height: 0;
  font-weight: 800;
}
.content-sub-title {
  padding: 10px 0;
}
.content-sub-title h3 {
  font-size: 20px;
  color: var(--mindalay--gray-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.course-media-wrapper img {
  width: 100%;
  margin: 10px 0;
  border-radius: 3px;
}
.course-media-wrapper a {
  color: var(--mindalay--brand-color);
}
.course-media-wrapper a:hover {
  color: var(--mindalay--brand-color);
  text-decoration: underline;
}
.course-info-container .course-card-body .course-price-wrapper {
  position: relative;
}
.course-info-container .course-card-body .course-price-wrapper .course-new-price {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: var(--mindalay--gray-color) !important;
}
.course-info-container .course-card-body .course-price-wrapper .course-old-price {
  margin-left: 5px;
  text-decoration: line-through;
  color: var(--mindalay--blue-color) !important;
  opacity: 0.8;
}
.course-info-container .course-card-body .course-price-wrapper .discount {
  padding: 3px 15px;
  background-color: var(--mindalay--secondary-color);
  font-size: 14px !important;
  margin-left: 10px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: var(--mindalay--white-color);
}
.course-info-container .course-card-body .course-property li {
  font-size: 14px;
  color: var(--mindalay--gray-color);
}
.course-info-container .course-card-body .course-property li i {
  color: var(--mindalay--secondary-color);
  margin-right: 5px;
}
.course-info-container .course-general-info h1 {
  color: var(--mindalay--brand-color);
  font-weight: 600;
}
.course-info-container .course-general-info h2 {
  color: var(--mindalay--gray-color);
}
.course-info-container .course-general-info .course-info-review p {
  margin-right: 10px;
}
.course-info-container .course-general-info .course-info-review p:nth-child(2) {
  margin-right: 5px;
}
.course-info-container .course-general-info .icon-wrapper p {
  font-size: 13px !important;
  margin-right: 10px;
}
.course-info-container .course-instructor .instructor-profile .instructor-profile-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
  width: 100%;
  max-width: 120px;
}
.course-info-container .course-instructor .instructor-profile .instructor-profile-info {
  padding: 5px 0;
}
.course-info-container .course-instructor .instructor-profile .instructor-profile-info p {
  font-size: 14px;
  padding: 2px 0;
}
.course-info-container .course-instructor .instructor-profile .instructor-profile-info p i {
  color: var(--mindalay--brand-color);
  min-width: 40px;
  text-align: center;
}
.course-info-container .course-rate .progress-wrapper .progress {
  margin: 5px 0;
  border-radius: 3px;
  width: calc(100% - 90px);
  height: 1rem;
}
.course-info-container .course-rate .progress-wrapper .progress .progress-bar {
  background-color: var(--mindalay--orange-color);
}
.course-info-container .course-rate .progress-wrapper .rating-wrapper {
  margin-left: 5px;
  min-width: 110px;
}
.course-info-container .course-rate .progress-wrapper .rating-wrapper span {
  color: var(--mindalay--secondary-color);
  font-size: 14px;
}
.course-info-container .course-rate .course-rating {
  padding: 10px 15px;
  text-align: center;
}
.course-info-container .course-rate .course-rating strong {
  color: var(--mindalay--orange-color);
  text-align: center;
  font-size: calc(2vw + 50px);
  line-height: 1;
  font-weight: 600;
}
.course-info-container .course-rate .course-rating p {
  color: var(--mindalay--orange-color);
  font-size: 14px;
}
.course-info-container ul li {
  margin-bottom: 10px;
}
.chech-icon-list i {
  color: var(--mindalay--brand-color);
  padding: 5px;
  margin-right: 5px;
  font-size: 14px;
}
.total-balance {
  background-color: var(--mindalay--brand-color);
  padding: 10px 15px;
  color: var(--mindalay--white-color);
  border-radius: 3px;
  justify-content: flex-end;
}
.course-feedback .feedback-wrapper .image-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 50px;
  max-width: 50px;
  border-radius: 3px;
}
.course-feedback .feedback-wrapper .feedback-body {
  padding: 0 10px;
}
.course-feedback .feedback-wrapper .feedback-body .feedback-usernam {
  opacity: 0.8;
}
.course-feedback .feedback-wrapper .feedback-body .feedback-date-time {
  font-size: 13px !important;
  margin-left: 15px;
}
.course-feedback .feedback-wrapper .feedback-reply-body {
  margin-top: 10px;
  margin-left: 60px;
  padding: 0 10px;
  border-left: 5px solid var(--mindalay--brand-color);
}
.profile-wrapper .profile-picture-wrapper {
  max-width: 220px;
  margin: 0 auto;
}
.profile-wrapper .profile-picture-wrapper .profile-picture {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  background-color: var(--mindalay--light-gray);
  min-height: 200px;
  width: 100%;
}
.profile-wrapper .profile-picture-wrapper .user-type {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 15px;
  font-size: 16px;
  color: var(--mindalay--gray-color);
}
.profile-wrapper .profile-info-wrapper h2 {
  color: var(--mindalay--brand-color);
  position: relative;
  font-size: 30px;
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
}
.profile-wrapper .profile-info-wrapper h2::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 8px;
  background-color: var(--mindalay--secondary-color);
  bottom: -7px;
}
.profile-wrapper .profile-info-wrapper span {
  color: var(--mindalay--gray-color);
  font-size: 16px;
}
.profile-wrapper .profile-info-wrapper .big-number {
  color: var(--mindalay--gray-color);
}
.profile-wrapper .profile-info-wrapper .big-number p {
  font-size: 14px;
}
.user-profile-image-wrapper p {
  margin-bottom: 10px;
}
.user-profile-image-wrapper .user-profile-image-container {
  max-height: 250px;
  background-color: var(--mindalay--light-gray);
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--mindalay--gray-color-lightened-30);
  border-radius: 3px;
}
.user-profile-image-wrapper .user-profile-image-container .user-profile-image {
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
}
.admin-header {
  background-color: var(--mindalay--brand-color);
}
.admin-header nav {
  padding: 5px 15px;
}
.admin-header nav a {
  color: var(--mindalay--white-color);
  font-size: 16px;
  padding: 0 5px;
}
.admin-header nav a:hover {
  color: var(--mindalay--white-color);
}
.admin-header nav .action-btn {
  background-color: var(--mindalay--white-color);
  border: none;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 3px;
  color: var(--mindalay--brand-color);
}
.admin-header nav .action-btn i {
  font-size: 14px;
  margin: 0 3px;
}
.admin-header nav .action-btn:hover {
  color: var(--mindalay--brand-color);
}
.admin-header nav .cursor-arrow {
  cursor: default;
}
.curriculum-title {
  padding: 20px 25px;
}
.content-title {
  display: flex;
  align-items: center;
  font-size: 23px;
  padding: 20px 25px;
  padding-bottom: 0;
}
.content-title a,
.content-title button {
  border: none;
  background: transparent;
}
.content-title a svg,
.content-title button svg {
  width: 16px !important;
  padding: 8px;
  border: 1px solid var(--mindalay--secondary-color);
  box-sizing: content-box;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}
.content-title a svg path,
.content-title button svg path {
  fill: var(--mindalay--secondary-color);
}
.content-title a svg:hover,
.content-title button svg:hover {
  background-color: var(--mindalay--secondary-color);
}
.content-title a svg:hover path,
.content-title button svg:hover path {
  fill: var(--mindalay--white-color);
}
.tab-title-group nav {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .tab-title-group nav {
    justify-content: space-evenly;
  }
}
.tab-title-group .nav-link {
  color: var(--mindalay--gray-color);
}
.tab-title-group .mindalay--nav-tab-button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.nav-tabs .nav-link {
  border: none;
}
.nav-tabs .nav-link:hover {
  color: var(--mindalay--brand-color);
}
.nav-tabs .nav-link.active {
  border: none;
  color: var(--mindalay--brand-color);
  border-bottom: 4px solid var(--mindalay--brand-color);
}
.nav-tabs .nav-link.active:hover {
  border-bottom: 4px solid var(--mindalay--brand-color);
}
.nav-tabs .nav-link.active::after {
  background-color: var(--mindalay--green-color);
  top: -15px;
  right: -15px !important;
}
.nav-tabs .has-number::after {
  background-color: var(--mindalay--green-color);
  right: -10px;
  padding: 12px 10px;
  font-size: 13px;
}
.nav-tabs .tab-header-button {
  flex: 1;
  text-align: right;
}
.tr-x-0 {
  transform: translateX(0) !important;
}
.side-left-organization {
  background-color: var(--mindalay--brand-color);
}
.side-left {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100vh;
  background-color: var(--mindalay--brand-color);
  width: 60px;
  overflow: hidden;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .active-side-left-menu-item {
  background-color: var(--mindalay--brand-color-darken-35);
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item-organization:hover {
  background-color: var(--mindalay--brand-color) !important;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item {
  align-items: center;
  color: var(--mindalay--white-color);
  transition: 0.3s;
  cursor: pointer;
  display: flex;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a {
  padding: 15px;
  color: var(--mindalay--white-color);
  font-weight: 600;
  display: flex;
  align-items: center;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a span {
  transition: 0.5s;
  transform: translateX(250%);
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a:hover {
  text-decoration: none;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a img {
  width: 30px;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a svg {
  margin-right: 15px;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a svg path,
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item a svg text {
  transition: 0.3s;
  fill: var(--mindalay--white-color) !important;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item:first-child {
  padding: 15px;
  margin-bottom: 10px;
}
.side-left .side-left-container .side-left-inner-wrapper .side-left-list-wrapper .side-left-menu-item:hover {
  background-color: var(--mindalay--brand-color-darken-35);
}
.side-left-submenu {
  position: fixed;
  margin-left: 60px;
  min-width: 200px;
  height: 100vh;
  top: 0;
  transition: 0.3s;
  padding-top: 55px;
  background-color: var(--mindalay--blue-color-lightened-55);
}
.side-left-submenu .side-left-submenu-container {
  padding-top: 15px;
}
.side-left-submenu .side-left-submenu-container .side-left-submenu-list {
  padding: 10px 0;
}
.side-left-submenu .side-left-submenu-container .side-left-submenu-list .side-left-submenu-item a {
  padding: 10px 25px;
  cursor: pointer;
  color: var(--mindalay--gray-color);
  font-weight: 600;
  display: block;
}
.side-left-submenu .side-left-submenu-container .side-left-submenu-list .side-left-submenu-item:hover {
  background-color: var(--mindalay--blue-color-lightened-45);
}
.side-left-submenu .side-left-submenu-container .side-left-submenu-list .side-left-submenu-item:hover a {
  text-decoration: none;
  color: var(--mindalay--brand-color);
}
.mindalay--search-input-wrapper {
  margin: 0;
}
.mindalay--search-input-wrapper .mindalay--search-input {
  position: absolute;
  cursor: pointer;
  right: 0px;
  width: 25px;
  top: 0;
  padding: 11px 20px;
  box-sizing: content-box;
  background-color: var(--mindalay--secondary-color);
  line-height: 0;
}
.mindalay--search-input-wrapper .mindalay--search-input svg {
  fill: var(--mindalay--white-color);
}
.mindalay--search-input-wrapper input {
  border: 1.5px solid var(--mindalay--gray-color-lightened-40);
  color: var(--mindalay--gray-color);
  border-radius: 3px;
  position: relative;
  width: 100%;
  padding: 10px 20px;
}
.container-dashboard--instructor .media-file-wrapper {
  background-image: url(/assets/images/cover-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
.course-wrapper--instructor {
  height: 100%;
  border-radius: 3px;
  border: 0.5px solid var(--mindalay--gray-color-lightened-80);
}
.course-wrapper--instructor .course-image-wrapper--instructor {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
  position: relative;
}
.course-wrapper--instructor .course-image-wrapper--instructor .course-status--instructor {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 2px 10px;
  z-index: 1;
}
.course-wrapper--instructor .course-image-wrapper--instructor .course-live--instructor {
  background-color: var(--mindalay--green-color);
  color: var(--mindalay--white-color);
}
.course-wrapper--instructor .course-image-wrapper--instructor .course-unfinished--instructor {
  background-color: var(--mindalay--orange-color);
  color: var(--mindalay--white-color);
}
.course-wrapper--instructor .course-image-wrapper--instructor .course-image--instructor-dashboard {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;
  background-color: var(--mindalay--light-gray);
  min-height: 200px;
  width: auto;
  transition: 0.3s;
}
.course-wrapper--instructor .course-image-wrapper--instructor .course-image--instructor-dashboard:hover {
  transform: scale(1.05);
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-info--instructor {
  padding: 15px 10px;
  transition: 0.3s;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-info--instructor:hover {
  background-color: var(--mindalay--blue-color-lightened-55);
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-name--instructor {
  font-weight: 600;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-price-wrapper--instructor {
  justify-content: space-between;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-price-wrapper--instructor .course-new-price--instructor {
  color: var(--mindalay--gray-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-price-wrapper--instructor .course-old-price--instructor {
  text-decoration: line-through;
  color: var(--mindalay--blue-color);
  margin-left: 5px;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-review-number--instructor {
  line-height: 1;
  color: var(--mindalay--gray-color);
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-review-count--instructor {
  color: var(--mindalay--gray-color);
  font-size: 14px;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor {
  background-color: var(--mindalay--secondary-color);
  width: 100%;
  padding: 10px 15px;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor a {
  color: var(--mindalay--white-color);
  display: block;
  font-size: 16px;
}
.course-wrapper--instructor .course-body-wrapper--instructor .course-edit-button--instructor a:hover {
  text-decoration: none;
}
.course-wrapper--instructor .course-button-group {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
}
.course-wrapper--instructor .course-edit-button,
.course-wrapper--instructor .course-delet-button,
.course-wrapper--instructor .course-view-button,
.course-wrapper--instructor .course-success-button,
.course-wrapper--instructor .course-gamify-button {
  background-color: var(--mindalay--white-color);
  padding: 8px;
  display: block;
  line-height: 0;
  margin-top: 3px;
  border-radius: 3px;
}
.course-wrapper--instructor .course-edit-button:hover,
.course-wrapper--instructor .course-delet-button:hover,
.course-wrapper--instructor .course-view-button:hover,
.course-wrapper--instructor .course-success-button:hover,
.course-wrapper--instructor .course-gamify-button:hover {
  transform: scale(1.03);
}
.course-wrapper--instructor .course-view-button {
  border: 1px solid var(--mindalay--secondary-color);
}
.course-wrapper--instructor .course-view-button i {
  color: var(--mindalay--secondary-color);
}
.course-wrapper--instructor .course-view-button svg * {
  fill: var(--mindalay--secondary-color);
}
.course-wrapper--instructor .course-success-button {
  border: 1px solid var(--mindalay--green-color);
}
.course-wrapper--instructor .course-success-button i {
  color: var(--mindalay--green-color);
}
.course-wrapper--instructor .course-success-button svg * {
  fill: var(--mindalay--green-color);
}
.course-wrapper--instructor .dropdown-gamify-button {
  padding: 0;
  background-color: transparent !important;
  border: none;
}
.course-wrapper--instructor .dropdown-gamify-button::after {
  display: none;
}
.course-wrapper--instructor .dropdown-gamify-icon svg * {
  fill: var(--mindalay--green-color);
}
.course-wrapper--instructor .course-edit-button {
  border: 1px solid var(--mindalay--blue-color);
}
.course-wrapper--instructor .course-edit-button svg * {
  fill: var(--mindalay--blue-color);
}
.course-wrapper--instructor .course-edit-button span {
  color: var(--mindalay--blue-color);
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  font-size: 15px;
  font-weight: 800;
}
.course-wrapper--instructor .course-delet-button {
  border: 1px solid var(--mindalay--red-color);
}
.course-wrapper--instructor .course-delet-button svg * {
  fill: var(--mindalay--red-color);
}
.course-wrapper--instructor svg {
  width: 15px;
  height: 15px;
}
.edit-button {
  background-color: var(--mindalay--white-color);
  padding: 8px;
  display: block;
  line-height: 0;
  margin-top: 3px;
  border-radius: 3px;
}
.edit-button svg {
  width: 15px;
  height: 15px;
}
.edit-button:hover {
  transform: scale(1.03);
}
.course-gamify-button {
  border: 1px solid var(--mindalay--green-color);
}
.course-gamify-button svg * {
  fill: var(--mindalay--green-color);
}
.setting-dropdown--section {
  right: 25px !important;
  top: 32px !important;
  transform: translateY(-25%);
}
.setting-dropdown {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}
.setting-dropdown .setting-dropdown-btn {
  padding: 5px;
  line-height: 0;
}
.setting-dropdown .setting-dropdown-btn svg {
  width: 25px !important;
  height: auto;
  transition: 0.3s;
  margin: 0 !important;
}
.setting-dropdown .setting-dropdown-btn svg path {
  fill: var(--mindalay--brand-color) !important;
}
.cart-wrapper {
  border: 1px solid var(--mindalay--gray-color-lightened-70);
  border-radius: 3px;
  padding: 8px;
}
.cart-wrapper .cart-body-wrapper .cart-image {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  margin-right: 10px;
  max-width: 100px;
  max-height: 100px;
}
.cart-wrapper .cart-body-wrapper .cart-info-wrapper .cart-item-info {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.cart-wrapper .cart-body-wrapper .cart-info-wrapper .cart-item-info h3 {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
}
.cart-wrapper .cart-body-wrapper .cart-info-wrapper .cart-item-ruls {
  margin-right: 10px;
}
.cart-wrapper .cart-body-wrapper .cart-info-wrapper .cart-item-ruls div {
  padding: 3px 0;
  line-height: 1;
}
.cart-wrapper .cart-body-wrapper .cart-info-wrapper .cart-item-ruls div a {
  font-size: 14px;
  color: var(--mindalay--secondary-color);
}
.cart-wrapper .cart-body-wrapper .cart-price-wrapper .cart-item-new-price {
  color: var(--mindalay--black-color);
  font-size: 20px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.cart-wrapper .cart-body-wrapper .cart-price-wrapper .cart-item-old-price {
  font-size: 16px;
  color: var(--mindalay--gray-color);
  text-decoration: line-through;
}
.cart-total-info-wrapper .total-price-info {
  color: var(--mindalay--gray-color);
  margin-bottom: 10px;
}
.cart-total-info-wrapper .total-price-info .total-text {
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
  color: var(--mindalay--black-color);
}
.cart-total-info-wrapper .total-price-info .total-price {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--mindalay--secondary-color);
}
.cart-total-info-wrapper .total-price-info .total-old-price {
  font-weight: 600;
  margin-left: 10px;
  color: var(--mindalay--secondary-color);
}
.cart-total-info-wrapper .total-price-info .total-discount {
  padding: 5px 10px;
  background-color: var(--mindalay--secondary-color);
  color: var(--mindalay--white-color);
  width: fit-content;
}
.become-an-instructor-step-1 .custom-radio {
  border: 1px solid var(--mindalay--gray-color-lightened-30);
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.become-an-instructor-step-1 .custom-radio label {
  padding: 15px 20px;
  margin: 0;
  width: 100%;
}
.become-an-instructor-step-1 .custom-radio input {
  margin: 0;
}
footer {
  background-color: var(--mindalay--footer-color);
  width: 100%;
  padding: 50px 30px;
}
footer .footer-top-body ul {
  justify-content: space-between;
  width: 100%;
}
footer .footer-top-body ul li {
  padding: 3px 0;
  transition: 0.2s;
  width: fit-content;
  color: var(--mindalay--gray-color) !important;
}
footer .footer-top-body ul li a {
  color: var(--mindalay--gray-color);
}
footer .footer-top-body ul li:first-child {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: var(--mindalay--brand-color);
}
footer .footer-top-body ul li:not(:first-child):hover {
  cursor: pointer;
  transform: translateX(10px);
}
footer .footer-top-body ul li:not(:first-child):hover::before {
  content: '\203A';
  margin-right: 5px;
  color: var(--mindalay--brand-color);
  font-weight: 800;
}
footer .footer-top-body ul:last-child li {
  width: 100%;
  text-align: right;
  text-transform: none;
}
footer .footer-bottom-body {
  align-items: flex-end;
}
footer .footer-bottom-body .footer-logo-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}
footer .footer-bottom-body .footer-logo-wrapper .footer-brand-name {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: var(--mindalay--gray-color);
  margin-left: 10px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}
footer .footer-bottom-body .footer-logo-wrapper img {
  width: 25px;
  max-width: 150px;
  max-height: 150px;
  filter: grayscale(1);
}
footer .footer-bottom-body p {
  flex: 1;
  text-align: right;
  font-size: 14px;
  color: var(--mindalay--gray-color);
}
footer .footer-bottom-body .footer-text {
  flex: 1;
  font-size: 14px;
  color: var(--mindalay--gray-color);
  text-align: left !important;
}
.selected-item-container {
  display: flex;
  flex-wrap: wrap;
}
.selected-item-container .selected-item-wrapper {
  width: fit-content;
  background-color: var(--mindalay--blue-color-lightened-55);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 0;
  border-radius: 3px;
}
.selected-item-container .selected-item-wrapper .selected-item p {
  color: var(--mindalay--blue-color);
  font-weight: 600;
}
.selected-item-container .selected-item-wrapper .selected-item small {
  color: var(--mindalay--gray-color) !important;
}
.selected-item-container .selected-item-wrapper button {
  margin-left: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.selected-item-container .selected-item-wrapper button svg {
  width: 15px;
  max-height: 15px;
  box-sizing: border-box;
}
.selected-item-container .selected-item-wrapper button svg path {
  fill: var(--mindalay--blue-color);
}
.become-a-consultant .consultant-category-information {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--mindalay--gray-color-lightened-60);
}
.org-payment-pay-repetitve .form-group {
  margin-bottom: 0;
}
.mindalay--consultation-container {
  padding: 20px;
  border-radius: 3px;
  position: relative;
  background-color: var(--mindalay--white-color);
  transition: 0.3s;
}
.mindalay--consultation-container .consultation-user-image {
  margin-right: 15px;
}
.mindalay--consultation-container .consultation-header-name {
  color: var(--mindalay--brand-color);
}
.mindalay--consultation-container .consultation-user-name {
  color: var(--mindalay--brand-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mindalay--consultation-container small {
  font-size: 13px;
  color: var(--mindalay--gray-color);
}
.mindalay--consultation-container .gmt-time {
  color: var(--mindalay--secondary-color);
  display: block;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--consultation-container .consultation-container-left-side .card-price-wrapper strong {
  color: var(--mindalay--brand-color);
  font-size: 23px;
}
.mindalay--consultation-container .consultation-container-left-side .consultation-description {
  margin-top: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.mindalay--consultation-container .consultation-container-left-side .consultation-houres {
  padding: 5px 10px;
  background-color: var(--mindalay--green-color);
  color: var(--mindalay--white-color);
  display: block;
  width: fit-content !important;
  margin: 8px 0;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 100;
}
.mindalay--consultation-container .consultation-container-left-side .mindalay--contract-date {
  text-align: right;
}
.mindalay--consultation-container .consultation-container-left-side .mindalay--contract-date span {
  display: block;
  padding: 5px 25px;
  border-radius: 3px;
  color: var(--mindalay--blue-color);
  font-weight: 600;
  background-color: var(--mindalay--blue-color-lightened-50);
}
.mindalay--consultation-container .consultation-container-left-side .ticket-subject {
  margin: 5px 20px 0 0;
}
.mindalay--consultation-container .consultation-container-left-side .ticket-subject small {
  display: block;
  margin-bottom: 5px;
}
.mindalay--consultation-container .consultation-container-left-side .ticket-subject small:last-child {
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
  color: var(--mindalay--brand-color);
  padding: 5px 10px;
  border: 1px solid var(--mindalay--secondary-color);
  border-radius: 3px;
}
.mindalay--consultation-container .consultation-date {
  margin-top: 2px;
  font-size: 13px !important;
}
.mindalay--consultation-container hr {
  margin: 0.5em 0;
}
.mindalay--consultation-container .consultation-container-right-side .btn-group {
  display: flex;
  justify-content: flex-end;
}
.mindalay--consultation-container .consultation-container-right-side .complited-consultation-price {
  color: var(--mindalay--secondary-color);
  margin-left: 10px;
  font-size: 20px;
}
.mindalay--consultation-container .consultation-user-image {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 60px;
  max-width: 60px;
  border-radius: 50%;
}
.mindalay--consultation-container .consultation-rating {
  display: flex;
  align-items: center;
}
.mindalay--hover-effect:hover {
  transform: scale(1.01);
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1) !important;
}
.mindalay--consultant-container .consultation-user-image {
  margin: 10px 15px 0 0;
}
.mindalay--consultant-container .consultation-price strong {
  font-size: 25px;
}
.mindalay--consultant-container .consultation-price b::first-letter {
  text-transform: uppercase;
}
.mindalay--consultant-container .consultation-student-info-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.mindalay--consultant-container .consultation-student-info-wrapper .consultation-student-info {
  margin-right: 15px;
}
.mindalay--consultant-container .consultation-student-info-wrapper .consultation-student-info small {
  color: var(--mindalay--gray-color);
  font-size: 14px;
  margin-right: 3px;
}
.mindalay--consultant-container .consultation-student-info-wrapper .consultation-student-info strong {
  color: var(--mindalay--brand-color);
}
.info-title {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1;
}
.info-title p,
.info-title div {
  margin-left: 5px;
  opacity: 1;
  line-height: initial;
  cursor: help;
}
.info-title p svg,
.info-title div svg {
  width: 15px;
  margin-bottom: 3px;
  height: auto;
}
.info-title p svg *,
.info-title div svg * {
  fill: var(--mindalay--blue-color);
}
.info-title p:hover > span,
.info-title div:hover > span {
  display: block !important;
  position: absolute;
  padding: 10px 15px;
  border-radius: 3px;
  top: -50%;
  color: var(--mindalay--gray-color);
  background-color: var(--mindalay--white-color);
  z-index: 1010;
  width: 200px;
  box-shadow: 0 0 33px rgba(0, 0, 0, 0.1);
  transform: translate(-80%, calc(-100% + 10px));
  left: 70px;
  font-size: 13px;
}
.info-title p:hover > span p,
.info-title div:hover > span p {
  font-size: 13px;
}
.messages-users-container .message-user-name {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  opacity: 0.8;
}
.mindalay--consultation-tab .consultation-chat-username {
  padding: 10px 15px;
  display: block;
  background-color: var(--mindalay--white-color);
  color: var(--mindalay--brand-color);
  border-bottom: 1px solid var(--mindalay--gray-color-lightened-80);
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container {
  overflow: auto;
  height: 420px;
  margin-bottom: 0;
  padding: 10px 0;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper {
  padding: 5px 10px;
  display: flex;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-message {
  min-width: 190px;
  border-radius: 3px;
  padding: 10px;
  padding-bottom: 20px;
  position: relative;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-message .consultation-chat-message-text {
  font-size: 16px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-sender-wrapper {
  margin: 10px;
  height: fit-content;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-sender-wrapper .consultation-chat-user-image,
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-sender-wrapper .consultation-chat-user-default-image {
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  position: relative;
  background-color: var(--mindalay--brand-color);
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-message-wrapper .consultation-chat-message-date {
  font-size: 11px;
  color: var(--mindalay--black-color);
  position: absolute;
  right: 10px;
  bottom: 5px;
  opacity: 0.7;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultant-chat-sender .consultation-chat-message {
  background-color: var(--mindalay--blue-color-lightened-55);
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-currnet-user {
  justify-content: flex-end;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-message-container .consultation-chat-currnet-user .consultation-chat-message {
  background-color: var(--mindalay--gray-color-lightened-90);
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-input-wrapper {
  position: relative;
  padding: 15px;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-input-wrapper input {
  padding-right: 50px !important;
  border-color: var(--mindalay--brand-color);
  padding: 13px 20px;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-input-wrapper .consultation-chat-send-button {
  box-sizing: content-box;
  width: fit-content;
  position: absolute;
  background-color: var(--mindalay--white-color);
  padding: 10px;
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-input-wrapper .consultation-chat-send-button svg {
  width: 25px;
}
.mindalay--consultation-tab .consultation-chat-content-wrapper .consultation-chat-input-wrapper .consultation-chat-send-button svg * {
  fill: var(--mindalay--brand-color);
}
.mindalay--consultation-tab .consultation-earning-content-wrapper {
  display: flex;
  overflow-x: auto;
}
.mindalay--consultation-tab .consultation-earning-content-wrapper .earning-item-wrapper {
  flex: 1;
  padding: 10px;
}
.mindalay--consultation-tab .consultation-earning-content-wrapper .earning-item-wrapper .info-title {
  margin-bottom: 5px;
  font-size: 25px;
  color: var(--mindalay--gray-color);
}
.mindalay--consultation-tab .consultation-earning-content-wrapper .earning-item-wrapper .info-title strong {
  position: relative;
  padding-bottom: 5px;
}
.mindalay--consultation-tab .consultation-earning-content-wrapper .earning-item-wrapper .info-title strong::after {
  content: '';
  height: 4px;
  width: 20px;
  background-color: var(--mindalay--brand-color);
  position: absolute;
  left: 0;
  bottom: 0;
}
.mindalay--consultation-tab .consultation-earning-content-wrapper .earning-item-wrapper .earning-price {
  color: var(--mindalay--secondary-color);
}
.mindalay--consultation-tab .consultation-feeback-wrapper {
  padding: 25px;
}
.mindalay--consultation-tab .consultation-feeback-wrapper .consulttion-feedback-title {
  font-size: 25px !important;
  margin-bottom: 20px;
}
.mindalay--consultation-tab .consultation-feeback-wrapper .consultation-feedback {
  margin-top: 5px;
}
.mindalay--consultation-tab .consultation-feeback-wrapper .rating-process-container {
  display: flex;
  margin: 40px 0;
  justify-content: center;
}
.mindalay--consultation-tab .consultation-feeback-wrapper .rating-process-container svg {
  width: 60px;
  margin: 0 5px;
  cursor: pointer;
}
.mindalay--consultation-tab .consultation-feeback-wrapper .rating-process-container svg * {
  fill: var(--mindalay--orange-color);
}
.category-burger-menu .dropdown-toggle::after {
  font-size: 13px;
  transform: rotate(-90deg);
  position: absolute;
  content: "❮";
  margin-left: 0.8em;
  margin-top: 0.2rem;
  border: none;
}
.category-burger-menu .dropdown-toggle-organization::after {
  margin-left: 10px;
}
.category-burger-menu .nav-link {
  color: var(--mindalay--gray-color-lightened-30);
  transition: 0.3s;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.category-burger-menu .nav-link::after {
  right: 15px;
}
.category-burger-btn-wrapper button {
  padding: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--mindalay--white-color);
}
.category-burger-btn-wrapper button:hover {
  color: var(--mindalay--white-color);
}
.category-filter-dropdown ul {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
.category-filter-dropdown .dropdown-toggle::after {
  font-size: 13px;
  transform: translateY(-50%) rotate(-90deg);
  position: absolute;
  content: "❮";
  right: -5px;
  top: 50%;
  border: none;
  color: var(--mindalay--white-color);
}
.category-filter-dropdown .dropdown-toggle:hover div {
  color: var(--mindalay--white-color) !important;
}
.category-filter-dropdown .has-submenu button::after {
  color: var(--mindalay--gray-color-lightened-70) !important;
}
.mindalay--overview-container {
  border-radius: 3px;
  margin: 15px;
}
.mindalay--overview-container .mindalay--overview-header {
  width: 100%;
  margin-bottom: 10px;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper {
  display: flex;
  overflow-y: auto;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper {
  display: flex;
  padding: 20px 25px;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper > span,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper > span {
  margin: 0 15px;
  display: block;
  width: 1px;
  height: 100%;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-total .mindalay--overview-info-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-info-value {
  font-size: 30px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-total .mindalay--overview-info-title,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-info-title,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-this-month .mindalay--overview-info-title,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-this-month .mindalay--overview-info-title {
  width: 100%;
  line-height: 1.5;
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  margin-bottom: 5px;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-total .mindalay--overview-info-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-info-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-this-month .mindalay--overview-info-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-this-month .mindalay--overview-info-value {
  line-height: 1;
  width: 100%;
  font-size: 30px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper {
  border: 1px solid var(--mindalay--brand-color);
  border-radius: 5px;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper > span {
  background-color: var(--mindalay--brand-color);
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-info-title,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-this-month .mindalay--overview-info-title {
  color: var(--mindalay--brand-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-local-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-this-month .mindalay--overview-local-value {
  opacity: 0.7;
  font-size: 14px;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-total .mindalay--overview-info-value {
  color: var(--mindalay--brand-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-total-wrapper .mindalay--overview-this-month .mindalay--overview-info-value {
  color: var(--mindalay--secondary-color);
  font-family: 'Commissioner', sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper > span {
  background-color: var(--mindalay--gray-color-lightened-30);
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-total .mindalay--overview-info-title,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-this-month .mindalay--overview-info-title {
  color: var(--mindalay--gray-color);
}
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-total .mindalay--overview-info-value,
.mindalay--overview-container .mindalay--overview-header .mindalay--overview-header-wrapper .mindalay--overview-raiting-wrapper .mindalay--overview-this-month .mindalay--overview-info-value {
  color: var(--mindalay--gray-color);
}
.mindalay--overview-container .mindalay--overview-body {
  min-height: 300px;
}
.mindalay--overview-container .mindalay--overview-body .mindalay--overview-body-wrapper {
  display: flex;
  border-bottom: 1px solid var(--mindalay--gray-color-lightened-40);
}
.mindalay--overview-container .mindalay--overview-body .mindalay--overview-body-wrapper li {
  flex: 1;
  padding: 10px 15px;
  text-align: center;
  border-right: 1px solid var(--mindalay--gray-color-lightened-40);
}
.modal-header button {
  width: fit-content !important;
}
.mindalay-files-gallery-container {
  margin: 1.5rem 0;
}
.mindalay-files-gallery-container .mindalay-file-wrapper {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.mindalay-files-gallery-container .mindalay-file-wrapper .mindalay-file-wrapper-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mindalay--gray-color-lightened-90);
}
.mindalay-files-gallery-container .mindalay-file-wrapper img {
  max-height: 200px;
  border-radius: 5px;
  width: 100%;
}
.mindalay-files-gallery-container .mindalay-file-wrapper audio {
  max-width: 100%;
}
.mindalay-files-gallery-container .mindalay-file {
  padding: 15px;
  display: flex;
  align-items: center;
}
.mindalay-files-gallery-container .mindalay-file .card-body {
  padding: 0;
  margin-left: 10px;
}
.mindalay-files-gallery-container .mindalay-file img {
  max-width: 50px;
}
.mindalay-files-gallery-container .mindalay-video video {
  width: 100%;
}
.user-webinar-wrapper {
  cursor: pointer;
}
.user-webinar-wrapper .card-img-top-wrapper {
  background-color: var(--mindalay--gray-color-lightened-85);
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.user-webinar-wrapper .card-img-top {
  width: auto;
  max-height: 150px;
}
.user-webinar-wrapper .card-title {
  margin: 0;
}
.webinar-creator-name {
  font-size: 14px;
}
.w-fix-left-menu-organization {
  width: 260px !important;
}
@media (min-width: 768px) {
  .w-fix-left-menu {
    width: 260px !important;
  }
  .authorization-form {
    margin: 50px auto;
  }
  p,
  label,
  select,
  option {
    font-size: 16px;
  }
  .input-lenght {
    padding: 6.5px 10px;
  }
  select {
    padding: 12px 15px !important;
  }
  .mindalay--btn-default:hover {
    background-color: var(--mindalay--brand-color);
    color: var(--mindalay--white-color);
  }
  .mindalay--btn-default:hover * {
    fill: transparent !important;
  }
  .mindalay--btn-blue:hover {
    background-color: var(--mindalay--blue-color);
    color: var(--mindalay--white-color);
  }
  .mindalay--btn-blue:hover * {
    fill: var(--mindalay--white-color) !important;
  }
  .cover-image {
    padding: 70px 50px;
  }
  .cover-image .cover-title {
    font-size: 30px;
    line-height: 1;
    color: var(--mindalay--secondary-color);
  }
  .cover-image .cover-title span {
    color: var(--mindalay--brand-color);
    font-size: 70px;
  }
  .quiz-question-name {
    font-size: 20px;
  }
  .navbar-popup-title {
    font-size: 1.5rem;
  }
  .big-number span {
    font-size: 40px !important;
  }
  .profile-info-wrapper h2 {
    font-size: 35px;
  }
  .profile-info-wrapper h2::before {
    left: 0;
  }
  .m-section {
    margin: 35px 0;
  }
  .p-sectiom {
    padding: 35px 0;
  }
  .setting-dropdown-btn svg:hover {
    animation: rotation 1s infinite linear;
  }
  .course-info-container .course-price-wrapper .course-new-price {
    font-size: 25px;
  }
  .course-info-container .course-price-wrapper .course-old-price {
    font-size: 16px;
  }
  .total-price-info .total-text {
    font-size: 30px;
  }
  .total-price-info .total-price {
    font-size: 30px !important;
  }
  .total-price-info .total-old-price {
    font-size: 20px;
  }
  .timer-item {
    padding: 10px 20px;
  }
  .timer-item span {
    min-width: 60px;
  }
  .timer-item .timer-item-value {
    font-size: 20px;
  }
  .course-wrapper-horizontal .course-body-wrapper {
    display: flex;
  }
  .course-wrapper-horizontal .course-image-wrapper {
    max-width: 220px;
  }
  .mindalay--search-input-wrapper {
    min-width: 300px;
  }
  .accordion-wrapper .content-css .topic-description-block p {
    display: block !important;
  }
  .accordion-wrapper p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  .modal-window .content-body {
    width: 700px;
  }
  .modal-window .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }
  .modal-window .card .card-header {
    display: none;
  }
  .modal-window .card .collapse {
    display: block;
  }
  .modal-window .card-body {
    max-height: 400px;
    overflow-y: auto;
  }
  .w-md-100 {
    width: 100%;
  }
  .feedback-usernam {
    font-size: 20px;
  }
  .section-content .setting-dropdown {
    top: 50%;
    transform: translateY(-50%);
  }
  .content-body {
    padding: 20px 25px;
  }
  .media-file-wrapper img {
    width: auto;
    max-height: 120px;
  }
  .mobil-navbar-wrapper {
    display: none;
  }
  .course-wrapper {
    min-height: 130px;
  }
  .course-wrapper .course-description {
    font-size: 14px !important;
  }
  .course-wrapper .course-price-wrapper .course-new-price {
    font-size: 16px;
  }
  .course-wrapper .course-price-wrapper .course-old-price {
    font-size: 14px;
  }
  .course-wrapper .course-review-number {
    font-size: 16px;
  }
  .dropdown-menu .dropdown-toggle {
    position: relative;
  }
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.2em solid transparent;
    border-right: 0;
    border-bottom: 0.2em solid transparent;
    border-left: 0.2em solid;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  .megasubmenu {
    left: 100%;
    top: -0.5% !important;
    min-height: 100%;
  }
  .dropdown-menu > li:hover > .megasubmenu {
    display: block;
  }
  .quiz-fildest-information {
    padding: 20px;
  }
  .instructior-dashboard-body--large {
    margin-left: 260px !important;
    transition: 0.3s;
  }
  .consultant-dashboard-body--large {
    margin-left: 60px;
  }
  .mindalay--consultation-container .header-left-sdie {
    flex: 0 1 50%;
  }
  .mindalay--consultation-container .header-right-side {
    flex: 0 1 50%;
    margin-left: 15px;
  }
  .mindalay--consultation-container .consultation-header-name,
  .mindalay--consultation-container .consultation-user-name {
    font-size: 20px;
  }
  .timer-item {
    padding: 10px 20px;
  }
  .timer-item span {
    min-width: 60px;
  }
  .timer-item .timer-item-value {
    font-size: 20px;
  }
  .course-general-info h1 {
    font-size: 23px;
  }
  .course-general-info h2 {
    font-size: 20px;
  }
  .big-number {
    flex: 0 1 21%;
  }
  aside.coures-pass-right-content {
    margin-left: calc(5vw + 250px);
    width: 100%;
  }
  aside.coures-pass-right-content .course-pass-process-video {
    padding: 20px;
  }
  aside.coures-pass-right-content .course-pass-process-video video {
    width: 80%;
    max-width: 800px;
  }
  aside.coures-pass-left-content {
    width: calc(5vw + 250px);
    height: 100vh;
    position: fixed;
    z-index: 1;
  }
  .course-main-info .course-image {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 200px;
  }
  .consultation-earning-content-wrapper {
    padding: 40px;
  }
  .section-title {
    font-size: 23px;
  }
}
@media (max-width: 767.98px) {
  .mindalay--overview-total-wrapper {
    justify-content: space-evenly;
    width: 100%;
  }
  .authorization-form {
    margin: 60px auto;
  }
  .section-title {
    font-size: 20px;
  }
  .flex-col-reverse {
    flex-direction: column-reverse;
  }
  p,
  label,
  option,
  select {
    font-size: 15px;
  }
  .svg-button {
    min-height: 42px;
    padding: 10px 15px !important;
  }
  .input-lenght {
    padding: 5.5px 10px;
  }
  select {
    padding: 10px 15px !important;
  }
  .quiz-question-name {
    font-size: 16px;
  }
  .navbar-popup-title {
    font-size: 1.3rem;
  }
  .big-number span {
    font-size: 30px !important;
  }
  .profile-info-wrapper h2 {
    font-size: 25px;
  }
  .profile-info-wrapper h2::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .m-section {
    margin: 25px 0;
  }
  .p-sectiom {
    padding: 25px 0;
  }
  .course-info-container .course-price-wrapper {
    position: relative;
  }
  .course-info-container .course-price-wrapper .course-new-price {
    font-size: 23px;
  }
  .course-info-container .course-price-wrapper .course-old-price {
    font-size: 14px;
  }
  .total-price-info {
    display: flex;
    align-items: center;
  }
  .total-price-info .total-text {
    font-size: 20px;
  }
  .total-price-info .total-price {
    margin-left: 10px;
    font-size: 25px;
  }
  .total-price-info .total-old-price {
    font-size: 16px;
  }
  .cover-image {
    padding: 40px 20px;
  }
  .cover-image .cover-title {
    font-size: 15px;
    line-height: 1.1;
    color: var(--mindalay--secondary-color);
  }
  .cover-image .cover-title span {
    color: var(--mindalay--brand-color);
    font-size: 30px;
  }
  .feedback-usernam {
    font-size: 16px;
  }
  .media-file-wrapper img {
    width: auto;
    max-height: 90px;
  }
  .course-wrapper .course-name {
    font-size: 15px;
  }
  .course-wrapper .course-description {
    font-size: 13px;
  }
  .course-wrapper .course-review-number {
    font-size: 15px;
  }
  .course-wrapper .course-price-wrapper .course-new-price {
    font-size: 14px;
  }
  .course-wrapper .course-price-wrapper .course-old-price {
    font-size: 13px;
  }
  .modal-window .container {
    max-width: 100% !important;
  }
  .modal-window .content-body {
    width: calc(100% - 30px);
  }
  .quiz-fildest-information {
    padding: 15px;
    margin-bottom: 15px;
  }
  .content-body {
    padding: 20px 5px 0 5px;
  }
  .course-wrapper-horizontal .course-body-wrapper .course-price-wrapper {
    text-align: left;
  }
  .course-wrapper-horizontal .course-image-wrapper {
    max-width: 150px;
    height: 100px;
  }
  .course-wrapper-horizontal .course-image-wrapper .course-image {
    max-height: 70px;
  }
  .side-menu-container {
    display: none;
  }
  .help-side-menu-container {
    display: none;
  }
  .timer-item {
    padding: 5px 10px;
  }
  .timer-item span {
    min-width: 30px;
    font-size: 15px;
  }
  .course-general-info h1 {
    font-size: 20px;
  }
  .course-general-info h2 {
    font-size: 16px;
  }
  .consultation-container strong {
    font-size: 18px;
  }
  .nav-tabs {
    justify-content: center;
  }
  .course-main-info .course-image {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 300px;
  }
  .course-pass-process-video video {
    width: 100%;
  }
  .course-pass-process-video img {
    width: 100%;
  }
  .m--w-md-100 {
    width: 100%;
  }
  .mindalay--contract-proposal-header-container {
    flex-direction: column-reverse;
  }
  .mindalay--contract-proposal-header-container .mindalay--contract-proposal-contract-name {
    font-size: 18px;
  }
  .consultation-earning-content-wrapper {
    padding: 20px;
  }
  .consultation-earning-content-wrapper .earning-item-wrapper {
    min-width: 200px;
  }
  .consultation-user-name {
    font-size: 16px;
  }
  small {
    font-size: 13px;
  }
}
@media (max-width: 575.98px) {
  .burger-menu-body {
    width: calc(100% - 75px);
  }
  .course-slider-max-5,
  .course-slider-max-3 {
    grid-auto-columns: calc((100% - (2 - 1) * 1rem)/2);
  }
  .course-flexable .course-wrapper {
    flex: 1 1 50%;
    max-width: calc(50% - 10px);
  }
  .pagination-group button[type="button"],
  .pagination-group button[type="submit"],
  .pagination-group button[type="reset"],
  .pagination-group input[type="submit"],
  .pagination-group input[type="reset"],
  .pagination-group .mindalay--btn-link {
    width: inherit;
  }
  button[type="button"],
  button[type="submit"],
  button[type="reset"],
  input[type="submit"],
  input[type="reset"],
  .mindalay--btn-link {
    width: 100%;
  }
  .breadcrumb {
    justify-content: center;
  }
  .mindalay--consultant-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mindalay--consultant-container .consultation-rating {
    justify-content: center;
    line-height: 0;
  }
  .mindalay--consultant-container .consultation-user-image {
    margin: 0 auto;
    margin-bottom: 0 !important;
  }
  .mindalay--consultant-container .consultation-houres,
  .mindalay--consultant-container .student-houres,
  .mindalay--consultant-container .consultation-header-name,
  .mindalay--consultant-container .consultation-container-left-side,
  .mindalay--consultant-container .consultation-price,
  .mindalay--consultant-container small,
  .mindalay--consultant-container .consultation-student-info-wrapper,
  .mindalay--consultant-container .consultation-rating {
    text-align: center;
    margin: 8px 0;
  }
  .mindalay--consultant-container .consultation-user-image {
    margin-bottom: 10px;
  }
  .mindalay--consultant-container .consultation-header-name {
    display: block;
  }
  .mindalay--consultant-container .consultation-houres {
    margin: 0 auto !important;
    display: inline;
  }
  .mindalay--consultant-container .consultation-user-name {
    width: 100%;
  }
  .mindalay--consultant-container .consultation-student-info-wrapper {
    justify-content: center;
    text-align: center;
  }
  .footer-bottom-body .footer-logo-wrapper {
    justify-content: center;
    margin: 15px 0;
  }
  .footer-bottom-body .copyright {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .student-houres {
    margin-right: 20px;
  }
  .burger-menu-body {
    min-width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .course-slider-max-5,
  .course-slider-max-3 {
    grid-auto-columns: calc((100% - (3 - 1) * 1rem)/3);
  }
  .course-flexable .course-wrapper {
    flex: 1 1 33.33%;
    max-width: calc(33.33% - 10px);
  }
}
@media (max-width: 991.98px) {
  .contract-date {
    width: 100%;
  }
  .cover-image img {
    display: none;
  }
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .course-slider-max-5 {
    grid-auto-columns: calc((100% - (4 - 1) * 1rem)/4);
  }
  .course-slider-max-3 {
    grid-auto-columns: calc((100% - (3 - 1) * 1rem)/3);
  }
  .course-flexable .course-wrapper {
    flex: 1 1 25%;
    max-width: calc(25% - 10px);
  }
}
@media (max-width: 991.98px) {
  .course-wrapper--instructor .course-price-wrapper--instructor .course-new-price--instructor {
    font-size: 23px !important;
  }
  .course-wrapper--instructor .course-price-wrapper--instructor .course-old-price--instructor {
    font-size: 16px !important;
  }
  .course-wrapper--instructor .course-review-number--instructor {
    font-size: 23px;
  }
  .course-wrapper--instructor .course-edit-button--instructor {
    transition: 0.3s;
  }
  .course-wrapper--instructor .course-edit-button--instructor:hover {
    background-color: var(--mindalay--secondary-color-lightened-minus-5);
  }
  .course-wrapper--instructor .course-status--instructor {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .course-wrapper--instructor .course-price-wrapper--instructor .course-new-price--instructor {
    font-size: 25px !important;
  }
  .course-wrapper--instructor .course-price-wrapper--instructor .course-old-price--instructor {
    font-size: 16px !important;
  }
  .course-wrapper--instructor .course-review-number--instructor {
    font-size: 30px;
  }
  .course-wrapper--instructor .course-edit-button--instructor {
    transition: 0.3s;
  }
  .course-wrapper--instructor .course-edit-button--instructor:hover {
    background-color: var(--mindalay--secondary-color-lightened-minus-5);
  }
  .course-wrapper--instructor .course-status--instructor {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .course-slider-max-5 {
    grid-auto-columns: calc((100% - (5 - 1) * 1rem)/5);
  }
  .course-slider-max-3 {
    grid-auto-columns: calc((100% - (4 - 1) * 1rem)/4);
  }
  .course-flexable .course-wrapper {
    flex: 1 1 20%;
    max-width: calc(20% - 10px);
  }
}
.course-video-link {
  width: 100% !important;
  height: 100% !important;
}
.list-style {
  list-style: circle;
}
.list-style > ul > li {
  margin-left: 30px;
  list-style: inherit;
}
.font_family {
  padding-left: 30px;
}
.font_family > p {
  font-family: sans-serif;
}
.minadalay_quill > .ql-container {
  height: 300px;
  overflow: auto;
}
.list-styles > ul {
  padding-left: 15px;
}
.list-styles > ul > li {
  list-style: outside !important;
}
